<template>
  <div class="pa-2">
    <EditAdmin
      v-if="profileData.userTypeId === 1 || profileData.userTypeId === 2"
      :isMyProfile="isMyProfile"
      :profileData="profileData"
    />
    <EditClient
      v-if="profileData.userTypeId === 3"
      :isMyProfile="isMyProfile"
      :profileData="profileData"
    />
    <EditFarm v-if="profileData.userTypeId === 4" :profileData="profileData" />
    <EditSeller
      v-if="profileData.userTypeId === 5"
      :isMyProfile="isMyProfile"
      :profileData="profileData"
    />
  </div>
</template>

<script>
import EditAdmin from "./EditAdmin.vue";
import EditSeller from "./EditSeller.vue";
import EditFarm from "./EditFarm.vue";
import EditClient from "./EditClient.vue";

export default {
  props: {
    isMyProfile: {
      type: Boolean,
      required: false,
      default: false,
    },
    profileData: {
      type: Object,
      required: true,
    },
  },
  components: {
    EditAdmin,
    EditSeller,
    EditClient,
    EditFarm,
  },
};
</script>

<style></style>
