const rules = {
  required: (value) => !!value || "Este campo é obrigatório.",
  hasCapitalLetter: (value) =>
    /^(?=(?:.*?[A-Z]){1})/.test(value) ||
    "Sua senha deve conter no mínimo uma letra maiúscula.",
  hasNumber: (value) =>
    /^(?=(?:.*?[0-9]){1})/.test(value) ||
    "Sua senha deve conter no mínimo um caractere alfanumérico.",
  hasSpecialCharacter: (value) =>
    /^(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/.test(
      value
    ) || "Sua senha deve conter no mínimo um caractere especial.",
  minSize: (value) =>
    value.length >= 6 || "Sua senha precisa ter no mínimo 6 caracteres",
  email: (value) => /.+@.+\..+/.test(value) || "E-mail inválido",
  cpf: (value) => value.length >= 14 || "CPF inválido",
  cnpj: (value) => value.length >= 17 || "CNPJ inválido",
  phone: (value) =>
    value.length >= 14 || value.length === 0 || "Telefone inválido",
  cep: (value) =>
    value.length >= 9 || "O CEP deve conter no mínimo 9 caracteres",
  rg: (value) => value.length >= 8 || "RG inválido",
  numeric: (value) =>
    /^\d+$/.test(value) || "Este campo deve conter apenas valores numéricos",
  numericOrEmpty: (value) =>
    /^\d+$/.test(value) ||
    value.length === 0 ||
    "Este campo deve conter apenas valores numéricos",
  fee: (value) =>
    (value == Number(value) && value > 0) ||
    "Este campo deve conter apenas valores numéricos maiores que zero",
  creditDiscount: (value) => {
    const numericValue = value.replace(",", ".");
    if (
      Number(numericValue) ||
      (parseFloat(numericValue) <= 10 && parseFloat(numericValue) >= 20)
    ) {
      return "Este campo deve conter apenas valores numéricos maiores ou iguais a 10,0 e menores ou iguais a 20,0";
    }
    return true;
  },
};

export default rules;
