<template>
  <div id="search">
    <header class="mt-1 mb-3 body-1 font-weight-bold primary--text">
      {{ this.headerText }}
    </header>
    <v-text-field
      class="elevation-0 body-2 lightgray--text"
      dense
      hide-details
      solo
      label="Pesquisar"
      filled
      prepend-inner-icon="mdi-magnify"
      v-model="search"
      clearable
      @change="handleChange"
      @click:clear="handleClear"
    >
    </v-text-field>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Search",
  props: {
    headerText: {
      type: String,
      required: false,
      default: "Selecione os items",
    },
    searchFarm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    search: "",
  }),
  methods: {
    ...mapActions([
      "checkbox_updateFilteredItems",
      "treeView_updateFilteredItems",
    ]),
    handleChange() {
      if (this.searchFarm) {
        this.checkbox_updateFilteredItems({ searchText: this.search });
        return;
      }

      this.treeView_updateFilteredItems({ searchText: this.search });
    },
    handleClear() {
      this.search = "";
      this.handleChange();
    },
  },
};
</script>

<style lang="scss" scoped>
#search {
  max-width: 225px;
  width: 100%;
}
</style>
