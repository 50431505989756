<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <IconUserAvatar />
        <span class="ml-2 body-1 font-weight-bold gray--text">
          Termos e condições de uso
        </span>
      </v-col>
      <v-col cols="12">
        <div class="pl-3 pr-6 py-3 body-2 d-flex justify-center">
          <iframe
            :src="baseURL + 'terms'"
            title="Termos e condições de uso"
            style="width: 100%; height: 30rem"
          ></iframe>
        </div>
      </v-col>
      <v-col cols="12" class="d-flex align-center">
        <IconUserAvatar />
        <span class="ml-2 body-1 font-weight-bold gray--text">
          Políticas de privacidade
        </span>
      </v-col>
      <v-col cols="12">
        <div class="pl-3 pr-6 py-3 body-2 d-flex justify-center">
          <iframe
            :src="baseURL + 'policy'"
            title="Políticas de privacidade"
            style="width: 100%; height: 30rem"
          ></iframe>
        </div>
      </v-col>
      <v-col cols="12">
        <v-checkbox
          class="mt-2 lightgray--text"
          v-model="acceptedTerm"
          label="Li e aceito os termos e condições de uso."
        ></v-checkbox>
        <v-checkbox
          class="mt-2 lightgray--text"
          v-model="acceptedPolicy"
          label="Li e aceito as políticas de privacidade."
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <v-btn
          color="primary"
          fab
          medium
          outlined
          class="elevation-0"
          @click="$emit('decrementTab')"
        >
          <v-icon dark> mdi-arrow-left </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          fab
          medium
          class="elevation-0"
          :disabled="!acceptedTerm || !acceptedPolicy"
          @click="$emit('incrementTab')"
        >
          <v-icon dark> mdi-arrow-right </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IconUserAvatar from "@/assets/icons/IconUserAvatar.vue";
import api from "@/plugins/axios.js";
export default {
  components: {
    IconUserAvatar,
  },
  data: () => ({
    acceptedTerm: false,
    acceptedPolicy: false,
    baseURL: api.defaults.baseURL,
  }),
};
</script>

<style lang="scss" scoped></style>
