<template>
  <div id="customer-registration" class="d-flex">
    <v-container>
      <v-row>
        <v-col cols="12" class="mt-6 px-4">
          <h2 class="primary--text">Ficamos felizes em te receber!</h2>
          <p class="body-1 gray--text">Insira seus dados para cadastrar-se.</p>
          <div class="pa-6 mt-6 elevation-2 rounded-lg">
            <CustomerRegister />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- <Background /> -->
  </div>
</template>

<script>
// import Background from "@/components/general/Background.vue";
import CustomerRegister from "@/components/customer-registration/CustomerRegister.vue";

export default {
  components: {
    // Background,
    CustomerRegister,
  },
};
</script>

<style lang="scss" scoped>
#customer-registration {
  height: 100vh;
  width: 100%;
}
</style>
