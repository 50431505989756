<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
      md="4"
      lg="3"
      v-for="(installation, index) in installations"
      :key="index"
    >
      <InstallationCard :installation="installation" :index="index" />
    </v-col>
    <v-col cols="12" sm="6" md="3" lg="2" v-if="showComponent">
      <router-link
        :to="`/adicionar-instalacao?userId=${this.$props.userData.sub}`"
        class="text-decoration-none"
      >
        <div
          class="elevation-3 white pa-6 rounded-lg d-flex flex-column justify-center align-center card-size"
        >
          <div>
            <svg
              width="51"
              height="51"
              viewBox="0 0 51 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.5 25.5H17M25.5 17V25.5V17ZM25.5 25.5V34V25.5ZM25.5 25.5H34H25.5Z"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M25.5 46.75C37.236 46.75 46.75 37.236 46.75 25.5C46.75 13.7639 37.236 4.25 25.5 4.25C13.7639 4.25 4.25 13.7639 4.25 25.5C4.25 37.236 13.7639 46.75 25.5 46.75Z"
                stroke="#ffffff"
                stroke-width="2"
              />
            </svg>
          </div>
          <h4 class="mt-4 white--text text-center">Adicionar instalação</h4>
        </div>
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
import InstallationCard from "@/components/installations/InstallationCard.vue";

export default {
  props: {
    installations: {
      type: Array,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  components: {
    InstallationCard,
  },
  data: () => ({
    showComponent: true,
  }),
};
</script>

<style scoped lang="scss">
.card-size {
  height: 100%;
  background-color: #007d4b !important;
}
</style>
