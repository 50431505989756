<template>
  <div>
    <header class="d-flex align-center mb-4">
      <h2 class="gray--text">Modalidades cadastradas</h2>
      <v-spacer></v-spacer>
      <v-col cols="12" md="3">
        <v-select
          v-model="selectedModalityType"
          :items="availableTypes"
          label="Tipo de modalidade"
          class="d-flex justify-end"
          return-object
          solo
        ></v-select>
      </v-col>
    </header>
    <v-data-table
      :headers="headers"
      :items="modalitiesFiltered"
      :search="search"
      :loading="loading"
      loading-text="Buscando fazendas..."
      class="gray--text body-2"
    >
      <template slot="no-data">
        <span class="body-2 lightgray--text"
          >Nenhuma modalidade encontrada :(</span
        >
      </template>
      <template v-slot:item.modalityTypeId="{ item }">
        <span>{{ getModalityTypeName(item.modalityTypeId) }}</span>
      </template>
      <template v-slot:item.FarmConsumerUnitModalities.length="{ item }">
        <span v-if="item.FarmConsumerUnitModalities.length === 1">{{
          item.FarmConsumerUnitModalities.length + ` Consumidor`
        }}</span>
        <span v-if="item.FarmConsumerUnitModalities.length !== 1">{{
          item.FarmConsumerUnitModalities.length + ` Consumidores`
        }}</span>
      </template>
      <template v-slot:item.kWh="{ item }">
        <span v-if="item.kWh">{{
          item.kWh +
          ` kWh (` +
          calculatePercentage(item.kWh, item.allocated) +
          `)%`
        }}</span>
        <span v-else class="error--text">Indisponível</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <span>{{ item.actions }}</span>
        <div class="d-flex flex-row justify-lg-start">
          <router-link
            class="mr-6"
            :to="{
              path: `/modalidades/${
                item.modalityTypeId === 1 ? `consorcio` : `cooperativa`
              }/editar/${item.id}`,
            }"
          >
            <IconEdit />
          </router-link>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import IconEdit from "@/assets/icons/IconEdit.vue";
import { getAllModalities } from "@/services/user.js";

export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    IconEdit,
  },
  async created() {
    await this.fetchModalities();
  },
  data: () => ({
    search: "",
    modalities: [],
    modalitiesFiltered: [],
    availableTypes: [],
    modalityType: 0,
    selectedModalityType: "Todos",
    qtdConsumers: null,
    kWh: null,
    percentage: null,
    headers: [
      { text: "Tipo", value: "modalityTypeId" },
      { text: "Nome", value: "name" },
      { text: "# Documento", value: "document" },
      { text: "Consumidores", value: "FarmConsumerUnitModalities.length" },
      { text: "Ger. Estimada%", value: "kWh" },
      { text: "Ações", value: "actions", align: "center", sortable: false },
    ],
  }),
  watch: {
    selectedModalityType() {
      this.filterModalitiesByType();
    },
  },
  methods: {
    async fetchModalities() {
      const result = await getAllModalities();
      this.availableTypes = this.getModalityTypeName();
      this.modalities = result.data;
      this.modalitiesFiltered = result.data;
    },
    getModalityTypeName(id = null) {
      const modalityTypes = {
        0: "Todos",
        1: "Consórcio",
        2: "Cooperativa",
      };

      if (!id) {
        return Object.values(modalityTypes);
      }
      return modalityTypes[id];
    },
    getModalityTypeId(name = null) {
      const modalityTypes = {
        Todos: 0,
        Consórcio: 1,
        Cooperativa: 2,
      };

      if (!name) {
        return Object.values(modalityTypes);
      }
      return modalityTypes[name];
    },
    calculatePercentage(kWh, allocated) {
      return (this.percentage = ((allocated * 100) / kWh).toFixed(3));
    },
    filterModalitiesByType() {
      if (this.getModalityTypeId(this.selectedModalityType) === 0) {
        this.modalitiesFiltered = this.modalities;
      } else {
        this.modalitiesFiltered = this.modalities.filter(
          (modality) =>
            modality.modalityTypeId ===
            this.getModalityTypeId(this.selectedModalityType)
        );
      }
    },
  },
};
</script>
