import Vue from "vue";
import axios from "axios";

const baseURL = "https://api.macenergy.com.br/";
// const baseURL = "https://api.dev.eco-golden.nscreen.com.br/";

function createApiHeader() {
  const headers = {
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "*",
    "access-control-allow-origin": "*",
    "content-type": "application/json",
  };
  if (localStorage.access_token) {
    headers["Authorization"] = `Bearer ${localStorage.access_token}`;
  }
  return headers;
}
const api = axios.create({
  baseURL: baseURL,
  headers: createApiHeader(),
});

export default api;

Vue.use({
  install(Vue) {
    Vue.prototype.$api = api;
  },
});
