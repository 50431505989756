<template>
  <div>
    <ContentArea>
      <v-row>
        <v-col cols="12" md="4">
          <Breadcrumbs :items="breadcrumbs" />
        </v-col>
        <v-col cols="12" md="8">
          <v-row class="mb-4 mr-0" justify="end">
            <router-link
              class="mr-1"
              :to="{
                path: `/modalidades/consorcio`,
              }"
            >
              <v-btn color="primary" large>Criar Consórcio</v-btn>
            </router-link>
            <router-link
              :to="{
                path: `/modalidades/cooperativa`,
              }"
            >
              <v-btn color="primary" large>Criar Cooperativa</v-btn>
            </router-link>
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <ModalityList :modalities="modalityList" :loading="loading" />
    </ContentArea>
  </div>
</template>

<script>
import { getUsers } from "@/services/user.js";
import Breadcrumbs from "@/components/general/Breadcrumbs.vue";
import ContentArea from "@/components/general/ContentArea.vue";
import ModalityList from "@/components/registered/ModalityList.vue";

export default {
  name: "RegisterModality",
  components: {
    Breadcrumbs,
    ContentArea,
    ModalityList,
  },
  created() {
    this.getAllUsers();
  },
  data: () => ({
    loading: true,
    breadcrumbs: [
      {
        text: "Área do administrador",
      },
      {
        text: "Consorcios Cadastrados",
      },
    ],
    modalityList: [],
    consorcioType: 1,
    cooperativaType: 2,
  }),
  methods: {
    getAllUsers() {
      this.loading = true;
      getUsers()
        .then((res) => {
          this.modalityList = res.data.filter((user) => user.userTypeId === 4);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style></style>
