<template>
  <div>
    <v-dialog v-model="dialog" max-width="720" persistent>
      <v-card v-if="!response.isError">
        <v-card-title class="d-flex justify-center">
          <span class="body-1 font-weight-bold success--text mt-2">{{
            response.message
          }}</span>
        </v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="primary" outlined @click="dialog = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title class="d-flex justify-center">
          <span class="body-1 font-weight-bold error--text mt-2">{{
            response.message
          }}</span>
        </v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="primary" outlined @click="dialog = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    responseProp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      response: this.responseProp,
    };
  },
  watch: {
    responseProp(newValue) {
      this.response = newValue;
      this.dialog = true;
    },
  },
};
</script>
