<template>
  <v-col cols="12">
    <v-form v-model="validForm">
      <v-row>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="Nome *"
            outlined
            v-model="modalityInfo.name"
            :rules="[rules.required]"
            @change="setModalityInfo"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="CNPJ *"
            outlined
            v-model="modalityInfo.document"
            v-mask="'##.###.###/####-##'"
            :rules="[rules.required]"
            @change="setModalityInfo"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="Nome do Responsável"
            outlined
            v-model="modalityInfo.responsible"
            @change="setModalityInfo"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
            label="Contato (email ou telefone)"
            outlined
            v-model="modalityInfo.contact"
            @change="setModalityInfo"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-col>
</template>

<script>
export default {
  props: {
    rules: {
      type: Object,
      required: true,
    },
    modalityInfoProp: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data: () => ({
    validForm: false,
    modalityInfo: {
      name: "",
      document: "",
      contact: "",
      responsible: "",
    },
  }),
  watch: {
    modalityInfoProp: {
      handler: function (newVal) {
        this.modalityInfo = newVal;
      },
    },
  },
  methods: {
    setModalityInfo() {
      this.$emit("update:modalityInfo", this.modalityInfo);
    },
  },
};
</script>

<style></style>
