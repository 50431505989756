<template>
  <v-app-bar color="white" app height="64">
    <v-app-bar-nav-icon
      @click="
        showSideNav = !showSideNav;
        $emit('showSideNav', showSideNav);
      "
    ></v-app-bar-nav-icon>
    <v-container class="d-flex align-center">
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <Logo class="d-none d-md-flex" />
      <v-spacer></v-spacer>

      <v-spacer></v-spacer>

      <v-btn icon @click="$emit('showNotification', true)" color="#8A8A8A">
        <v-badge
          color="#FFA906"
          left
          overlap
          bordered
          :content="unreadNotifications"
          v-if="unreadNotifications"
        >
          <v-icon> mdi-bell-outline </v-icon>
        </v-badge>
        <v-icon v-else> mdi-bell-outline </v-icon>
      </v-btn>
      <HeaderMenu :profileName="profileName" />
    </v-container>
  </v-app-bar>
</template>

<script>
import HeaderMenu from "./HeaderMenu.vue";
import Logo from "./LogoEcoGolden.vue";
export default {
  name: "Header",
  components: {
    HeaderMenu,
    Logo,
  },
  props: {
    unreadNotifications: {
      type: Number,
      required: true,
    },
    profileName: {
      type: String,
      required: false,
      default: "",
    },
  },
  data: () => ({
    showSideNav: false,
  }),
};
</script>
