<template>
  <v-form v-model="validAddressForm">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <IconHome />
        <span
          class="ml-2 body-1 font-weight-bold gray--text"
          v-if="consumerUnit"
        >
          Insira os dados de endereço da unidade consumidora
        </span>
        <div v-else>
          <span
            class="ml-2 body-1 font-weight-bold gray--text"
            v-if="defaultAddress"
          >
            Insira os dados de endereço residencial do novo usuário
          </span>
          <span class="ml-2 body-1 font-weight-bold gray--text" v-else>
            Insira os dados de endereço para correspondência
          </span>
        </div>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="CEP *"
          outlined
          @change="getAddress()"
          :error-messages="
            cepError
              ? 'CEP não encontrado. Insira uma CEP válido para continuar'
              : ''
          "
          :hint="hint"
          persistent-hint
          v-mask="'#####-###'"
          v-model="addressData.zipCode"
          :rules="[rules.required, rules.cep]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Estado *"
          outlined
          disabled
          v-model="addressData.state"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Cidade *"
          outlined
          disabled
          v-model="addressData.city"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Logradouro *"
          outlined
          v-model="addressData.publicPlace"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Bairro *"
          outlined
          v-model="addressData.district"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="2">
        <v-text-field
          label="Número *"
          outlined
          v-model="addressData.number"
          :disabled="addressData.dontHaveNumber"
          :rules="[rules.required]"
        ></v-text-field>
        <v-checkbox
          class="mt-0"
          v-model="addressData.dontHaveNumber"
          label="Sem número"
          @change="
            addressData.dontHaveNumber == true
              ? (addressData.number = 'Sem número')
              : (addressData.number = '')
          "
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="6" lg="2">
        <v-text-field
          label="Complemento "
          outlined
          v-model="addressData.complement"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="d-flex justify-space-between"
        v-if="!customFooter"
      >
        <v-btn
          outlined
          color="primary"
          @click="$emit('decrementTab')"
          v-if="backButton"
        >
          Voltar
        </v-btn>
        <v-btn
          v-if="consumerUnit"
          color="primary"
          @click="
            $emit('incrementTab');
            $emit('setConsumerUnit', addressData);
          "
          :disabled="!validAddressForm"
        >
          Continuar
        </v-btn>
        <v-btn
          v-else
          color="primary"
          @click="
            $emit('incrementTab');
            $emit('setAddress', addressData);
          "
          :disabled="!validAddressForm"
        >
          Continuar
        </v-btn>
      </v-col>
      <v-col cols="12" class="d-flex justify-space-between mt-4" v-else>
        <v-btn
          color="primary"
          fab
          medium
          outlined
          class="elevation-0"
          @click="$emit('decrementTab')"
        >
          <v-icon dark> mdi-arrow-left </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          fab
          medium
          class="elevation-0"
          @click="
            $emit('incrementTab');
            $emit('setAddress', addressData);
          "
          :disabled="!validAddressForm"
        >
          <v-icon dark> mdi-arrow-right </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import IconHome from "@/assets/icons/IconHome.vue";
export default {
  props: {
    rules: {
      type: Object,
      required: true,
    },
    consumerUnit: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultAddress: {
      type: Boolean,
      required: false,
      default: true,
    },
    backButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    customFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    IconHome,
  },
  data: () => ({
    validAddressForm: false,
    cityList: [],
    stateList: [],
    cepError: false,
    hint: "Insira seu CEP para que os campos sejam preenchidos",
    addressData: {
      zipCode: "",
      state: "",
      city: "",
      publicPlace: "",
      district: "",
      dontHaveNumber: false,
      number: "",
      complement: "",
    },
  }),
  methods: {
    getAddress() {
      if (this.addressData.zipCode.length === 9) {
        const zipCode = this.addressData.zipCode.replace("-", "");
        fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
          .then((response) => response.json())
          .then((data) => {
            if (data.erro) {
              this.cepError = true;
              this.hint =
                "CEP não encontrado. Insira uma CEP válido para continuar.";
              this.addressData.city = "";
              this.addressData.state = "";
              this.addressData.district = "";
              this.addressData.publicPlace = "";
            } else {
              this.cepError = false;
              this.hint = "";
              this.addressData.city = data.localidade;
              this.addressData.state = data.uf;
              this.addressData.district = data.bairro;
              this.addressData.publicPlace = data.logradouro;
            }
          });
      }
    },
  },
};
</script>
