import api from "../plugins/axios";

// post
export async function createAdmin(userData) {
  const createAdmin = await api.post("/users/admin", userData);
  return createAdmin;
}

export async function createSeller(userData) {
  const createSeller = await api.post("/users/seller", userData);
  return createSeller;
}

export async function createClient(userData) {
  const createClient = await api.post("/users/client", userData);
  return createClient;
}

export async function createFarm(userData) {
  const createFarm = await api.post("/users/farm", userData);
  return createFarm;
}

export async function getFarms(modalityTypeId) {
  const getFarms = await api.get(
    `/farms/available/?modalityTypeId=${modalityTypeId}`
  );
  return getFarms;
}

export async function createConsumerUnit(consumerData, query = null) {
  const formData = new FormData();
  formData.append("file", consumerData.file);
  formData.append(
    "consumerUnitData",
    JSON.stringify(consumerData.consumerUnitData)
  );

  let queryParams;
  query ? (queryParams = query) : (queryParams = "");

  const createConsumerUnit = await api.post(
    `/consumer-units${queryParams}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return createConsumerUnit;
}

export async function createClientNoAuth(userData, files) {
  const formData = new FormData();

  for (const i of Object.keys(files)) {
    formData.append("files", files[i]);
  }

  formData.append("userData", JSON.stringify(userData));

  const createClientNoAuth = await api.post("/users/register", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return createClientNoAuth;
}

//put
export async function updateUser(userId, userData) {
  const putUser = await api.put(`/users/${userId}`, userData);
  return putUser;
}

//put
export async function updateUserStatus(userId, status = { status: "" }) {
  const putUser = await api.put(`/users/status/${userId}`, status);
  return putUser;
}

export async function updateConsumerUnit(consumerUnitId, consumerUnitData) {
  const putConsumerId = await api.put(
    `/consumer-units/${consumerUnitId}`,
    consumerUnitData
  );
  return putConsumerId;
}

//get
export async function getUsers() {
  const getUsers = await api.get("/users");
  return getUsers;
}

export async function getUserById(userId, userTypeId = null) {
  const url = `/users/${userId}${
    userTypeId ? `?userTypeId=${userTypeId}` : ""
  }`;

  const getUser = await api.get(url);
  return getUser;
}

export async function getConsumerUnit() {
  const getConsumerUnit = await api.get(`/consumer-units`);
  return getConsumerUnit;
}

export async function getConsumerUnitById(userId) {
  const getConsumerUnit = await api.get(`/consumer-units/?userId=${userId}`);
  return getConsumerUnit;
}

export async function getFarmConsumerUnitsByUserId(userId) {
  const getFarmConsumerUnits = await api.get(
    `farm-consumer-units/client/${userId}`
  );
  return getFarmConsumerUnits;
}

export async function getFarmProductionById(farmId) {
  const getFarmProduction = await api.get(`farms/${farmId}/production`);
  return getFarmProduction;
}

export async function createProduction(farmId, productionData) {
  const createFarmProduction = await api.post(
    `farms/${farmId}/production`,
    productionData
  );
  return createFarmProduction;
}

export async function updateGeneratedProduction(
  farmId,
  productionId,
  productionData
) {
  const putGeneratedProduction = await api.put(
    `farms/${farmId}/production/${productionId}`,
    productionData
  );
  return putGeneratedProduction;
}

export async function deleteGeneratedProduction(farmId, productionId) {
  const deleteGeneratedProduction = await api.delete(
    `farms/${farmId}/production/${productionId}`
  );
  return deleteGeneratedProduction;
}

export async function getConsumerUnitStatuses() {
  const getConsumerUnitStatus = await api.get(`/consumer-units-statuses/`);
  return getConsumerUnitStatus;
}

export async function getConsumerUnitByModalityTypeId(modalityTypeId) {
  const getConsumerUnit = await api.get(
    `/consumer-units/available/?modalityTypeId=${modalityTypeId}`
  );
  return getConsumerUnit;
}

export async function getUserNotifications(id) {
  const getUserNotifications = await api.get(`/users/${id}/notifications`);
  return getUserNotifications;
}

//delete
export async function deleteUser(userId) {
  const deleteUser = await api.delete(`/users/${userId}`);
  return deleteUser;
}

//patch
export async function readNotification(userId, notificationId, params) {
  const readNotification = await api.patch(
    `/users/${userId}/notifications/${notificationId}`,
    params
  );
  return readNotification;
}

export async function readAllNotifications(userId, params) {
  const readAllNotifications = await api.patch(
    `/users/${userId}/notifications`,
    params
  );
  return readAllNotifications;
}

export async function getAllFee() {
  const getAllMontlyFees = await api.get(`/monthly-fee`);
  return getAllMontlyFees;
}

export async function getFeeByYear(year) {
  const getAllMontlyFees = await api.get(`/monthly-fee/${year}`);
  return getAllMontlyFees;
}

export async function updateFee(feeId, feeData) {
  const putFee = await api.put(`/monthly-fee/${feeId}`, feeData);
  return putFee;
}

export async function deleteFee(feeId) {
  const deleteFee = await api.delete(`/monthly-fee/${feeId}`);
  return deleteFee;
}

export async function createFee(feeData) {
  const createFee = await api.post("/monthly-fee/", feeData);
  return createFee;
}

export async function getAllModalities() {
  const getAllModalities = await api.get(`/modalities`);
  return getAllModalities;
}

export async function getModalityById(modalityId) {
  const getModality = await api.get(`/modalities/${modalityId}`);
  return getModality;
}

export async function getAllModalitiesByType(modalityTypeId) {
  const getAllModalities = await api.get(
    `/modalities/?modalityTypeId=${modalityTypeId}`
  );
  return getAllModalities;
}

export async function getAllInvoicesByUserId(userId) {
  const getAllInvoices = await api.get(`/invoices/${userId}`);
  return getAllInvoices;
}

export async function getAllGeneratedInvoices(month, year) {
  const getAllGeneratedInvoices = await api.get(
    `/invoices/?month=${month}&year=${year}`
  );
  return getAllGeneratedInvoices;
}

export async function getAllInvoicesEconomyHistory(userId) {
  const getEconomyHistory = await api.get(`/invoices/${userId}/history`);
  return getEconomyHistory;
}

export async function getAllInvoicesEconomyHistoryByYear(userId, year) {
  const getEconomyHistory = await api.get(
    `/invoices/${userId}/history?year=${year}`
  );
  return getEconomyHistory;
}

export async function getGeneratedInvoiceByStatus(month, year, status) {
  const getGeneratedInvoiceByStatus = await api.get(
    `/invoices/?month=${month}&year=${year}&status=${status}`
  );
  return getGeneratedInvoiceByStatus;
}

export async function generateBilling(
  userId,
  consumerUnitId,
  invoiceId,
  billingData
) {
  const generateBilling = await api.post(
    `/billings/${userId}/${consumerUnitId}/${invoiceId}`,
    billingData
  );
  return generateBilling;
}

export async function getBillingById(
  userId,
  consumerUnitId,
  invoiceId,
  billingId
) {
  const getBilling = await api.get(
    `/billings/${userId}/${consumerUnitId}/${invoiceId}/${billingId}`
  );
  return getBilling;
}
