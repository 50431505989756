<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" md="5" lg="4">
        <DashboardCard
          color="primary"
          title="Total de vendas neste mês"
          content="R$ 798,43"
        />
      </v-col>
      <v-col cols="12" sm="6" md="5" lg="4">
        <DashboardCard
          color="#219653"
          title="Comissão acumulada neste mês"
          content="R$ 196,54"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="white pa-4 rounded-lg">
          <h2 class="gray--text ml-3 mb-3">Histórico de vendas</h2>

          <apexchart
            type="area"
            height="350"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardCard from "@/components/dashboard/DashboardCard.vue";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    DashboardCard,
    apexchart: VueApexCharts,
  },
  data: () => ({
    series: [
      {
        name: "Histórico de vendas",
        data: [
          578, 495, 506, 600, 650, 714, 630, 650, 829, 906, 750, 600, 680, 700,
        ],
      },
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      colors: ["#e48206"],
      xaxis: {
        type: "numeric",
        categories: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      },
    },
  }),
};
</script>

<style></style>
