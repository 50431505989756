<template>
  <div>
    <v-row>
      <v-col cols="12" md="10"></v-col>
      <v-col cols="12" md="2"></v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="invoices"
      :sort-by="['status', 'dueDate']"
      :sort-desc="[true, false]"
      :loading="loading"
      loading-text="Buscando tarifas..."
    >
      <template v-slot:item.installationNumber="{ item }">
        {{ item.installationNumber }}
      </template>
      <template v-slot:item.dueDate="{ item }">
        {{ item.dueDate }}
      </template>
      <template v-slot:item.value="{ item }">
        {{ `R$ ` + item.value }}
      </template>
      <template v-slot:item.kWh="{ item }">
        {{ item.kWh + ` kWh` }}
      </template>
      <template v-slot:item.status="{ item }">
        <span
          v-if="item.status === 'PENDENTE' || item.status === 'ATRASADA'"
          class="red--text font-weight-bold"
        >
          {{ item.status }}
        </span>
        <span
          v-if="item.status === 'PAGA'"
          class="primary--text font-weight-bold"
        >
          {{ item.status }}
        </span>
        <span
          v-if="item.status === 'CANCELADA' || item.status === 'PROCESSANDO'"
          class="font-weight-bold"
        >
          {{ item.status }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex flex-row justify-lg-start" :disable="true">
          <InvoiceModal :invoiceData="item" />
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import InvoiceModal from "@/components/invoices/InvoiceModal.vue";
export default {
  name: "InvoiceList",
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    invoices: {
      type: Array,
      required: true,
    },
  },
  components: {
    InvoiceModal,
  },

  data() {
    return {
      date: new Date(),
      headers: [
        { text: "Unidade Consumidora", value: "installationNumber" },
        { text: "Vencimento", value: "dueDate" },
        { text: "Valor", value: "value" },
        { text: "Consumo kWh", value: "kWh" },
        { text: "Status", value: "status" },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },

  methods: {},
};
</script>
