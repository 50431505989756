<template>
  <v-form v-model="validFarmInformationForm">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <IconEnergy />
        <span
          class="ml-2 body-1 font-weight-bold gray--text"
          v-if="kindOfPerson === 1"
        >
          Insira os dados da novo fazendeiro
        </span>
        <span
          class="ml-2 body-1 font-weight-bold gray--text"
          v-else-if="kindOfPerson === 2"
        >
          Insira os dados da nova fazenda
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Nome Completo *"
          outlined
          v-model="farmData.fullName"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4" v-if="kindOfPerson === 1">
        <v-text-field
          label="CPF *"
          outlined
          v-mask="'###.###.###-##'"
          v-model="farmData.physicalPerson"
          :rules="[rules.required, rules.cpf]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4" v-if="kindOfPerson === 2">
        <v-text-field
          label="CNPJ *"
          v-mask="'##.###.###/####-##'"
          outlined
          v-model="farmData.legalPerson"
          :rules="[rules.required, rules.cnpj]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4" v-if="kindOfPerson === 2">
        <v-text-field
          label="Razão Social *"
          outlined
          v-model="farmData.companyName"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Potência nominal (KVA) * "
          outlined
          type="Number"
          v-model="farmData.KVA"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Potência de placa (KWP) * "
          outlined
          type="Number"
          v-model="farmData.KWP"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Capacidade de geração mensal (kWh) * "
          outlined
          type="Number"
          v-model="farmData.kWh"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <v-btn outlined color="primary" @click="$emit('decrementTab')">
          Voltar
        </v-btn>
        <v-btn
          color="primary"
          @click="
            $emit('incrementTab');
            $emit('setFarmData', farmData);
          "
          :disabled="!validFarmInformationForm"
        >
          Continuar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import IconEnergy from "@/assets/icons/IconEnergy.vue";
export default {
  props: {
    kindOfPerson: {
      type: Number,
      required: true,
    },
    rules: {
      type: Object,
      required: true,
    },
  },
  components: {
    IconEnergy,
  },
  data: () => ({
    validFarmInformationForm: false,
    farmData: {
      fullName: "",
      physicalPerson: "",
      legalPerson: "",
      companyName: "",
      KVA: "",
      KWP: "",
      kWh: "",
    },
  }),
};
</script>

<style></style>
