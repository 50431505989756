<template>
  <div class="d-flex flex-column">
    <span class="body-2 gray--text">{{ field }}</span>
    <span class="mt-1 body-1 font-weight-bold primary--text text-uppercase">{{
      contents
    }}</span>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: String,
      required: true,
    },
    contents: {
      type: String,
      required: true,
    },
  },
};
</script>
