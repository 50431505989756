<template>
  <div class="mt-4">
    <v-row>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row class="align-center">
              <IconHome />
              <span class="ml-2 body-1 font-weight-bold primary--text"
                >Histórico de geração de energia</span
              >
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="overflow-auto">
            <v-data-table
              :headers="headers"
              :items="generatedHistory"
              class="elevation-1"
            >
              <template v-slot:item.actions="{ item }">
                <div class="d-flex flex-row justify-lg-start">
                  <EditProduction
                    class="mr-6"
                    :farmId="farmId"
                    :productionData="item"
                  />
                  <DeleteGeneratedProduction
                    :farmId="farmId"
                    :productionData="item"
                  />
                </div>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row class="justify-end mr-0 mb-4 mt-12">
      <RegisterProduction :farmId="this.farmId" />
    </v-row>
    <v-divider class="mb-4"></v-divider>
  </div>
</template>

<script>
import IconHome from "@/assets/icons/IconHome.vue";
import RegisterProduction from "@/components/register/RegisterProduction.vue";
import EditProduction from "@/components/profile/EditProduction.vue";
import DeleteGeneratedProduction from "@/components/profile/DeleteGeneratedProduction.vue";
import { getFarmProductionById } from "@/services/user.js";

export default {
  name: "RowGeneratedEnergyHistory",
  components: {
    IconHome,
    EditProduction,
    RegisterProduction,
    DeleteGeneratedProduction,
  },
  props: {
    farmId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Mês/Ano", value: "date" },
        { text: "Geração em kWh", value: "production" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      response: "",
      date: "",
      generatedHistory: [],
    };
  },
  created() {
    this.getFarmProductionById();
  },
  methods: {
    getFarmProductionById() {
      getFarmProductionById(this.farmId).then((res) => {
        const generatedList = res.data;
        this.generatedHistory = generatedList.map((kWh) => {
          return {
            id: kWh.id,
            date: kWh.month + "/" + kWh.year,
            production: kWh.production + " kWh",
          };
        });
      });
    },
  },
};
</script>

<style scoped></style>
