<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" />
    <v-col cols="12">
      <ContactForm />
    </v-col>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/general/Breadcrumbs.vue";
import ContactForm from "@/components/contact/ContactForm.vue";
export default {
  name: "Contact",
  components: {
    Breadcrumbs,
    ContactForm,
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Área do cliente",
      },
      {
        text: "Falar com a Eco Golden",
      },
    ],
    profileData: {},
  }),
};
</script>
