<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <IconUserAvatar />
        <span class="ml-2 body-1 font-weight-bold gray--text">
          Você é Pessoa Física ou Pessoa Jurídica?
        </span>
      </v-col>
      <v-col cols="12">
        <v-radio-group v-model="kindOfPerson">
          <v-radio label="Pessoa física (CPF)" :value="1"></v-radio>
          <v-radio label="Pessoa jurídica (CNPJ)" :value="2"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <router-link to="/auth" class="text-decoration-none">
          <v-btn color="primary" fab medium outlined class="elevation-0">
            <v-icon dark> mdi-arrow-left </v-icon>
          </v-btn>
        </router-link>
        <v-btn
          color="primary"
          fab
          medium
          class="elevation-0"
          @click="
            $emit('incrementTab');
            $emit('setKindOfPerson', kindOfPerson);
          "
        >
          <v-icon dark> mdi-arrow-right </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IconUserAvatar from "@/assets/icons/IconUserAvatar.vue";
export default {
  components: {
    IconUserAvatar,
  },
  data: () => ({
    kindOfPerson: 1,
  }),
};
</script>
