<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" md="6" lg="5" xl="4">
        <DashboardCard
          color="#FFA906"
          title="Total economizado até agora"
          :content="Number(this.economyValue.total)"
        />
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="5" xl="4">
        <DashboardCard
          color="primary"
          title="Próxima fatura"
          :content="Number(this.invoice.value)"
          :status="this.invoice.status"
          :invoiceData="this.invoice"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="white pa-4 rounded-lg">
          <v-row>
            <v-col cols="12" lg="10">
              <h2 class="gray--text ml-3 mb-3">Histórico de Economia</h2>
            </v-col>

            <v-col cols="12" lg="2">
              <v-select
                v-model="selectedYear"
                :items="availableYears"
                @change="getHistoryByYear"
                class="d-flex justify-end"
                solo
              ></v-select>
            </v-col>
          </v-row>

          <apexchart
            type="area"
            height="350"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardCard from "@/components/dashboard/DashboardCard.vue";
import {
  getAllInvoicesByUserId,
  getAllInvoicesEconomyHistory,
  getAllInvoicesEconomyHistoryByYear,
  getUserNotifications,
} from "@/services/user.js";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    DashboardCard,
    apexchart: VueApexCharts,
  },
  props: {
    userId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  beforeMount() {
    this.setInitialData();
    this.getNotification();
  },
  data: () => ({
    invoice: [],
    invoices: [],
    economyValue: [],
    selectedYear: null,
    availableYears: [],
    chartData: [],
    series: [],
    chartOptions: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      colors: ["#007D4B"],
      xaxis: {
        type: "string",
        categories: [],
      },
    },
  }),
  methods: {
    async setInitialData() {
      const invoicesData = await getAllInvoicesByUserId(this.userId);
      this.invoices = invoicesData.data.map((invoice) => {
        return {
          id: invoice.id,
          installationNumber: invoice.ConsumerUnit.installationNumber,
          dueDate: invoice.dueDate,
          value: invoice.value,
          kWh: invoice.consumptionAvailable,
          status: invoice.status,
          userId: this.userId,
          consumerUnitId: invoice.ConsumerUnit.id,
        };
      });
      this.invoices.sort((a, b) => {
        const dateA = new Date(
          a.dueDate.split("/").reverse().join("-")
        ).getTime();
        const dateB = new Date(
          b.dueDate.split("/").reverse().join("-")
        ).getTime();
        return dateB - dateA;
      });
      this.invoice = this.invoices[0];
      const economyData = await getAllInvoicesEconomyHistory(this.userId);
      this.economyValue = economyData.data;

      this.availableYears = this.economyValue.data.map((date) => date.year);
      this.selectedYear = this.availableYears[0];
      this.getHistoryByYear(this.selectedYear);
    },
    getHistoryByYear() {
      if (this.selectedYear) {
        this.loading = true;
        this.series = [];
        this.chartOptions.xaxis = {};
        getAllInvoicesEconomyHistoryByYear(this.userId, this.selectedYear)
          .then((response) => {
            this.chartData = response.data;
            this.series = [
              {
                name: "Histórico de economia",
                data: this.getEconomyValues(this.chartData.data[0]),
              },
            ];
            this.chartOptions = {
              xaxis: {
                type: "string",
                categories: this.getCategoriesValues(this.chartData.data[0]),
              },
            };
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => (this.loading = false));
      }
    },
    getEconomyValues(data) {
      return data.months.map((month) => Number(month.economy.toFixed(2)));
    },
    getCategoriesValues(data) {
      return data.months.map((month) => {
        switch (Number(month.month)) {
          case 1:
            return "Janeiro";
          case 2:
            return "Fevereiro";
          case 3:
            return "Março";
          case 4:
            return "Abril";
          case 5:
            return "Maio";
          case 6:
            return "Junho";
          case 7:
            return "Julho";
          case 8:
            return "Agosto";
          case 9:
            return "Setembro";
          case 10:
            return "Outubro";
          case 11:
            return "Novembro";
          case 12:
            return "Dezembro";
        }
      });
    },
    getNotification() {
      getUserNotifications(this.userId).then((res) => {
        this.notifications = res.data;
        this.notifications.map((notifcation) => {
          if (!notifcation.read) this.unreadNotifications++;
        });
      });
    },
  },
};
</script>

<style></style>
