<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <div class="d-flex align-center">
          <span
            class="body-1 gray--text font-weight-bold align-center justify-space-around"
          >
            Tarifas
          </span>
        </div>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          v-model="selectedYear"
          :items="availableYears"
          @change="findFeeByYear"
          class="d-flex justify-end"
          solo
        ></v-select>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="filteredFees"
      :loading="loading"
      loading-text="Buscando tarifas..."
    >
      <template v-slot:item.month="{ item }">
        {{ item.month | monthName }}
      </template>
      <template v-slot:item.fee="{ item }">
        {{ `R$ ` + item.fee }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex flex-row justify-lg-start">
          <EditFee class="mr-6" :feeData="item" />
          <DeleteFee :feeData="item" />
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getAllFee, getFeeByYear } from "@/services/user";
import EditFee from "@/components/profile/EditFee.vue";
import DeleteFee from "@/components/profile/DeleteFee.vue";

export default {
  name: "FeeList",
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    EditFee,
    DeleteFee,
  },

  data() {
    return {
      selectedYear: null,
      availableYears: [],
      fees: [],
      headers: [
        { text: "Mês", value: "month" },
        { text: "Valor", value: "fee" },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },

  created() {
    this.getAvailableYears();
  },

  computed: {
    filteredFees() {
      return this.fees.filter((tarifa) => tarifa.year === this.selectedYear);
    },
  },

  filters: {
    monthName(mes) {
      const nomesMeses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      return nomesMeses[mes - 1];
    },
  },

  methods: {
    getAvailableYears() {
      getAllFee()
        .then((response) => {
          const anos = Array.from(
            new Set(response.data.map((tarifa) => tarifa.year))
          );
          this.availableYears = anos.sort().reverse();
          this.selectedYear = this.availableYears[0];
          this.findFeeByYear();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    findFeeByYear() {
      if (this.selectedYear) {
        this.loading = true;
        getFeeByYear(this.selectedYear)
          .then((response) => (this.fees = response.data))
          .catch((error) => console.log(error))
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>
