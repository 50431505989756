<template>
  <v-form v-model="validProductionForm">
    <div class="text-center mt-7">
      <v-dialog v-model="dialog" max-width="480" persistent>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-row class="mb-4 mt-n16 mr-0" justify="end">
              <v-btn class="mt-4" color="primary" large>
                <span>Adicionar geração</span>
              </v-btn>
            </v-row>
          </div>
        </template>
        <v-card class="text-center py-12 px-6" v-if="!completed">
          <header>
            <h2 class="primary--text mb-10">Adicionar geração de energia</h2>
          </header>
          <body>
            <v-row class="justify-space-around">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newProduction"
                  label="Valor"
                  :rules="[rules.required]"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newDate"
                  label="Mês/Ano (MM/AAAA)"
                  outlined
                  v-mask="'##/####'"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
          </body>
          <v-card-actions class="d-flex justify-center mt-5">
            <v-btn
              class="mt-4 d-flex align-center justify-center"
              x-large
              color="primary"
              outlined
              @click="dialog = false"
            >
              Cancelar
            </v-btn>
            <CustomButton
              id="custom-button"
              class="mt-4 d-flex align-center justify-center"
              color="primary"
              @click="saveChanges()"
              :disabled="!validProductionForm"
            >
              Confirmar
            </CustomButton>
          </v-card-actions>
        </v-card>
        <div class="text-center" v-else>
          <v-card class="py-12">
            <svg
              v-if="success"
              width="123"
              height="123"
              viewBox="0 0 123 123"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M38 64.4375L55.625 82.0625L85 46.8125"
                stroke="#0D961B"
                stroke-width="5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M61.5 120.25C93.9467 120.25 120.25 93.9467 120.25 61.5C120.25 29.0533 93.9467 2.75 61.5 2.75C29.0533 2.75 2.75 29.0533 2.75 61.5C2.75 93.9467 29.0533 120.25 61.5 120.25Z"
                stroke="#0D961B"
                stroke-width="5"
              />
            </svg>
            <p
              class="body-1 font-weight-bold ma-6"
              :class="{ 'success--text': success, 'error--text': !success }"
            >
              {{ message }}
            </p>
            <v-btn color="primary" outlined class="mt-1" @click="complete()">
              Entendi
            </v-btn>
          </v-card>
        </div>
      </v-dialog>
    </div>
  </v-form>
</template>

<script>
import rules from "@/plugins/rules.js";
import { createProduction } from "@/services/user.js";
import CustomButton from "@/components/general/CustomButton.vue";

export default {
  components: {
    CustomButton,
  },
  data: () => ({
    rules: rules,
    success: null,
    completed: false,
    newProduction: null,
    newMonth: null,
    newYear: null,
    newDate: null,
    dialog: false,
    validProductionForm: false,
  }),
  props: {
    farmId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    createProduction(params) {
      createProduction(this.farmId, params)
        .then(() => {
          this.success = true;
          this.message = "Geração de energia adicionada com sucesso!";
        })
        .catch(() => {
          if (this.newMonth > 12 || this.newMonth < 1 || this.newYear < 2020) {
            this.message =
              "Mês ou ano inválidos. Verifique se o mês está entre 1 e 12 e o ano é maior que 2020!";
            return;
          }
          this.success = false;
          this.message = "Erro ao adicionar geração, tente novamente";
        })
        .finally(() => {
          this.completed = true;
        });
    },
    saveChanges() {
      this.newProduction = this.newProduction.replace(",", ".");
      this.newMonth = this.newDate.slice(0, 2);
      this.newYear = this.newDate.slice(3, 7);
      const params = {
        month: Number(this.newMonth),
        year: Number(this.newYear),
        production: Number(this.newProduction),
      };
      this.createProduction(params);
    },
    complete() {
      window.location.reload();
    },
  },
};
</script>
<style>
#custom-button {
  min-width: fit-content !important;
  max-width: 30% !important;
}
</style>
