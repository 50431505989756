<template>
  <div>
    <Dashboard />
  </div>
</template>

<script>
import Dashboard from "./Dashboard.vue";

export default {
  name: "Home",
  components: {
    Dashboard,
  },
};
</script>
