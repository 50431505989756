<template>
  <div>
    <v-col>
      <v-treeview
        class="text-body-1 font-weight-medium text--secondary"
        v-model="$store.state.treeViewListState.alreadySelected"
        :items="this.treeView_getItems"
        :item-key="'index'"
        selection-type="leaf"
        selected-color="primary"
        selectable
        @input="handleInput"
        return-object
        open-all
      ></v-treeview>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TreeViewList",
  computed: {
    ...mapGetters(["treeView_getItems", "treeView_getSelectedByOthers"]),
  },
  methods: {
    ...mapActions(["tempModalityData_addItem"]),
    handleInput(value) {
      this.tempModalityData_addItem(value);
    },
  },
};
</script>

<style scoped></style>
