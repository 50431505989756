<template>
  <v-form v-model="validUserInformationForm">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <IconUserAvatar />
        <span class="ml-2 body-1 font-weight-bold gray--text">
          Insira os dados pessoais do novo usuário
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <v-text-field
          label="Nome completo *"
          outlined
          v-model="userInfoData.fullName"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <v-text-field
          label="CPF *"
          outlined
          v-mask="'###.###.###-##'"
          v-model="userInfoData.physicalPerson"
          :rules="[rules.required, rules.cpf]"
        ></v-text-field>
      </v-col>
      <!--      <v-col cols="12" md="6" lg="4">-->
      <!--        <v-text-field-->
      <!--          label="RG *"-->
      <!--          outlined-->
      <!--          v-mask="-->
      <!--            userInfoData.idCard.length <= 10 ? '##.###.###' : '##.###.###-#'-->
      <!--          "-->
      <!--          v-model="userInfoData.idCard"-->
      <!--          :rules="[rules.required, rules.rg]"-->
      <!--        ></v-text-field>-->
      <!--      </v-col>-->
      <v-col cols="12" md="6" lg="6" v-if="kindOfPerson === 2">
        <v-text-field
          label="CNPJ *"
          v-mask="'##.###.###/####-##'"
          outlined
          v-model="userInfoData.legalPerson"
          :rules="[rules.required, rules.cnpj]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="6" v-if="kindOfPerson === 2">
        <v-text-field
          label="Razão Social *"
          outlined
          v-model="userInfoData.companyName"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <v-btn outlined color="primary" @click="$emit('decrementTab')">
          Voltar
        </v-btn>
        <v-btn
          color="primary"
          @click="
            $emit('incrementTab');
            $emit('setUserInfo', userInfoData);
          "
          :disabled="!validUserInformationForm"
        >
          Continuar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import IconUserAvatar from "@/assets/icons/IconUserAvatar.vue";
export default {
  props: {
    kindOfPerson: {
      type: Number,
      required: true,
    },
    rules: {
      type: Object,
      required: true,
    },
  },
  components: {
    IconUserAvatar,
  },
  data: () => ({
    validUserInformationForm: false,
    userInfoData: {
      fullName: "",
      idCard: "",
      physicalPerson: "",
      legalPerson: "",
      companyName: "",
    },
  }),
};
</script>

<style></style>
