<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" />
    <RegisterFee />
    <ContentArea>
      <FeeList :fees="feeList" :loading="loading" />
    </ContentArea>
  </div>
</template>

<script>
import { getUsers } from "@/services/user.js";
import Breadcrumbs from "@/components/general/Breadcrumbs.vue";
import RegisterFee from "@/components/register/RegisterFee.vue";
import ContentArea from "@/components/general/ContentArea.vue";
import FeeList from "@/components/registered/FeeList.vue";

export default {
  name: "RegisterFarm",
  components: {
    Breadcrumbs,
    RegisterFee,
    ContentArea,
    FeeList,
  },
  created() {
    this.getAllUsers();
  },
  data: () => ({
    loading: true,
    breadcrumbs: [
      {
        text: "Área do administrador",
      },
      {
        text: "Tarifas Cadastradas",
      },
    ],
    feeList: [],
  }),
  methods: {
    getAllUsers() {
      this.loading = true;
      getUsers()
        .then((res) => {
          this.feeList = res.data.filter((user) => user.userTypeId === 4);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style></style>
