import api from "@/plugins/axios";

const ALTERS_STATUS_ERR_MESSAGE = `Não foi possível alterar o status da unidade consumidora!`;
const CHARGE_UNIT_ERR_MESSAGE = `Não foi possível gerar a cobrança da unidade consumidora!`;

function _handleResultSuccess(result) {
  const response = {
    message: "",
    isError: false,
  };
  response.message = result.data.message;
  return response;
}

function _handleResultBadRequest(result) {
  const response = {
    message: "",
    isError: true,
  };
  response.message = result.data.message;
  return response;
}

function _handleResultInternalServerError(message) {
  return {
    message,
    isError: true,
  };
}

export async function approveStatus(consUnitId) {
  try {
    const result = await api.put(
      `/consumer-units/${consUnitId}/status/approve`,
      {}
    );
    return _handleResultSuccess(result);
  } catch (error) {
    if (error.response.status === 400)
      return _handleResultBadRequest(error.response);
    return _handleResultInternalServerError(ALTERS_STATUS_ERR_MESSAGE);
  }
}

export async function reproveStatus(consUnitId, comments = null) {
  try {
    const result = await api.put(
      `/consumer-units/${consUnitId}/status/reprove`,
      comments ? { comments } : {}
    );
    return _handleResultSuccess(result);
  } catch (error) {
    if (error.response.status === 400)
      return _handleResultBadRequest(error.response);
    return _handleResultInternalServerError(ALTERS_STATUS_ERR_MESSAGE);
  }
}

export async function chargeStatus(consUnitId) {
  try {
    const result = await api.put(
      `/consumer-units/${consUnitId}/status/charge`,
      {}
    );
    return _handleResultSuccess(result);
  } catch (error) {
    if (error.response.status === 400)
      return _handleResultBadRequest(error.response);
    return _handleResultInternalServerError(CHARGE_UNIT_ERR_MESSAGE);
  }
}
