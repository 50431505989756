<template>
  <div
    class="elevation-3 white pa-6 rounded-lg d-flex flex-column justify-center text-center"
    style="height: 100%"
  >
    <span class="grey--text text-md-body-1"
      >Sua conta do mês de {{ this.month | monthName }} está disponivel</span
    >
    <span class="primary--text text-lg-h4 mt-2"
      >R$ {{ this.invoice.value }}</span
    >
    <span class="text-left grey--text text-center">
      <v-icon x-small color="yellow">mdi-circle</v-icon>
      {{ this.invoice.status }}</span
    >
    <v-divider class="my-2"></v-divider>
    <span class="font-weight-bold">Vence em {{ this.invoice.dueDate }}</span>
    <span class="font-weight-bold"
      >Referenta a {{ this.month | monthName }} de {{ this.year }}</span
    >
  </div>
</template>

<script>
export default {
  name: "InvoiceCard",
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.setInitialData();
  },
  data() {
    return {
      month: null,
      year: null,
    };
  },
  methods: {
    setInitialData() {
      this.month = Number(this.invoice.dueDate.split("/")[1]);
      this.year = Number(this.invoice.dueDate.split("/")[2]);
    },
  },

  filters: {
    monthName(mes) {
      const nomesMeses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      return nomesMeses[mes - 1];
    },
  },
};
</script>
