<template>
  <div>
    <v-row>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row class="align-center">
              <IconHome />
              <span class="ml-2 body-1 font-weight-bold primary--text"
                >Unidades Consumidoras</span
              >
            </v-row>
            <v-row class="align-center justify-end mb-3">
              <span class="mr-4 primary--text font-weight-bold">{{
                sumInstallations
              }}</span>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="overflow-auto">
            <v-data-table
              :headers="headers"
              :items="installations"
              class="elevation-1"
            >
              <template v-slot:item.id="{ item }">
                <v-card class="elevation-0">
                  <v-card-text>
                    {{ "Num. Instalação: " }}
                    <span class="font-weight-bold">{{
                      "#" + item.installationNumber
                    }}</span>
                    <v-divider style="opacity: 0" />
                    {{ `${item.publicPlace}, ${item.number || "S/N"}` }}
                    <v-divider style="opacity: 0" />
                    <span v-if="item.complement">
                      {{ `${item.complement}` }}
                    </span>
                    {{ `${item.district}` }}
                    <v-divider style="opacity: 0" />
                    {{ `${item.city} - ${item.state} ${item.zipCode}` }}
                  </v-card-text>
                </v-card>
              </template>
              <template v-slot:item.farm="{ item }">
                <v-card
                  class="elevation-0"
                  v-if="item.farm.hasOwnProperty('id')"
                >
                  <v-card-text>
                    <span class="font-weight-bold">{{
                      item.farm.fullName
                    }}</span>
                    <v-divider style="opacity: 0" />
                    {{
                      `${item.farm.publicPlace}, ${item.farm.number || "S/N"}`
                    }}
                    <v-divider style="opacity: 0" />
                    <span v-if="item.farm.complement">
                      {{ `${item.farm.complement}` }}
                    </span>
                    {{ `${item.farm.district}` }}
                    <v-divider style="opacity: 0" />
                    {{
                      `${item.farm.city} - ${item.farm.state} ${item.farm.zipCode}`
                    }}
                  </v-card-text>
                </v-card>
                <v-card v-else class="elevation-0 transparent">
                  <v-card-text>
                    <span
                      class="d-flex flex-column text-center align-start font-weight-bold"
                      >Sem Vínculo</span
                    >
                  </v-card-text>
                </v-card>
              </template>
              <template v-slot:item.status="{ item }">
                <span
                  class="font-weight-bold text-center"
                  :style="{ color: getColor(item.status) }"
                >
                  {{ item.status.name }}
                </span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-row>
                  <StatusActionButton
                    class="mr-1 mb-1 text-center"
                    v-if="item.actions.reprove"
                    :text="item.actions.reprove.text"
                    :action="item.actions.reprove.fn"
                    :id="item.id"
                    :status="item.status"
                    :color="item.actions.reprove.color"
                    @click:buttonAction="handleButtonAction"
                  />
                  <StatusActionButton
                    class="mr-1 mb-1 text-center"
                    v-if="item.actions.approve"
                    :text="item.actions.approve.text"
                    :action="item.actions.approve.fn"
                    :id="item.id"
                    :status="item.status"
                    :color="item.actions.approve.color"
                    @click:buttonAction="handleButtonAction"
                  />
                  <StatusActionButton
                    class="mr-1 mb-1 text-center"
                    v-else-if="item.actions.charge"
                    :text="item.actions.charge.text"
                    :action="item.actions.charge.fn"
                    :id="item.id"
                    :status="item.status"
                    :color="item.actions.charge.color"
                    @click:buttonAction="handleButtonAction"
                  />
                  <span
                    v-else-if="item.status.id === 9"
                    class="font-weight-light text-center"
                  >
                    {{ item.note }}
                  </span>
                  <EditConsumerUnit
                    :unitData="item"
                    v-if="item.status.id !== 9"
                  />
                </v-row>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row class="justify-end pa-8 pr-10">
        <router-link :to="`/adicionar-instalacao?userId=${this.userId}`">
          <span class="primary--text font-weight-bold align-center"
            >Adicionar instalação</span
          >
        </router-link>
      </v-row>
      <DialogStatus :responseProp="response" />
    </v-row>
  </div>
</template>

<script>
import StatusActionButton from "@/components/profile/rows/StatusActionButton.vue";
import IconHome from "@/assets/icons/IconHome.vue";
import DialogStatus from "@/components/profile/rows/DialogStatus.vue";
import EditConsumerUnit from "../EditConsumerUnit.vue";

export default {
  name: "RowConsumerUnitList",
  components: {
    StatusActionButton,
    DialogStatus,
    IconHome,
    EditConsumerUnit,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    installations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Instalação",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Vínculo", value: "farm" },
        { text: "Status", value: "status" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      response: {},
    };
  },
  computed: {
    sumInstallations() {
      if (this.installations.length === 1) {
        return this.installations.length + " Instalação";
      } else {
        return this.installations.length + " Instalações";
      }
    },
  },
  methods: {
    getColor(status) {
      return status.color;
    },
    async handleButtonAction(action, id, comments = null) {
      let response = {};
      if (comments) {
        response = await action(id, comments);
      } else {
        response = await action(id);
      }
      this.response = response;
      this.$emit("updateInstallations");
    },
  },
};
</script>

<style scoped></style>
