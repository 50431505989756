<template>
  <v-navigation-drawer v-model="show" app temporary right width="500">
    <div class="white pa-4 px-6">
      <header>
        <div class="d-flex aling-end justify-end">
          <v-icon @click="show = false"> mdi-close </v-icon>
        </div>
        <div class="d-flex align-center justify-space-between mt-3">
          <h3 class="dark--text">Notificações</h3>
          <a
            class="dark--text caption text-decoration-underline"
            @click="readAllNotifications(params)"
            >Marcar todas como lidas
          </a>
        </div>
        <v-divider class="my-4"></v-divider>
      </header>
      <v-expansion-panels
        v-for="(notification, index) in notifications"
        :key="index"
        multiple
      >
        <v-expansion-panel
          @click="
            readNotification(
              notification.notificationId,
              notification.read,
              params
            )
          "
        >
          <v-expansion-panel-header disable-icon-rotate class="pa-0">
            <div>
              <span class="body-2 font-weight-bold dark--text">
                {{ notification.title }}
              </span>
              <p class="caption dark--text">
                {{ notification.text }}
              </p>
            </div>

            <template v-slot:actions>
              <v-icon color="teal" v-if="notification.read"> mdi-check </v-icon>
              <v-icon color="#FFA906" v-else> mdi-circle-medium </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="body-2 dark--text">
            <span v-html="notification.content"></span>
          </v-expansion-panel-content>
          <v-divider class="mb-4"></v-divider>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-navigation-drawer>
</template>

<script>
import jwt from "jwt-decode";
import {
  getUserNotifications,
  readNotification,
  readAllNotifications,
} from "@/services/user.js";
export default {
  props: {
    showNotification: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    showNotification(newValue) {
      this.show = newValue;
    },
    show(newValue) {
      if (!newValue) {
        this.$emit("showNotification", false);
      }
    },
  },
  created() {
    this.getNotification();
  },
  data: () => ({
    show: false,
    notifications: [],
    unreadNotifications: 0,
    params: {
      read: true,
    },
  }),
  methods: {
    getNotification() {
      const userId = jwt(localStorage.access_token).sub;
      getUserNotifications(userId).then((res) => {
        this.notifications = res.data;
        this.notifications.sort((a, b) =>
          a.read === b.read ? 0 : a.read ? 1 : -1
        );
        this.notifications.map((notification) => {
          if (!notification.read) this.unreadNotifications++;
        });
        this.emitNotification();
      });
    },
    readNotification(id, status, params) {
      if (!status) {
        const userId = jwt(localStorage.access_token).sub;
        readNotification(userId, id, params);
        this.unreadNotifications--;
        this.emitNotification();
      }
    },
    readAllNotifications(params) {
      const userId = jwt(localStorage.access_token).sub;
      readAllNotifications(userId, params);

      this.notifications.map((notification) => (notification.read = true));
      this.unreadNotifications = 0;
      this.emitNotification();
    },
    emitNotification() {
      this.$emit("unreadNotifications", this.unreadNotifications);
    },
  },
};
</script>

<style scoped lang="scss">
.v-expansion-panel::before {
  box-shadow: none !important;
}
.v-expansion-panel-content {
  .v-expansion-panel-content__wrap {
    padding: 0 0px 16px !important;
  }
}
</style>
