<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="d-flex align-center">
          <span class="body-1 gray--text font-weight-bold">
            Editar dados do Vendedor
          </span>
        </div>
        <v-divider class="mt-3"></v-divider>
      </v-col>
    </v-row>
    <DialogFeedback
      :completed="completed"
      :success="success"
      :message="message"
      @closeFeedback="closeFeedback()"
    />
    <RowPersonalData :isMyProfile="isMyProfile" :personalData="personalData" />
    <RowContact
      :contactDetails="contactDetails"
      @updateUser="updateUser($event)"
    />
    <RowAddress :addressData="addressData" @updateUser="updateUser($event)" />
    <RowPassword v-if="isMyProfile" @updateUser="updateUser($event)" />
    <RowCommission
      :isMyProfile="isMyProfile"
      :commissionData="commissionData"
      @updateUser="updateUser($event)"
    />
  </div>
</template>

<script>
import { updateUser } from "@/services/user.js";
import RowPersonalData from "@/components/profile/rows/RowPersonalData.vue";
import RowContact from "@/components/profile/rows/RowContact.vue";
import RowAddress from "@/components/profile/rows/RowAddress.vue";
import RowPassword from "@/components/profile/rows/RowPassword.vue";
import RowCommission from "@/components/profile/rows/RowCommission.vue";
import DialogFeedback from "@/components/general/DialogFeedback.vue";

export default {
  components: {
    RowPersonalData,
    RowContact,
    RowAddress,
    RowPassword,
    RowCommission,
    DialogFeedback,
  },
  props: {
    isMyProfile: {
      type: Boolean,
      required: false,
      default: false,
    },
    profileData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.setInitialData();
  },
  data: () => ({
    completed: false,
    success: null,
    message: "",
    personalData: {
      fullName: "",
      physicalPerson: "",
      legalPerson: "",
      companyName: "",
    },
    contactDetails: {
      primaryPhone: "",
      secondaryPhone: "",
      email: "",
    },
    addressData: {
      zipCode: "",
      publicPlace: "",
      number: "",
      complement: "",
      district: "",
      city: "",
      state: "",
    },
    commissionData: {
      commissionPercentage: "",
    },
    password: "",
  }),
  methods: {
    setInitialData() {
      this.personalData = {
        fullName: this.profileData.fullName,
        physicalPerson: this.profileData.physicalPerson,
        legalPerson: this.profileData.legalPerson,
        companyName: this.profileData.companyName,
        idUserType: this.profileData.idUserType,
        userTypeId: this.profileData.userTypeId,
      };
      this.contactDetails = {
        primaryPhone: this.profileData.primaryPhone,
        secondaryPhone: this.profileData.secondaryPhone,
        email: this.profileData.email,
      };
      this.addressData = {
        zipCode: this.profileData.zipCode,
        publicPlace: this.profileData.publicPlace,
        number: this.profileData.number,
        complement: this.profileData.complement,
        district: this.profileData.district,
        city: this.profileData.city,
        state: this.profileData.state,
      };
      this.commissionData = {
        commissionPercentage: this.profileData.commissionPercentage,
      };
    },
    setCommission(commission) {
      this.commissionData.commissionPercentage = commission;
    },
    updateUser(params) {
      updateUser(this.profileData.id, params)
        .then(() => {
          this.success = true;
          this.message = "Dados salvos com sucesso";
        })
        .catch(() => {
          this.success = false;
          this.message = "Erro ao editar os dados, tente novamente";
        })
        .finally(() => {
          this.completed = true;
        });
    },
    closeFeedback() {
      this.completed = false;
    },
  },
};
</script>

<style></style>
