var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('v-row',{staticClass:"align-center"},[_c('IconHome'),_c('span',{staticClass:"ml-2 body-1 font-weight-bold primary--text"},[_vm._v("Unidades Consumidoras")])],1),_c('v-row',{staticClass:"align-center justify-end mb-3"},[_c('span',{staticClass:"mr-4 primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.sumInstallations))])])],1),_c('v-expansion-panel-content',{staticClass:"overflow-auto"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.installations},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"elevation-0"},[_c('v-card-text',[_vm._v(" "+_vm._s("Num. Instalação: ")+" "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s("#" + item.installationNumber))]),_c('v-divider',{staticStyle:{"opacity":"0"}}),_vm._v(" "+_vm._s(((item.publicPlace) + ", " + (item.number || "S/N")))+" "),_c('v-divider',{staticStyle:{"opacity":"0"}}),(item.complement)?_c('span',[_vm._v(" "+_vm._s(("" + (item.complement)))+" ")]):_vm._e(),_vm._v(" "+_vm._s(("" + (item.district)))+" "),_c('v-divider',{staticStyle:{"opacity":"0"}}),_vm._v(" "+_vm._s(((item.city) + " - " + (item.state) + " " + (item.zipCode)))+" ")],1)],1)]}},{key:"item.farm",fn:function(ref){
var item = ref.item;
return [(item.farm.hasOwnProperty('id'))?_c('v-card',{staticClass:"elevation-0"},[_c('v-card-text',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.farm.fullName))]),_c('v-divider',{staticStyle:{"opacity":"0"}}),_vm._v(" "+_vm._s(((item.farm.publicPlace) + ", " + (item.farm.number || "S/N")))+" "),_c('v-divider',{staticStyle:{"opacity":"0"}}),(item.farm.complement)?_c('span',[_vm._v(" "+_vm._s(("" + (item.farm.complement)))+" ")]):_vm._e(),_vm._v(" "+_vm._s(("" + (item.farm.district)))+" "),_c('v-divider',{staticStyle:{"opacity":"0"}}),_vm._v(" "+_vm._s(((item.farm.city) + " - " + (item.farm.state) + " " + (item.farm.zipCode)))+" ")],1)],1):_c('v-card',{staticClass:"elevation-0 transparent"},[_c('v-card-text',[_c('span',{staticClass:"d-flex flex-column text-center align-start font-weight-bold"},[_vm._v("Sem Vínculo")])])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold text-center",style:({ color: _vm.getColor(item.status) })},[_vm._v(" "+_vm._s(item.status.name)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[(item.actions.reprove)?_c('StatusActionButton',{staticClass:"mr-1 mb-1 text-center",attrs:{"text":item.actions.reprove.text,"action":item.actions.reprove.fn,"id":item.id,"status":item.status,"color":item.actions.reprove.color},on:{"click:buttonAction":_vm.handleButtonAction}}):_vm._e(),(item.actions.approve)?_c('StatusActionButton',{staticClass:"mr-1 mb-1 text-center",attrs:{"text":item.actions.approve.text,"action":item.actions.approve.fn,"id":item.id,"status":item.status,"color":item.actions.approve.color},on:{"click:buttonAction":_vm.handleButtonAction}}):(item.actions.charge)?_c('StatusActionButton',{staticClass:"mr-1 mb-1 text-center",attrs:{"text":item.actions.charge.text,"action":item.actions.charge.fn,"id":item.id,"status":item.status,"color":item.actions.charge.color},on:{"click:buttonAction":_vm.handleButtonAction}}):(item.status.id === 9)?_c('span',{staticClass:"font-weight-light text-center"},[_vm._v(" "+_vm._s(item.note)+" ")]):_vm._e(),(item.status.id !== 9)?_c('EditConsumerUnit',{attrs:{"unitData":item}}):_vm._e()],1)]}}])})],1)],1)],1),_c('v-row',{staticClass:"justify-end pa-8 pr-10"},[_c('router-link',{attrs:{"to":("/adicionar-instalacao?userId=" + (this.userId))}},[_c('span',{staticClass:"primary--text font-weight-bold align-center"},[_vm._v("Adicionar instalação")])])],1),_c('DialogStatus',{attrs:{"responseProp":_vm.response}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }