<template>
  <div v-if="!completed">
    <v-tabs v-model="tab" background-color="white">
      <v-tab key="consumerUnit" :disabled="tab != 0"> Und. Consumidora </v-tab>
      <v-tab key="dataConfirmation" :disabled="tab != 1">
        Confirmação de dados
      </v-tab>
    </v-tabs>
    <v-divider class="mb-6"></v-divider>
    <v-tabs-items v-model="tab" class="pa-2">
      <v-tab-item key="consumerUnit">
        <TabConsumerUnit
          :rules="rules"
          :defaultAddress="false"
          :consumerUnit="true"
          @incrementTab="incrementTab"
          :backButton="false"
          @setConsumerUnit="setConsumerUnit($event)"
        />
      </v-tab-item>
      <v-tab-item key="dataConfirmation">
        <v-row>
          <v-col cols="12" class="d-flex align-center">
            <IconEnergy />
            <span class="ml-2 body-1 font-weight-bold gray--text">
              Confira os dados da nova instalação
            </span>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation field="CEP" :contents="consumerUnit.zipCode" />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation field="Estado" :contents="consumerUnit.state" />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation field="Cidade" :contents="consumerUnit.city" />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation
              field="Bairro"
              :contents="consumerUnit.district"
            />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation
              field="Logradouro"
              :contents="consumerUnit.publicPlace"
            />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation
              field="Número"
              :contents="consumerUnit.number ? consumerUnit.number : '--'"
            />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation
              field="Complemento"
              :contents="
                consumerUnit.complement ? consumerUnit.complement : '--'
              "
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation
              field="Número da Instalação"
              :contents="consumerUnit.installationNumber"
            />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation
              field="Faturas"
              :contents="dontHaveBills ? '--' : file.name"
            />
          </v-col>
          <v-col cols="12" md="6" lg="3" v-if="consumerUnit.creditDiscount">
            <DataConfirmation
              field="Desconto de Crédito"
              :contents="consumerUnit.creditDiscount + '%'"
            />
          </v-col>
          <v-col cols="12" md="6" lg="3" v-if="consumerUnit.consumptionAverage">
            <DataConfirmation
              field="Média de Consumo"
              :contents="consumerUnit.consumptionAverage + ' kWh'"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-space-between">
            <v-btn outlined color="primary" @click="tab = tab - 1">
              Voltar
            </v-btn>
            <v-btn color="primary" @click.once="createConsumerUnit()">
              Confirmar cadastro
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
  <div v-else class="mt-12 pt-12">
    <Feedback
      :success="success"
      :message="
        success
          ? 'Solicitação realizada com sucesso! Aguarde enquanto nossa equipe avalia sua solicitação.'
          : 'Houve um erro ao realizar sua solicitação, tente novamente mais tarde.'
      "
      :goTo="isAdmin() ? backToEdit : backToList"
      goToMessage="Voltar para Instalações"
    />
  </div>
</template>
<script>
import rules from "@/plugins/rules";
import { createConsumerUnit } from "@/services/user.js";
import DataConfirmation from "@/components/register/DataConfirmation.vue";
import TabConsumerUnit from "@/components/add-installation/tabs/TabConsumerUnit.vue";

import IconEnergy from "@/assets/icons/IconEnergy.vue";
import Feedback from "@/components/general/Feedback.vue";
import jwt from "jwt-decode";

export default {
  name: "RegisterInstallation",
  components: {
    DataConfirmation,
    TabConsumerUnit,
    Feedback,
    IconEnergy,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data: ($props) => ({
    completed: false,
    success: null,
    tab: 0,
    rules: rules,
    consumerUnit: {
      userId: $props.userId,
      zipCode: "",
      state: "",
      city: "",
      publicPlace: "",
      district: "",
      number: "",
      complement: "",
      installationNumber: "",
      creditDiscount: "",
      consumptionAverage: "",
      fileName: "",
    },
    dontHaveBills: true,
    file: null,
    jwt: jwt,
  }),
  methods: {
    setConsumerUnit(consumerUnitData) {
      this.consumerUnit.zipCode = consumerUnitData.zipCode;
      this.consumerUnit.state = consumerUnitData.state;
      this.consumerUnit.city = consumerUnitData.city;
      this.consumerUnit.publicPlace = consumerUnitData.publicPlace;
      this.consumerUnit.district = consumerUnitData.district;
      this.consumerUnit.number = consumerUnitData.number;
      this.consumerUnit.complement = consumerUnitData.complement;
      this.consumerUnit.installationNumber =
        consumerUnitData.installationNumber;
      this.file = consumerUnitData.file;
      this.consumerUnit.fileName =
        consumerUnitData.file.fileName || consumerUnitData.file.name;

      this.dontHaveBills = consumerUnitData.dontHaveBills;
      this.consumerUnit.creditDiscount = Number(
        consumerUnitData.creditDiscount.replace(",", ".").replace("%", "")
      );
      this.consumerUnit.consumptionAverage = Number(
        consumerUnitData.consumptionAverage
      );
    },
    createConsumerUnit() {
      const params = {
        file: this.file,
        consumerUnitData: this.consumerUnit,
      };

      console.log("PARAMS", params);

      createConsumerUnit(params, "?userId=" + this.consumerUnit.userId)
        .then(() => {
          this.success = true;
        })
        .catch(() => {
          this.success = false;
        })
        .finally(() => {
          this.completed = true;
        });
    },
    incrementTab() {
      this.tab += 1;
    },
    decrementTab() {
      this.tab -= 1;
    },
    backToEdit() {
      window.location.href = `/editar-usuario/${this.userId}`;
    },
    backToList() {
      window.location.href = `/instalacoes`;
    },
    isAdmin() {
      return [1, 2].includes(this.jwt(localStorage.access_token).role);
    },
  },
};
</script>
