var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"d-flex align-center mb-4"},[_c('h2',{staticClass:"gray--text"},[_vm._v("Modalidades cadastradas")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{staticClass:"d-flex justify-end",attrs:{"items":_vm.availableTypes,"label":"Tipo de modalidade","return-object":"","solo":""},model:{value:(_vm.selectedModalityType),callback:function ($$v) {_vm.selectedModalityType=$$v},expression:"selectedModalityType"}})],1)],1),_c('v-data-table',{staticClass:"gray--text body-2",attrs:{"headers":_vm.headers,"items":_vm.modalitiesFiltered,"search":_vm.search,"loading":_vm.loading,"loading-text":"Buscando fazendas..."},scopedSlots:_vm._u([{key:"item.modalityTypeId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getModalityTypeName(item.modalityTypeId)))])]}},{key:"item.FarmConsumerUnitModalities.length",fn:function(ref){
var item = ref.item;
return [(item.FarmConsumerUnitModalities.length === 1)?_c('span',[_vm._v(_vm._s(item.FarmConsumerUnitModalities.length + " Consumidor"))]):_vm._e(),(item.FarmConsumerUnitModalities.length !== 1)?_c('span',[_vm._v(_vm._s(item.FarmConsumerUnitModalities.length + " Consumidores"))]):_vm._e()]}},{key:"item.kWh",fn:function(ref){
var item = ref.item;
return [(item.kWh)?_c('span',[_vm._v(_vm._s(item.kWh + " kWh (" + _vm.calculatePercentage(item.kWh, item.allocated) + ")%"))]):_c('span',{staticClass:"error--text"},[_vm._v("Indisponível")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.actions))]),_c('div',{staticClass:"d-flex flex-row justify-lg-start"},[_c('router-link',{staticClass:"mr-6",attrs:{"to":{
            path: ("/modalidades/" + (item.modalityTypeId === 1 ? "consorcio" : "cooperativa") + "/editar/" + (item.id)),
          }}},[_c('IconEdit')],1)],1)]}}])},[_c('template',{slot:"no-data"},[_c('span',{staticClass:"body-2 lightgray--text"},[_vm._v("Nenhuma modalidade encontrada :(")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }