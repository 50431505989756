<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" />
    <ContentArea>
      <transition
        v-if="!$route.params.token"
        name="auth-transitions"
        :duration="{ enter: 500, leave: 100 }"
        mode="out-in"
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      >
        <div v-if="!selectedUserType.id" class="pa-2">
          <span class="primary--text font-weight-bold body-1"
            >Tipo de usuário</span
          >
          <v-divider class="my-4"></v-divider>
          <v-row>
            <v-col cols="12" class="d-flex align-center">
              <IconUserAvatar />
              <span class="ml-2 body-1 font-weight-bold gray--text">
                Selecione o tipo de usuário que deseja cadastrar
              </span>
            </v-col>
            <v-col cols="12" sm="8" md="4">
              <v-select
                v-model="selectedUserType"
                :items="userTypeList"
                item-text="description"
                item-vaLue="id"
                label="Tipo de usuário"
                return-object
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <RegisterAdmin
          :adminType="selectedUserType"
          v-if="selectedUserType.id === 1 || selectedUserType.id === 2"
        />
        <RegisterClient v-if="selectedUserType.id === 3" />
        <RegisterFarm v-if="selectedUserType.id === 4" />
        <RegisterSeller v-if="selectedUserType.id === 5" />
      </transition>
    </ContentArea>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/general/Breadcrumbs.vue";
import ContentArea from "@/components/general/ContentArea.vue";
import IconUserAvatar from "@/assets/icons/IconUserAvatar.vue";
import RegisterAdmin from "@/components/register/RegisterAdmin.vue";
import RegisterSeller from "@/components/register/RegisterSeller.vue";
import RegisterFarm from "@/components/register/RegisterFarm.vue";
import RegisterClient from "@/components/register/RegisterClient.vue";

export default {
  name: "Register",
  components: {
    Breadcrumbs,
    ContentArea,
    IconUserAvatar,
    RegisterAdmin,
    RegisterSeller,
    RegisterFarm,
    RegisterClient,
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Área do administrador",
      },
      {
        text: "Cadastro",
      },
    ],
    selectedUserType: 0,
    userTypeList: [
      {
        id: 1,
        description: "Administrador Master",
      },
      {
        id: 2,
        description: "Administrador Geral",
      },
      {
        id: 3,
        description: "Cliente",
      },
      {
        id: 4,
        description: "Fazenda",
      },
      {
        id: 5,
        description: "Vendedor",
      },
    ],
  }),
};
</script>

<style></style>
