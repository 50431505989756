<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" />
    <v-col cols="12">
      <ContentArea>
        <InstallationForm :userId="userId" />
      </ContentArea>
    </v-col>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/general/Breadcrumbs.vue";
import ContentArea from "@/components/general/ContentArea.vue";
import InstallationForm from "@/components/add-installation/InstallationForm.vue";
export default {
  name: "Contact",
  components: {
    Breadcrumbs,
    ContentArea,
    InstallationForm,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Área do cliente",
      },
      {
        text: "Falar com a Eco Golden",
      },
    ],
  }),
};
</script>
