<template>
  <v-form v-model="validFilesForm">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <IconUserAvatar />
        <span class="ml-2 body-1 font-weight-bold gray--text">
          Anexe uma foto dos seus documentos
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-file-input
          v-model="documentsData.rg1"
          label="RG Frente *"
          outlined
          :rules="[rules.required]"
          prepend-icon=""
          prepend-inner-icon="mdi-paperclip"
          @change="validFile(documentsData.rg1, 'rg1')"
          accept="image/jpeg,image/png,application/pdf"
        ></v-file-input>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-file-input
          v-model="documentsData.rg2"
          label="RG Verso *"
          outlined
          :rules="[rules.required]"
          prepend-icon=""
          prepend-inner-icon="mdi-paperclip"
          @change="validFile(documentsData.rg2, 'rg2')"
          accept="image/jpeg,image/png,application/pdf"
        ></v-file-input>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-file-input
          v-model="documentsData.cpf"
          label="CPF *"
          outlined
          :rules="[rules.required]"
          prepend-icon=""
          prepend-inner-icon="mdi-paperclip"
          @change="validFile(documentsData.cpf, 'cpf')"
          accept="image/jpeg,image/png,application/pdf"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <v-btn
          color="primary"
          fab
          medium
          outlined
          class="elevation-0"
          @click="$emit('decrementTab')"
        >
          <v-icon dark> mdi-arrow-left </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          fab
          medium
          class="elevation-0"
          :disabled="!validFilesForm"
          @click="
            $emit('incrementTab');
            $emit('setDocuments', documentsData);
          "
        >
          <v-icon dark> mdi-arrow-right </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import IconUserAvatar from "@/assets/icons/IconUserAvatar.vue";
export default {
  props: {
    rules: {
      type: Object,
      required: true,
    },
  },
  components: {
    IconUserAvatar,
  },
  data: () => ({
    validFilesForm: false,
    documentsData: {
      rg1: null,
      rg2: null,
      cpf: null,
    },
    notHasFile: false,
    errorRg1: "",
    errorRg2: "",
    errorCpf: "",
  }),
  methods: {
    validFile(file, documentName) {
      const acceptTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];

      const errorMessage =
        "A foto deve estar em um desses formatos: JPG, JPEG, PNG ou PDF";
      if (file) {
        if (acceptTypes.indexOf(file.type) === -1) {
          file = null;
          if (documentName === "cpf") this.errorCpf = errorMessage;
          else if (documentName === "rg1") this.errorRg1 = errorMessage;
          else this.errorRg2 = errorMessage;
        } else {
          // Conversão do nome da Imagem para o nome do documento
          file = new File([file], documentName, { type: file.type });
          if (documentName === "cpf") {
            this.errorCpf = "";
            this.documentsData.cpf = file;
          } else if (documentName === "rg1") {
            this.errorRg1 = "";
            this.documentsData.rg1 = file;
          } else {
            this.errorRg2 = "";
            this.documentsData.rg2 = file;
          }
        }
      }
    },
  },
};
</script>

<style></style>
