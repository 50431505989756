<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" />
    <ContentArea>
      <UserList :users="userList" :loading="loading" />
    </ContentArea>
  </div>
</template>

<script>
import { getUsers } from "@/services/user.js";
import Breadcrumbs from "@/components/general/Breadcrumbs.vue";
import ContentArea from "@/components/general/ContentArea.vue";
import UserList from "@/components/registered/UserList.vue";

export default {
  name: "Register",
  components: {
    Breadcrumbs,
    ContentArea,
    UserList,
  },
  created() {
    this.getAllUsers();
  },
  data: () => ({
    loading: false,
    breadcrumbs: [
      {
        text: "Área do administrador",
      },
      {
        text: "Usuários Cadastrados",
      },
    ],
    userList: [],
  }),
  methods: {
    getAllUsers() {
      this.loading = true;
      getUsers()
        .then((res) => {
          this.userList = res.data.filter((user) => user.userTypeId !== 4);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style></style>
