<template>
  <v-form v-model="validBillingDay">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <IconEdit />
        <span class="ml-2 body-1 font-weight-bold gray--text">
          Selecione a melhor data de pagamento para sua fatura
        </span>
      </v-col>
      <v-col cols="12">
        <v-date-picker
          v-model="billingDay"
          full-width
          class="elevation-5 my-8"
          color="primary"
          :show-current="false"
          :show-week="false"
          :show-adjacent-months="false"
          locale="pt-br"
        ></v-date-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <v-btn
          color="primary"
          fab
          medium
          outlined
          class="elevation-0"
          @click="$emit('decrementTab')"
        >
          <v-icon dark> mdi-arrow-left </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          fab
          medium
          class="elevation-0"
          :disabled="!billingDay"
          @click="
            $emit('incrementTab');
            $emit('setBillingDay', billingDay);
          "
        >
          <v-icon dark> mdi-arrow-right </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import IconEdit from "@/assets/icons/IconEdit.vue";
export default {
  props: {
    rules: {
      type: Object,
      required: true,
    },
  },
  components: {
    IconEdit,
  },
  data: () => ({
    validBillingDay: false,
    billingDay: "",
  }),
};
</script>

<style lang="scss" scoped>
.v-date-picker-header {
  display: none !important;
}
thead {
  opacity: 0 !important;
}
.v-date-picker-title {
  color: #e48206 !important;
}
</style>
