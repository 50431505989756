<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div>
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="6">
              <div class="d-flex align-center">
                <span class="body-1 gray--text font-weight-bold">
                  Editar dados da fazenda
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <div class="d-flex align-center justify-end mt-n4">
                <span class="body-1 primary--text font-weight-bold">
                  Status do usuário:
                </span>
                <RowUserStatus :farmValue="profileData.status" />
              </div>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mt-3"></v-divider>
        <v-sheet rounded :color="semaphore" class="text-center mt-2 pa-4"
          ><span class="body-1 white--text"
            >Produção Alocada:
            <v-divider vertical class="border-opacity-0 pl-4 pr-4"></v-divider>
            <span class="font-weight-bold">{{ this.farmData.allocated }}</span>
            kWh<v-divider
              vertical
              class="border-opacity-0 pl-2 pr-2"
            ></v-divider
            >de<v-divider
              vertical
              class="border-opacity-0 pl-2 pr-2"
            ></v-divider>
            <span class="font-weight-bold">{{ this.farmData.kWh }}</span>
            kWh<v-divider
              vertical
              class="border-opacity-0 pl-4 pr-4"
            ></v-divider>
            <span class="font-weight-bold"> {{ allocatedPercentage }}</span>
            da capacidade</span
          ></v-sheet
        >
      </v-col>
    </v-row>
    <DialogFeedback
      :completed="completed"
      :success="success"
      :message="message"
      @closeFeedback="closeFeedback()"
    />
    <RowFarmData :farmData="farmData" @updateUser="updateUser($event)" />
    <RowContact
      :contactDetails="contactDetails"
      @updateUser="updateUser($event)"
    />
    <RowGeneratedEnergyHistory :farmId="this.farmData.id" />
    <RowAddress :addressData="addressData" @updateUser="updateUser($event)" />
  </div>
</template>

<script>
import { updateUser } from "@/services/user.js";
import RowFarmData from "@/components/profile/rows/RowFarmData.vue";
import RowContact from "@/components/profile/rows/RowContact.vue";
import RowGeneratedEnergyHistory from "@/components/profile/rows/RowGeneratedEnergyHistory.vue";
import RowAddress from "@/components/profile/rows/RowAddress.vue";
import RowUserStatus from "./rows/RowUserStatus.vue";
import DialogFeedback from "@/components/general/DialogFeedback.vue";

export default {
  components: {
    RowFarmData,
    RowContact,
    RowGeneratedEnergyHistory,
    RowAddress,
    DialogFeedback,
    RowUserStatus,
  },
  props: {
    profileData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.setInitialData();
  },
  computed: {
    allocatedPercentage() {
      return `${((this.farmData.allocated / this.farmData.kWh) * 100).toFixed(
        3
      )}%`;
    },
    semaphore() {
      const percentage = (
        (this.farmData.allocated / this.farmData.kWh) *
        100
      ).toFixed(3);
      if (percentage <= 65) {
        return "success";
      } else if (percentage <= 90) {
        return "yellow";
      } else {
        return "error";
      }
    },
  },
  data: () => ({
    completed: false,
    success: null,
    message: "",
    farmData: {
      fullName: "",
      KVA: "",
      KWP: "",
      kWh: "",
    },
    contactDetails: {
      primaryPhone: "",
      secondaryPhone: "",
      email: "",
    },
    addressData: {
      zipCode: "",
      publicPlace: "",
      number: "",
      complement: "",
      district: "",
      city: "",
      state: "",
    },
    commissionData: {
      commissionPercentage: "",
    },
    password: "",
  }),
  methods: {
    setInitialData() {
      this.farmData = {
        id: this.profileData.id,
        fullName: this.profileData.fullName,
        KVA: this.profileData.KVA,
        KWP: this.profileData.KWP,
        kWh: this.profileData.kWh,
        allocated: this.profileData.allocated,
      };
      this.contactDetails = {
        primaryPhone: this.profileData.primaryPhone,
        secondaryPhone: this.profileData.secondaryPhone,
        email: this.profileData.email,
      };
      this.addressData = {
        zipCode: this.profileData.zipCode,
        publicPlace: this.profileData.publicPlace,
        number: this.profileData.number,
        complement: this.profileData.complement,
        district: this.profileData.district,
        city: this.profileData.city,
        state: this.profileData.state,
      };
    },
    updateUser(params) {
      updateUser(this.profileData.id, params)
        .then(() => {
          this.success = true;
          this.message = "Dados salvos com sucesso";
        })
        .catch(() => {
          this.success = false;
          this.message = "Erro ao editar os dados, tente novamente";
        })
        .finally(() => {
          this.completed = true;
        });
    },
    closeFeedback() {
      this.completed = false;
    },
  },
};
</script>

<style></style>
