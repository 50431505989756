<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="672" persistent>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <IconDelete />
        </div>
      </template>
      <v-card class="text-center py-12 px-6" v-if="!completed">
        <header>
          <h2 class="primary--text mb-10">
            Tem certeza que deseja excluir a quantidade de energia gerada
            abaixo?
          </h2>
        </header>
        <body class="mt-6">
          <div class="body-1 lightgray--text mb-4">
            <strong>Mês de referência: </strong>{{ productionData.date }}
          </div>
          <div class="body-1 lightgray--text mb-4">
            <strong>Quantidade de energia gerada: </strong>
            {{ productionData.production }}
          </div>
        </body>

        <v-card-actions class="mt-5">
          <v-btn color="primary" outlined @click="dialog = false">
            Voltar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="deleteGeneratedProduction()">
            Deletar
          </v-btn>
        </v-card-actions>
      </v-card>
      <div class="text-center" v-else>
        <v-card class="py-12">
          <svg
            v-if="success"
            width="123"
            height="123"
            viewBox="0 0 123 123"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M38 64.4375L55.625 82.0625L85 46.8125"
              stroke="#0D961B"
              stroke-width="5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M61.5 120.25C93.9467 120.25 120.25 93.9467 120.25 61.5C120.25 29.0533 93.9467 2.75 61.5 2.75C29.0533 2.75 2.75 29.0533 2.75 61.5C2.75 93.9467 29.0533 120.25 61.5 120.25Z"
              stroke="#0D961B"
              stroke-width="5"
            />
          </svg>
          <p
            class="body-1 font-weight-bold mt-2"
            :class="{ 'success--text': success, 'error--text': !success }"
          >
            {{ message }}
          </p>
          <v-btn color="primary" outlined class="mt-1" @click="complete()">
            Entendi
          </v-btn>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { deleteGeneratedProduction } from "@/services/user.js";
import IconDelete from "@/assets/icons/IconDelete.vue";

export default {
  components: {
    IconDelete,
  },
  props: {
    productionData: {
      type: Object,
      required: true,
    },
    farmId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    success: null,
    completed: false,
    message: "Geração de energia excluída com sucesso!",
    dialog: false,
  }),
  methods: {
    deleteGeneratedProduction() {
      deleteGeneratedProduction(this.farmId, this.productionData.id)
        .then(() => {
          this.success = true;
          this.message = "Geração de energia excluída com sucesso!";
        })
        .catch(() => {
          this.success = false;
          this.message = "Erro ao geração de energia, tente novamente";
        })
        .finally(() => {
          this.completed = true;
        });
    },
    complete() {
      window.location.reload();
    },
  },
};
</script>
