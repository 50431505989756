<template>
  <v-form v-model="validAddressForm">
    <v-row v-for="(addressData, index) in consumerUnit" :key="index">
      <v-col cols="12" class="d-flex align-center">
        <IconHome />
        <span class="ml-2 body-1 font-weight-bold gray--text">
          Insira os dados de endereço e consumo da unidade consumidora
          {{ index + 1 }}
        </span>
        <v-spacer />
        <v-btn
          color="error"
          fab
          small
          v-if="index !== 0"
          @click="removeConsumerUnit(index)"
        >
          <v-icon dark> mdi-delete</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="CEP *"
          outlined
          @change="getAddress(index)"
          :error-messages="
            addressData.details.cepError
              ? 'CEP não encontrado. Insira uma CEP válido para continuar'
              : ''
          "
          :hint="addressData.details.hint"
          persistent-hint
          v-mask="'#####-###'"
          v-model="addressData.zipCode"
          :rules="[rules.required, rules.cep]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Estado *"
          outlined
          disabled
          v-model="addressData.state"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Cidade *"
          outlined
          disabled
          v-model="addressData.city"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Logradouro *"
          outlined
          v-model="addressData.publicPlace"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Bairro *"
          outlined
          v-model="addressData.district"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="2">
        <v-text-field
          label="Número *"
          outlined
          v-model="addressData.number"
          :disabled="addressData.details.dontHaveNumber"
          :rules="[rules.required]"
        ></v-text-field>
        <v-checkbox
          class="mt-0"
          v-model="addressData.details.dontHaveNumber"
          label="Sem número"
          @change="
            addressData.details.dontHaveNumber == true
              ? (addressData.number = 'Sem número')
              : (addressData.number = '')
          "
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="6" lg="2">
        <v-text-field
          label="Complemento "
          outlined
          v-model="addressData.complement"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Número da Instalação *"
          outlined
          v-model="addressData.installationNumber"
          :rules="[rules.required, rules.numeric]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-file-input
          v-model="addressData.fileName"
          label="Foto da última fatura de energia que recebeu desta instalação *"
          :disabled="addressData.details.notHasFile"
          outlined
          :error="
            addressData.fileName == null && !addressData.details.notHasFile
          "
          prepend-icon=""
          prepend-inner-icon="mdi-paperclip"
          :error-messages="addressData.details.error"
          @change="validFile(index)"
          accept="image/jpeg,image/png,application/pdf"
        ></v-file-input>
        <v-checkbox
          class="mt-0"
          v-model="addressData.details.notHasFile"
          @change="setFileNull(index)"
          label="Não possuo faturas de energia do endereço de instalação."
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="end">
        <v-btn color="primary" class="elevation-0" @click="addConsumerUnit()">
          Adicionar outro endereço
        </v-btn>
        <v-divider class="mt-3"></v-divider>
      </v-col>
      <v-col cols="12" class="d-flex justify-space-between mt-4">
        <v-btn
          color="primary"
          fab
          medium
          outlined
          class="elevation-0"
          @click="$emit('decrementTab')"
        >
          <v-icon dark> mdi-arrow-left </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          fab
          medium
          class="elevation-0"
          @click="
            $emit('incrementTab');
            $emit('setConsumerUnit', consumerUnit);
          "
          :disabled="!validAddressForm"
        >
          <v-icon dark> mdi-arrow-right </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import IconHome from "@/assets/icons/IconHome.vue";
export default {
  props: {
    rules: {
      type: Object,
      required: true,
    },
  },
  components: {
    IconHome,
  },
  data: () => ({
    validAddressForm: false,
    consumerUnit: [
      {
        details: {
          hint: "Insira seu CEP para que os campos sejam preenchidos",
          dontHaveNumber: false,
          notHasFile: true,
          error: "",
          cepError: false,
        },
        zipCode: "",
        state: "",
        city: "",
        publicPlace: "",
        district: "",
        number: "",
        complement: "",
        installationNumber: "",
        status: false,
        fileName: null,
      },
    ],
  }),
  methods: {
    addConsumerUnit() {
      this.consumerUnit.push({
        details: {
          dontHaveNumber: false,
          notHasFile: true,
          error: "",
          cepError: false,
        },
        zipCode: "",
        state: "",
        city: "",
        publicPlace: "",
        district: "",
        number: "",
        complement: "",
        installationNumber: "",
        status: false,
        fileName: null,
      });
    },
    removeConsumerUnit(index) {
      this.consumerUnit.splice(index, 1);
    },
    getAddress(index) {
      if (this.consumerUnit[index].zipCode.length === 9) {
        const zipCode = this.consumerUnit[index].zipCode.replace("-", "");
        fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
          .then((response) => response.json())
          .then((data) => {
            if (data.erro) {
              this.consumerUnit[index].details.cepError = true;
              this.consumerUnit[index].details.hint =
                "CEP não encontrado. Insira uma CEP válido para continuar.";
              this.consumerUnit[index].city = "";
              this.consumerUnit[index].state = "";
              this.consumerUnit[index].district = "";
              this.consumerUnit[index].publicPlace = "";
            } else {
              this.consumerUnit[index].details.cepError = false;
              this.consumerUnit[index].details.hint = "";
              this.consumerUnit[index].city = data.localidade;
              this.consumerUnit[index].state = data.uf;
              this.consumerUnit[index].district = data.bairro;
              this.consumerUnit[index].publicPlace = data.logradouro;
            }
          });
      }
    },
    setFileNull(index) {
      this.consumerUnit[index].fileName = null;
      this.consumerUnit[index].details.error = "";
    },
    validFile(index) {
      const acceptTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      if (this.consumerUnit[index].fileName) {
        if (
          acceptTypes.indexOf(this.consumerUnit[index].fileName.type) === -1
        ) {
          this.consumerUnit[index].fileName = null;
          this.error =
            "A foto deve estar em um desses formatos: JPG, JPEG, PNG ou PDF";
        } else {
          let file = this.consumerUnit[index].fileName;
          this.consumerUnit[index].details.error = "";

          file = new File([file], `conta${index + 1}`, { type: file.type });
          this.consumerUnit[index].fileName = file;
        }
      }
    },
  },
};
</script>
