<template>
  <v-form v-model="validCommissionForm">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <IconPercentage />
        <span class="ml-2 body-1 font-weight-bold gray--text">
          Insira o percentual de comissão do vendedor
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          outlined
          label="Percentual de comissão *"
          prefix="%"
          type="number"
          v-model="commission"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <v-btn outlined color="primary" @click="$emit('decrementTab')">
          Voltar
        </v-btn>
        <v-btn
          color="primary"
          @click="
            $emit('incrementTab');
            $emit('setCommission', commission);
          "
          :disabled="!validCommissionForm"
        >
          Continuar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import IconPercentage from "@/assets/icons/IconPercentage.vue";
export default {
  props: {
    rules: {
      type: Object,
      required: true,
    },
  },
  components: {
    IconPercentage,
  },
  data: () => ({
    validCommissionForm: false,
    commission: 0,
  }),
};
</script>
