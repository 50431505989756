<template>
  <v-form v-model="valid" id="recover-password">
    <v-container>
      <v-row>
        <v-col>
          <h4 class="text-h4 font-weight-bold primary--text text-center">
            Recuperação de senha
          </h4>
          <p class="lightgray--text body-1 mt-3 text-center">
            Para recuperar sua senha, enviaremos um link para o e-mail informado
            abaixo
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            label="E-mail"
            v-model="email"
            type="text"
            :rules="[rules.required, rules.email]"
            prepend-inner-icon="mdi-account-circle-outline"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <div v-if="success" class="caption mb-1 success--text">
            Link enviado com sucesso! Acesse o seu e-mail para prosseguir com a
            recuperação de senha.
          </div>
          <div v-if="error" class="caption mb-1 error--text">
            Ocorreu um erro ao enviar o link. Insira o seu e-mail e tente
            novamente.
          </div>
          <v-btn
            color="primary"
            :disabled="!valid"
            x-large
            block
            @click="recoverPassword()"
          >
            Enviar Link
          </v-btn>
          <v-btn
            color="primary"
            class="mt-4"
            x-large
            block
            outlined
            @click="$emit('change')"
          >
            Voltar ao login
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import rules from "@/plugins/rules";
import { passwordReset } from "@/services/auth.js";

export default {
  name: "Recoverpassword",
  data: () => ({
    rules: rules,
    email: "",
    valid: false,
    success: false,
    error: false,
  }),
  methods: {
    recoverPassword() {
      passwordReset(this.email)
        .then(() => {
          this.success = true;
        })
        .catch(() => {
          this.error = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#recover-password {
  max-width: 476px;
  width: 100%;
}
</style>
