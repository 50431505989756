<template>
  <v-col cols="12" class="d-flex justify-space-between">
    <v-col cols="8">
      <SelectedList
        :selection="this.checkbox_getAlreadySelected"
        :vinculation="true"
        @clicked:vinculation="handleVinculationButtonClick"
      />
    </v-col>
    <v-divider vertical></v-divider>
    <v-col cols="4">
      <Search headerText="Selecione Fazendas" :search-farm="true" />
      <CheckBoxList />
    </v-col>
  </v-col>
</template>

<script>
import CheckBoxList from "@/components/modalidades/CheckBoxList.vue";
import SelectedList from "@/components/modalidades/SelectedList.vue";
import Search from "@/components/general/Search.vue";
import { mapGetters } from "vuex";

export default {
  name: "ParentTab",
  components: { Search, CheckBoxList, SelectedList },
  computed: {
    ...mapGetters(["checkbox_getAlreadySelected"]),
  },
  methods: {
    handleVinculationButtonClick(node) {
      this.$emit("navigation:toItem", node);
    },
  },
};
</script>

<style scoped></style>
