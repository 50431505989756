<template>
  <div>
    <header class="d-flex align-center mb-4">
      <h2 class="gray--text">Usuários cadastrados</h2>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        class="elevation-0 body-2 lightgray--text"
        dense
        hide-details
        solo
        label="Pesquisar"
        filled
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
    </header>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      :loading="loading"
      loading-text="Buscando usuários..."
      class="gray--text body-2"
    >
      <template slot="no-data">
        <span class="body-2 lightgray--text">Nenhum usuário encontrado :(</span>
      </template>
      <template v-slot:item.userType="{ item }">
        <span v-if="item.userTypeId === 1">Administrador Master</span>
        <span v-if="item.userTypeId === 2">Administrador Geral</span>
        <span v-if="item.userTypeId === 3">Cliente</span>
        <span v-if="item.userTypeId === 4">Fazenda</span>
        <span v-if="item.userTypeId === 5">Vendedor</span>
      </template>
      <template v-slot:item.personType="{ item }">
        <span v-if="item.legalPerson">{{ item.legalPerson }}</span>
        <span v-else>{{ item.physicalPerson }}</span>
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.status == 'ATIVO'" class="success--text">Ativo</span>
        <span v-if="item.status == 'PENDENTE'" class="warning--text"
          >Pendente</span
        >
        <span v-if="item.status == 'INATIVO'" class="error--text">Inativo</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-space-around">
          <router-link :to="`/editar-usuario/${item.id}`">
            <IconEdit />
          </router-link>
          <DeleteUser :profileData="item" />
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import IconEdit from "@/assets/icons/IconEdit.vue";
import DeleteUser from "@/components/profile/DeleteUser.vue";
export default {
  props: {
    users: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  components: {
    IconEdit,
    DeleteUser,
  },
  data: () => ({
    search: "",
    headers: [
      { text: "Nome", value: "fullName" },
      { text: "Tipo de usuário", value: "userType", sortable: false },
      { text: "CPF / CNPJ", value: "personType", sortable: false },
      { text: "Status", value: "status" },
      { text: "Ações", value: "actions", align: "center", sortable: false },
    ],
  }),
};
</script>
