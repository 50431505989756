<template>
  <v-form v-model="validFarmDataForm">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <IconUserAvatar />
        <span class="ml-2 body-1 font-weight-bold primary--text">
          Dados da fazenda
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Nome da fazenda *"
          outlined
          disabled
          v-model="farmData.fullName"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Potência nominal (KVA) * "
          outlined
          type="number"
          v-model="farmData.KVA"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Potência de placa (KWP) * "
          outlined
          type="number"
          v-model="farmData.KWP"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Capacidade de geração mensal (kWh) * "
          outlined
          type="number"
          v-model="farmData.kWh"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          :disabled="!validFarmDataForm"
          color="primary"
          @click="saveChanges"
          >Salvar Dados</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import rules from "@/plugins/rules";
import IconUserAvatar from "@/assets/icons/IconUserAvatar.vue";
export default {
  components: {
    IconUserAvatar,
  },
  props: {
    farmData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    rules: rules,
    validFarmDataForm: false,
  }),
  methods: {
    saveChanges() {
      const params = {
        fullName: this.farmData.fullName,
        KVA: this.farmData.KVA,
        KWP: this.farmData.KWP,
        kWh: this.farmData.kWh,
      };
      this.$emit("updateUser", params);
    },
  },
};
</script>

<style></style>
