<template>
  <v-breadcrumbs
    :items="items"
    divider=">"
    class="gray--text pl-0 pt-0"
  ></v-breadcrumbs>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
