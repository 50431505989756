<template>
  <div>
    <v-dialog v-model="comments" max-width="720" persistent>
      <v-card class="pa-4">
        <v-card-title class="d-flex justify-center">
          <span class="body-1 font-weight-bold success--text mt-2"
            >Motivo de Cancelamento</span
          >
        </v-card-title>
        <v-textarea
          v-model="commentsText"
          auto-grow
          crealable
          counter
          label="Observações"
          rows="1"
        ></v-textarea>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="primary" outlined @click="confirm">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "StatusCommentDialog",
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    comments: false,
    commentsText: null,
  }),
  watch: {
    active: function (value) {
      this.comments = value;
    },
  },
  methods: {
    confirm() {
      this.$emit("confirm:comments", this.commentsText);
    },
  },
};
</script>

<style scoped></style>
