<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" />
    <ContentArea>
      <FarmList :farms="farmList" :loading="loading" />
    </ContentArea>
  </div>
</template>

<script>
import { getUsers } from "@/services/user.js";
import Breadcrumbs from "@/components/general/Breadcrumbs.vue";
import ContentArea from "@/components/general/ContentArea.vue";
import FarmList from "@/components/registered/FarmList.vue";

export default {
  name: "RegisterFarm",
  components: {
    Breadcrumbs,
    ContentArea,
    FarmList,
  },
  created() {
    this.getAllUsers();
  },
  data: () => ({
    loading: true,
    breadcrumbs: [
      {
        text: "Área do administrador",
      },
      {
        text: "Fazendas Cadastradas",
      },
    ],
    farmList: [],
  }),
  methods: {
    getAllUsers() {
      this.loading = true;
      getUsers()
        .then((res) => {
          this.farmList = res.data.filter((user) => user.userTypeId === 4);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style></style>
