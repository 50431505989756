<template>
  <div>
    <v-checkbox
      class="text-sm-h6 mt-6 ml-6 mb-n8"
      v-for="item in this.checkbox_getItems"
      :key="item.id"
      :label="item.name"
      :value="item"
      :disabled="item.disabled"
      v-model="$store.state.checkboxListState.alreadySelected"
      @change="handleInput"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CheckBoxList",
  computed: {
    ...mapGetters(["checkbox_getItems", "modalityData_getItem"]),
  },
  methods: {
    ...mapActions(["modalityData_addItems", "tempModalityData_resetItems"]),
    handleInput(value) {
      const filtered = value.map((item) => {
        const fcu = this.modalityData_getItem.filter((v) => {
          return v.farm.id === item.id;
        });
        if (fcu.length > 0) {
          return fcu[0];
        }
        return { farm: { ...item }, consumerUnits: [] };
      });

      this.modalityData_addItems(filtered);
      this.tempModalityData_resetItems(filtered);
    },
  },
};
</script>

<style scoped></style>
