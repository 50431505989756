import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#007D4B",
        error: "#A6290E",
        success: "#0D961B",
        lightgray: "#8A8A8A",
        yellow: "#FFA906",
        gray: "#6D6D6D",
        darkgray: "#F9F9F9",
        dark: "#666666",
      },
    },
  },
});
