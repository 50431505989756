<template>
  <v-form v-model="validAddressForm">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <IconHome />
        <span
          class="ml-2 body-1 font-weight-bold primary--text"
          v-if="consumerUnit"
        >
          Endereço da unidade consumidora
        </span>
        <span class="ml-2 body-1 font-weight-bold primary--text" v-else>
          {{
            defaultAddress
              ? "Endereço residencial"
              : "Endereço de correspondência"
          }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="CEP *"
          outlined
          @change="getAddress()"
          v-mask="'#####-###'"
          v-model="addressData.zipCode"
          :rules="[rules.required, rules.cep]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Estado *"
          outlined
          disabled
          v-model="addressData.state"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Cidade *"
          outlined
          disabled
          v-model="addressData.city"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Logradouro *"
          outlined
          v-model="addressData.publicPlace"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Bairro *"
          outlined
          v-model="addressData.district"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Complemento "
          outlined
          v-model="addressData.complement"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Número *"
          outlined
          v-model="addressData.number"
          :disabled="addressData.dontHaveNumber"
          :rules="[rules.required]"
        ></v-text-field>
        <v-checkbox
          class="mt-0"
          v-model="addressData.dontHaveNumber"
          label="Sem número"
          @change="
            addressData.dontHaveNumber == true
              ? (addressData.number = 'Sem número')
              : (addressData.number = '')
          "
        ></v-checkbox>
      </v-col>
      <v-col cols="12" class="d-flex justify-end" v-if="consumerUnit">
        <router-link
          :to="`/adicionar-instalacao?userId=${this.$route.params.id}`"
        >
          <v-btn color="primary">Nova Instalação</v-btn>
        </router-link>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import rules from "@/plugins/rules";
import IconHome from "@/assets/icons/IconHome.vue";
export default {
  components: {
    IconHome,
  },
  props: {
    addressData: {
      type: Object,
      required: true,
    },
    defaultAddress: {
      type: Boolean,
      required: false,
      default: true,
    },
    consumerUnit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    rules: rules,
    validAddressForm: false,
  }),
  methods: {
    getAddress() {
      if (this.addressData.zipCode.length === 9) {
        const cep = this.addressData.zipCode.replace("-", "");
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
          .then((response) => response.json())
          .then((data) => {
            this.addressData.city = data.localidade;
            this.addressData.state = data.uf;
            this.addressData.district = data.bairro;
            this.addressData.publicPlace = data.logradouro;
          });
      }
    },
    saveChanges() {
      let params = {
        zipCode: this.addressData.zipCode,
        publicPlace: this.addressData.publicPlace,
        number: this.addressData.number,
        complement: this.addressData.complement,
        district: this.addressData.district,
        city: this.addressData.city,
        state: this.addressData.state,
      };

      if (this.consumerUnit) {
        this.$emit("updateConsumerUnit", params);
      } else {
        this.$emit("updateUser", params);
      }
    },
  },
};
</script>

<style></style>
