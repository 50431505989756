<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" />
    <v-row>
      <v-col cols="12">
        <InstallationList :installations="installations" :userData="userData" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import jwt from "jwt-decode";
import { getConsumerUnitById } from "@/services/user.js";
import Breadcrumbs from "@/components/general/Breadcrumbs.vue";
import InstallationList from "@/components/installations/InstallationList.vue";
export default {
  name: "Installations",
  components: {
    Breadcrumbs,
    InstallationList,
  },
  created() {
    this.getInstallations();
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Área do cliente",
      },
      {
        text: "Instalações",
      },
    ],
    installations: [],
    jwt: jwt,
    userData: {},
  }),
  methods: {
    getInstallations() {
      this.userData = jwt(localStorage.access_token);

      getConsumerUnitById(this.userData.sub).then((res) => {
        this.installations = res.data;
      });
    },
  },
};
</script>
