<template>
  <v-form v-model="validConsumerUnitForm">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <IconHome />
        <span
          class="ml-2 body-1 font-weight-bold gray--text"
          v-if="consumerUnit"
        >
          Insira os dados de endereço da unidade consumidora
        </span>
        <div v-else>
          <span
            class="ml-2 body-1 font-weight-bold gray--text"
            v-if="defaultAddress"
          >
            Insira os dados de endereço residencial do novo usuário
          </span>
          <span class="ml-2 body-1 font-weight-bold gray--text" v-else>
            Insira os dados de endereço para correspondência
          </span>
        </div>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="CEP *"
          outlined
          @change="getAddress()"
          :error-messages="
            cepError
              ? 'CEP não encontrado. Insira uma CEP válido para continuar'
              : ''
          "
          :hint="hint"
          persistent-hint
          v-mask="'#####-###'"
          v-model="unit.zipCode"
          :rules="[rules.required, rules.cep]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Estado *"
          outlined
          disabled
          v-model="unit.state"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Cidade *"
          outlined
          disabled
          v-model="unit.city"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Logradouro *"
          outlined
          v-model="unit.publicPlace"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Bairro *"
          outlined
          v-model="unit.district"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="2">
        <v-text-field
          label="Número *"
          outlined
          v-model="unit.number"
          :disabled="unit.dontHaveNumber"
          :rules="[rules.required]"
        ></v-text-field>
        <v-checkbox
          class="mt-0"
          v-model="unit.dontHaveNumber"
          label="Sem número"
          @change="
            unit.dontHaveNumber == true
              ? (unit.number = 'Sem número')
              : (unit.number = '')
          "
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="6" lg="2">
        <v-text-field
          label="Complemento "
          outlined
          v-model="unit.complement"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Número da Instalação *"
          outlined
          v-model="unit.installationNumber"
          :rules="[rules.required, rules.numeric]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-file-input
          v-model="unit.file"
          label="Faturas *"
          :disabled="unit.dontHaveBills"
          outlined
          :error-messages="fileError"
          @change="validFile()"
          accept="image/jpeg,image/png,application/pdf"
        ></v-file-input>
        <v-checkbox
          class="mt-0"
          v-model="unit.dontHaveBills"
          @change="setFileNull()"
          label="Não possuo faturas de energia do endereço de instalação."
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="6" lg="2">
        <v-text-field
          v-if="isAdmin()"
          label="Desconto de Crédito"
          outlined
          v-mask="'##,#%'"
          v-model="unit.creditDiscount"
          :rules="[rules.creditDiscount]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="2">
        <v-text-field
          v-if="isAdmin()"
          label="Média de Consumo"
          outlined
          v-model="unit.consumptionAverage"
          :rules="[rules.numericOrEmpty]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <router-link :to="`/editar-usuario/${this.$route.query.userId}`">
          <v-btn v-if="consumerUnit" color="primary" outlined> Cancelar </v-btn>
        </router-link>
        <v-btn
          v-if="consumerUnit"
          color="primary"
          @click="
            $emit('incrementTab');
            $emit('setConsumerUnit', unit);
          "
          :disabled="!validConsumerUnitForm"
        >
          Continuar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import IconHome from "@/assets/icons/IconHome.vue";
import jwt from "jwt-decode";
export default {
  props: {
    rules: {
      type: Object,
      required: true,
    },
    consumerUnit: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultAddress: {
      type: Boolean,
      required: false,
      default: true,
    },
    backButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    customFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    IconHome,
  },
  data: () => ({
    validConsumerUnitForm: false,
    cityList: [],
    stateList: [],
    cepError: false,
    fileError: "",
    hint: "Insira seu CEP para que os campos sejam preenchidos",
    unit: {
      zipCode: "",
      state: "",
      city: "",
      publicPlace: "",
      district: "",
      dontHaveNumber: false,
      number: "",
      complement: "",
      installationNumber: "",
      file: null,
      dontHaveBills: false,
      creditDiscount: "",
      consumptionAverage: "",
    },
    jwt: jwt,
  }),
  methods: {
    getAddress() {
      if (this.unit.zipCode.length === 9) {
        const zipCode = this.unit.zipCode.replace("-", "");
        fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
          .then((response) => response.json())
          .then((data) => {
            if (data.erro) {
              this.cepError = true;
              this.hint =
                "CEP não encontrado. Insira uma CEP válido para continuar.";
              this.unit.city = "";
              this.unit.state = "";
              this.unit.district = "";
              this.unit.publicPlace = "";
            } else {
              this.cepError = false;
              this.hint = "";
              this.unit.city = data.localidade;
              this.unit.state = data.uf;
              this.unit.district = data.bairro;
              this.unit.publicPlace = data.logradouro;
            }
          });
      }
    },
    setFileNull() {
      this.unit.file = null;
      this.fileError = "";
    },
    validFile() {
      const acceptTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];

      if (this.unit.file) {
        let file = this.unit.file;
        if (acceptTypes.indexOf(this.unit.file.type) === -1) {
          this.unit.file = null;
          this.fileError =
            "A foto deve estar em um desses formatos: JPG, JPEG, PNG ou PDF";
        } else {
          this.fileError = "";
          this.unit.file = new File([file], `conta1`, { type: file.type });
        }
      }
    },
    isAdmin() {
      return [1, 2].includes(this.jwt(localStorage.access_token).role);
    },
  },
};
</script>
