<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" />
    <ContentArea>
      <v-row class="mt-3">
        <v-col cols="12">
          <InvoiceList :invoices="invoices" />
        </v-col>
      </v-row>
    </ContentArea>
  </div>
</template>

<script>
import jwt from "jwt-decode";
import {
  getAllInvoicesByUserId,
  getUserNotifications,
} from "@/services/user.js";
import Breadcrumbs from "@/components/general/Breadcrumbs.vue";
import ContentArea from "@/components/general/ContentArea.vue";
import InvoiceList from "@/components/invoices/InvoiceList.vue";
export default {
  name: "Installations",
  components: {
    Breadcrumbs,
    ContentArea,
    InvoiceList,
  },
  beforeMount() {
    this.getNotification();
  },
  created() {
    this.setInitialData();
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Área do cliente",
      },
      {
        text: "Faturas",
      },
    ],
    invoice: {},
    invoices: [],
    jwt: jwt,
    userData: {},
  }),
  methods: {
    setInitialData() {
      this.userData = jwt(localStorage.access_token);
      this.invoice = this.invoices;

      getAllInvoicesByUserId(this.userData.sub).then((res) => {
        const invoicesData = res.data;
        this.invoices = invoicesData.map((invoice) => {
          return {
            id: invoice.id,
            installationNumber: invoice.ConsumerUnit.installationNumber,
            dueDate: invoice.dueDate,
            value: invoice.value,
            kWh: invoice.consumptionAvailable,
            status: invoice.status,
            userId: this.userData.sub,
            consumerUnitId: invoice.ConsumerUnit.id,
          };
        });
      });
    },
    getNotification() {
      getUserNotifications(this.userId).then((res) => {
        this.notifications = res.data;
        this.notifications.map((notifcation) => {
          if (!notifcation.read) this.unreadNotifications++;
        });
      });
    },
  },
};
</script>
