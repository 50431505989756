import api from "../plugins/axios";

// get
export async function getContactSubjects() {
  const getContactSubjects = await api.get("/contact/subjects");
  return getContactSubjects;
}

//post
export async function sendMessage(contactData) {
  const sendMessage = await api.post("/contact", contactData);
  return sendMessage;
}
