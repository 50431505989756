<template>
  <v-form v-model="validContactForm">
    <v-select
      v-model="contactSubjectSelected"
      :items="contactSubjects"
      item-vaLue="id"
      item-text="name"
      required
      label="Selecione o motivo de seu contato"
      :rules="[
        (contactSubjectSelected ? true : false) ||
          'Selecione uma opção para continuar.',
      ]"
      solo
    ></v-select>
    <v-textarea
      class="body-2"
      v-model="body"
      solo
      no-resize
      label="Mensagem"
      height="48vh"
      :rules="[rules.required]"
    ></v-textarea>
    <div class="d-flex justify-end">
      <v-btn :disabled="!validContactForm" color="primary" @click="sendMessage"
        >Enviar</v-btn
      >
    </div>
    <DialogFeedback
      :completed="completed"
      :success="success"
      :message="message"
      @closeFeedback="closeFeedback()"
    />
  </v-form>
</template>

<script>
import rules from "@/plugins/rules";
import { getContactSubjects, sendMessage } from "@/services/contact.js";
import DialogFeedback from "@/components/general/DialogFeedback.vue";
export default {
  components: {
    DialogFeedback,
  },
  created() {
    this.getContactSubjects();
  },
  data: () => ({
    completed: false,
    success: null,
    message: "",
    contactSubjectSelected: "",
    contactSubjects: [],
    body: "",
    rules: rules,
    validContactForm: false,
  }),
  methods: {
    getContactSubjects() {
      getContactSubjects().then((res) => {
        this.contactSubjects = res.data;
      });
    },
    sendMessage() {
      const params = {
        subject: this.contactSubjectSelected,
        body: this.body,
      };
      sendMessage(params)
        .then(() => {
          this.success = true;
          this.message = `Aguarde que em breve um de nossos atendentes entrará em contato pelo e-mail ou telefone cadastrados.`;
        })
        .catch(() => {
          this.success = false;
          this.message = "Erro ao enviar contato, tente novamente";
        })
        .finally(() => {
          this.completed = true;
        });
    },
    closeFeedback() {
      this.completed = false;
    },
  },
};
</script>

<style></style>
