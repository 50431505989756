<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" />
    <ContentArea>
      <EditProfile :profileData="profileData" :isMyProfile="true" />
    </ContentArea>
  </div>
</template>

<script>
import jwt from "jwt-decode";
import { getUserById } from "@/services/user.js";
import Breadcrumbs from "@/components/general/Breadcrumbs.vue";
import EditProfile from "@/components/profile/EditProfile.vue";
import ContentArea from "@/components/general/ContentArea.vue";

export default {
  name: "Profile",
  components: {
    Breadcrumbs,
    EditProfile,
    ContentArea,
  },
  created() {
    this.getProfileData();
  },
  data: () => ({
    jwt: jwt,
    breadcrumbs: [
      {
        text: "Meu perfil",
      },
      {
        text: "Editar Dados",
      },
    ],
    profileData: {},
  }),
  methods: {
    getProfileData() {
      const userId = jwt(localStorage.access_token).sub;
      getUserById(userId).then((res) => {
        this.profileData = res.data;
      });
    },
  },
};
</script>
