<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" md="5" lg="4">
        <DashboardCard
          color="#FFA906"
          title="Total de faturas geradas no mês"
          :content="generatedInvoices.toFixed(2)"
        />
      </v-col>
      <v-col cols="12" sm="6" md="5" lg="4">
        <DashboardCard
          color="#007D4B"
          title="Total de faturas pagas no mês"
          :content="paidInvoices.toFixed(2)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardCard from "@/components/dashboard/DashboardCard.vue";
import {
  getAllGeneratedInvoices,
  getGeneratedInvoiceByStatus,
} from "@/services/user.js";
export default {
  components: {
    DashboardCard,
  },
  props: {
    userId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data: () => ({
    generatedInvoices: 0,
    paidInvoices: 0,
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    status: "PAGA",
  }),
  created() {
    this.getAllGeneratedInvoices();
  },
  methods: {
    async getAllGeneratedInvoices() {
      const allGeneratedInvoices = await getAllGeneratedInvoices(
        this.month,
        this.year
      );
      allGeneratedInvoices.data.forEach((invoice) => {
        return (this.generatedInvoices += Number(invoice.value));
      });
      const allPaidInvoices = await getGeneratedInvoiceByStatus(
        this.month,
        this.year,
        this.status
      );
      allPaidInvoices.data.forEach((invoice) => {
        return (this.paidInvoices += Number(invoice.value));
      });
    },
  },
};
</script>

<style></style>
