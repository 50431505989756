<template>
  <v-btn
    :style="{ opacity: disabled ? 0.3 : 1 }"
    color="primary"
    x-large
    block
    v-on:click="$emit('click')"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "CustomButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
