<template>
  <div id="auth" class="d-flex">
    <Background />
    <Content />
  </div>
</template>

<script>
import Background from "@/components/general/Background.vue";
import Content from "@/components/auth/Content.vue";

export default {
  components: {
    Background,
    Content,
  },
};
</script>

<style lang="scss" scoped>
#auth {
  height: 100vh;
  width: 100%;
}
</style>
