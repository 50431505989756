<template>
  <div>
    <StatusCommentDialog
      :active="comments"
      @confirm:comments="callActionWithComments"
    />
    <v-btn
      v-if="isForInactivation"
      :color="color"
      class="white--text"
      @click.stop="comments = true"
    >
      {{ text }}
    </v-btn>
    <v-btn v-else :color="color" class="white--text" @click="callAction">
      {{ text }}
    </v-btn>
  </div>
</template>

<script>
import StatusCommentDialog from "@/components/profile/rows/StatusCommentDialog.vue";

export default {
  name: "StatusActionButton",
  components: { StatusCommentDialog },
  props: {
    text: {
      type: String,
      required: true,
    },
    action: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: true,
      default: "success",
    },
    status: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      comments: false,
    };
  },
  computed: {
    isForInactivation() {
      return (
        this.action.name.includes("reprove") && [2].includes(this.status.id)
      );
    },
  },
  methods: {
    callAction() {
      this.$emit("click:buttonAction", this.action, this.id);
    },
    callActionWithComments(comments) {
      this.comments = false;
      this.$emit("click:buttonAction", this.action, this.id, comments);
    },
  },
};
</script>

<style scoped></style>
