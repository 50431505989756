<template>
  <div>
    <v-navigation-drawer
      v-model="show"
      app
      persistent
      width="250"
      :mini-variant.sync="mini"
      class="darkgray"
      floating
    >
      <!-- <div class="elevation-4 white">
        <Logo class="ml-8 align-self-auto" />
      </div> -->
      <div class="elevation-4 pb-1 white">
        <v-btn icon @click.stop="mini = !mini" class="ma-3">
          <v-icon v-if="!mini">mdi-chevron-left</v-icon>
          <v-icon v-else>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
      <v-list nav shaped class="mt-6" v-bind:class="{ 'border-right': mini }">
        <span class="body-1 gray--text font-weight-bold mt-16 px-2" v-if="!mini"
          >Olá, {{ profileName }}
        </span>
        <v-list-item-group
          color="primary"
          class="mt-6"
          active-class="active-item"
          mandatory
        >
          <v-list-item
            v-for="(item, index) in currentMenu"
            :key="index"
            link
            :to="item.route"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <span v-html="item.title"></span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
// import Logo from "./LogoEcoGolden.vue";
export default {
  components: {
    // Logo,
  },
  props: {
    showSideNav: {
      type: Boolean,
      required: false,
      default: false,
    },
    profileName: {
      type: String,
      required: false,
      default: "Bem-vindo",
    },
    profileType: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  watch: {
    showSideNav(newValue) {
      this.show = newValue;
    },
    show(newValue) {
      if (!newValue) {
        this.$emit("showSideNav", false);
      }
    },
    profileType(value) {
      console.log();
      switch (value) {
        case 1:
          this.currentMenu = this.itemsAdmin;
          break;
        case 2:
          this.currentMenu = this.itemsAdmin;
          break;
        case 3:
          this.currentMenu = this.itemsClient;
          break;
        case 5:
          this.currentMenu = this.itemsSeller;
          break;
      }
    },
  },
  data: () => ({
    show: true,
    currentMenu: [],
    itemsAdmin: [
      {
        title: "Dashboard",
        icon: "mdi-chart-bar",
        route: "/home",
      },
      {
        title: "Cadastros",
        icon: "mdi-account-plus",
        route: "/cadastro",
      },
      {
        title: "Usuários",
        icon: "mdi-account-group",
        route: "/usuarios-cadastrados",
      },
      {
        title: "Fazendas",
        icon: "mdi-tractor",
        route: "/fazendas-cadastradas",
      },
      {
        title: "Tarifas",
        icon: "mdi-currency-usd",
        route: "/tarifas-cadastradas",
      },
      {
        title: "Consórcios<br>Cooperativas",
        icon: "mdi-briefcase",
        route: "/modalidades-cadastradas",
      },
    ],
    itemsClient: [
      { title: "Meu consumo", icon: "mdi-chart-bar", route: "/home" },
      { title: "Instalações", icon: "mdi-home", route: "/instalacoes" },
      { title: "Faturas", icon: "mdi-barcode", route: "/faturas" },
      { title: "Ajuda", icon: "mdi-help-circle", route: "/contato" },
    ],
    itemsSeller: [
      { title: "Minhas vendas", icon: "mdi-chart-bar", route: "/home" },
      { title: "Ajuda", icon: "mdi-help-circle", route: "/contato" },
    ],
    mini: false,
  }),
};
</script>

<style scoped lang="scss">
.border-right {
  border-right: 2px solid #b5b5b5;
  height: 80vh;
}
.active-item {
  background-color: #007d4b;
  color: white;
  font-weight: 500;
}
</style>
