<template>
  <div>
    <span class="subtitle-2 gray--text limit-name">{{ profileName }}</span>
    <v-menu left bottom :offset-y="true" transition="slide-x-transition">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list class="px-4">
        <v-list-item to="perfil">
          <v-list-item-title>Meu perfil</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout()">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    profileName: {
      type: String,
      required: false,
      default: "",
    },
  },
  methods: {
    logout() {
      window.location.href = "/auth";
      localStorage.removeItem("access_token");
    },
  },
};
</script>
