<template>
  <div id="bg-image"></div>
</template>

<script>
export default {
  name: "Background",
};
</script>

<style lang="scss" scoped>
#bg-image {
  width: 45%;
  height: 100%;

  background-image: url("./../../assets/background-auth.jpg");
  background-size: cover;
  background-position: center;
}

@media only screen and (max-width: 860px) {
  #bg-image {
    display: none;
  }
}

@media only screen and (min-width: 1920px) {
  #bg-image {
    width: 25%;
  }
}
</style>
