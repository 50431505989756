<template>
  <v-form v-model="validEditForm">
    <div class="text-center">
      <v-dialog v-model="dialog" max-width="800" persistent>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="d-flex align-start justify-start"
            v-bind="attrs"
            v-on="on"
            v-if="
              invoiceData.status === 'ATRASADA' ||
              invoiceData.status === 'PENDENTE'
            "
          >
            <v-btn color="primary">
              <span>Pagar</span>
            </v-btn>
          </div>
          <div
            class="d-flex align-start justify-start"
            v-bind="attrs"
            v-else
            style="cursor: auto"
          >
            <v-btn color="primary" disabled>
              <span>Pagar</span>
            </v-btn>
          </div>
        </template>
        <v-card class="text-center py-12 px-6" v-if="!completed">
          <div>
            <v-row class="mb-4">
              <v-col cols="12" lg="4">
                <InvoiceCard :invoice="invoiceData" />
              </v-col>
              <v-col cols="12" lg="8">
                <InvoicePaymentMethod :invoiceData="invoiceData" />
              </v-col>
            </v-row>
            <v-btn color="primary" @click="closeModal()" outlined
              >Cancelar</v-btn
            >
          </div>
        </v-card>
      </v-dialog>
    </div>
  </v-form>
</template>

<script>
import rules from "@/plugins/rules.js";
// import { updateFee } from "@/services/user.js";
import InvoiceCard from "@/components/invoices/InvoiceCard.vue";
import InvoicePaymentMethod from "@/components/invoices/InvoicePaymentMethod.vue";

export default {
  components: {
    InvoiceCard,
    InvoicePaymentMethod,
  },
  props: {
    invoiceData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    rules: rules,
    validEditForm: false,
    success: null,
    dialog: false,
    validFeeForm: false,
    completed: false,
  }),
  methods: {
    openModal() {
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
  },
  filters: {
    monthName(mes) {
      const nomesMeses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      return nomesMeses[mes - 1];
    },
  },
};
</script>
<style>
#custom-button {
  min-width: fit-content !important;
  max-width: 30% !important;
}
</style>
