<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <div class="d-flex align-center">
          <span class="body-1 gray--text font-weight-bold">
            Editar dados do Cliente
          </span>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="d-flex align-center justify-end mt-n4">
          <span class="body-1 primary--text font-weight-bold">
            Status do usuário:
          </span>
          <RowUserStatus :value="personalData.userStatus" />
        </div>
      </v-col>
    </v-row>
    <v-divider class="mt-3 mb-3"></v-divider>
    <DialogFeedback
      :completed="completed"
      :success="success"
      :message="message"
      @closeFeedback="closeFeedback()"
    />
    <RowPersonalData :isMyProfile="isMyProfile" :personalData="personalData" />
    <v-divider class="my-4"></v-divider>
    <RowContact
      :contactDetails="contactDetails"
      @updateUser="updateUser($event)"
    />
    <v-divider class="border-opacity-0 mt-4 mb-7"></v-divider>
    <RowConsumerUnitList
      :userId="this.profileData.id"
      :installations="installations"
      @updateInstallations="updateInstallations"
      class="mt-4 mb-4"
    />
    <v-divider class="mb-4 border-opacity-0"></v-divider>
    <RowAddress
      :addressData="addressData"
      :defaultAddress="false"
      @updateUser="updateUser($event)"
    />
    <v-divider class="my-4"></v-divider>
    <RowPassword v-if="isMyProfile" @updateUser="updateUser($event)" />
  </div>
</template>

<script>
import {
  updateUser,
  getConsumerUnitById,
  updateConsumerUnit,
  getConsumerUnitStatuses,
  getFarmConsumerUnitsByUserId,
} from "@/services/user.js";
import RowPersonalData from "@/components/profile/rows/RowPersonalData.vue";
import RowContact from "@/components/profile/rows/RowContact.vue";
import RowAddress from "@/components/profile/rows/RowAddress.vue";
import RowPassword from "@/components/profile/rows/RowPassword.vue";
import RowUserStatus from "./rows/RowUserStatus.vue";
import RowConsumerUnitList from "@/components/profile/rows/RowConsumerUnitList.vue";
import DialogFeedback from "@/components/general/DialogFeedback.vue";
import {
  approveStatus,
  chargeStatus,
  reproveStatus,
} from "@/services/consumer-unit";

export default {
  components: {
    RowPersonalData,
    RowContact,
    RowAddress,
    RowPassword,
    RowUserStatus,
    RowConsumerUnitList,
    DialogFeedback,
  },
  props: {
    isMyProfile: {
      type: Boolean,
      required: false,
      default: false,
    },
    profileData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.setInitialData();
    this.updateInstallations();
  },
  data: () => ({
    completed: false,
    success: null,
    message: "",
    installations: [],
    statusData: {},
    farmConsumerUnits: [],
    personalData: {
      fullName: "",
      physicalPerson: "",
      legalPerson: "",
      companyName: "",
      userStatus: "",
    },
    contactDetails: {
      primaryPhone: "",
      secondaryPhone: "",
      email: "",
    },
    addressData: {
      zipCode: "",
      publicPlace: "",
      number: "",
      complement: "",
      district: "",
      city: "",
      state: "",
    },
    consumerUnit: {
      id: "",
      zipCode: "",
      publicPlace: "",
      number: "",
      complement: "",
      district: "",
      city: "",
      state: "",
      statusId: "",
    },
    password: "",
  }),
  methods: {
    setInitialData() {
      this.personalData = {
        fullName: this.profileData.fullName,
        physicalPerson: this.profileData.physicalPerson,
        legalPerson: this.profileData.legalPerson,
        companyName: this.profileData.companyName,
        idUserType: this.profileData.idUserType,
        userTypeId: this.profileData.userTypeId,
        userStatus: this.profileData.status,
      };
      this.contactDetails = {
        primaryPhone: this.profileData.primaryPhone,
        secondaryPhone: this.profileData.secondaryPhone,
        email: this.profileData.email,
      };
      this.addressData = {
        zipCode: this.profileData.zipCode,
        publicPlace: this.profileData.publicPlace,
        number: this.profileData.number,
        complement: this.profileData.complement,
        district: this.profileData.district,
        city: this.profileData.city,
        state: this.profileData.state,
      };
    },
    async updateInstallations() {
      const consumerUnits = await getConsumerUnitById(this.profileData.id);
      const farmConsumerUnits = await getFarmConsumerUnitsByUserId(
        this.profileData.id
      );
      const allStatus = await getConsumerUnitStatuses();

      const installations = consumerUnits.data.map((consumerUnit) => {
        const farm = farmConsumerUnits.data.filter(
          (farmConsumerUnit) =>
            farmConsumerUnit.ConsumerUnitId === consumerUnit.id
        );
        const status = allStatus.data.filter(
          (status) => status.id === consumerUnit.statusId
        );

        return {
          ...consumerUnit,
          farm: farm.length > 0 ? { ...farm[0].User } : {},
          actions: this.getActions(consumerUnit),
          status: status[0],
        };
      });

      this.installations = installations;
    },
    updateUser(params) {
      updateUser(this.profileData.id, params)
        .then(() => {
          this.success = true;
          this.message = "Dados salvos com sucesso";
        })
        .catch(() => {
          this.success = false;
          this.message = "Erro ao editar os dados, tente novamente";
        })
        .finally(() => {
          this.completed = true;
        });
    },
    updateConsumerUnit(params) {
      updateConsumerUnit(this.consumerUnit.id, params)
        .then(() => {
          this.success = true;
          this.message = "Dados salvos com sucesso";
        })
        .catch(() => {
          this.success = false;
          this.message = "Erro ao editar os dados, tente novamente";
        })
        .finally(() => {
          this.completed = true;
        });
    },
    getActions(consumerUnit) {
      let actionSet;
      switch (consumerUnit.statusId) {
        case 1:
          actionSet = {
            approve: {
              text: "Analisar",
              fn: this.approve,
              color: "success",
            },
            reprove: null,
            charge: null,
          };
          break;
        case 2:
          actionSet = {
            approve: {
              text: "Aprovar",
              fn: this.approve,
              color: "success",
            },
            reprove: {
              text: "Reprovar",
              fn: this.reprove,
              color: "#C60047",
            },
            charge: null,
          };
          break;
        case 3:
          actionSet = {
            approve: null,
            reprove: {
              text: "Inativar",
              fn: this.reprove,
              color: "#C60047",
            },
            charge: null,
          };
          break;
        case 4:
          actionSet = {
            approve: null,
            reprove: null,
            charge: null,
          };
          break;
        case 5:
          actionSet = {
            approve: {
              text: "Aprovar",
              fn: this.approve,
              color: "success",
            },
            reprove: {
              text: "Reprovar",
              fn: this.reprove,
              color: "#C60047",
            },
            charge: null,
          };
          break;
        case 6:
          actionSet = {
            approve: null,
            reprove: {
              text: "Desativar",
              fn: this.reprove,
              color: "#C60047",
            },
            charge: {
              text: "Gerar Boleto",
              fn: this.charge,
              color: "success",
            },
          };
          break;
        case 7:
          actionSet = {
            approve: null,
            reprove: null,
            charge: {
              text: "Gerar Boleto",
              fn: this.charge,
              color: "success",
            },
          };
          break;
        case 8:
          actionSet = {
            approve: null,
            reprove: {
              text: "Inativar",
              fn: this.reprove,
              color: "#C60047",
            },
            charge: {
              text: "Gerar Boleto",
              fn: this.charge,
              color: "success",
            },
          };
          break;
        case 9:
          actionSet = {
            approve: null,
            reprove: null,
            charge: null,
          };
          break;
      }
      return actionSet;
    },
    async approve(id) {
      return (this.response = await approveStatus(id));
    },
    async reprove(id, comments = null) {
      return (this.response = await reproveStatus(id, comments));
    },
    async charge(id) {
      return (this.response = await chargeStatus(id));
    },
    closeFeedback() {
      this.completed = false;
    },
  },
};
</script>

<style>
.v-radio {
  transform: scale(0.9) !important;
}
</style>
