<template>
  <v-app>
    <v-main
      v-if="
        $route.name === 'Autenticação' ||
        $route.name === 'Redefinição de senha' ||
        $route.name === 'Cadastro de cliente'
      "
    >
      <router-view />
    </v-main>
    <div v-else>
      <Header
        @showSideNav="showSideNav($event)"
        @showNotification="showNotification(true)"
        :unreadNotifications="unread"
        :profileName="
          profileData.companyName
            ? profileData.companyName
            : profileData.fullName
        "
      />
      <SideNav
        :showSideNav="showMenu"
        @showSideNav="showSideNav($event)"
        :profileName="
          profileData.companyName
            ? profileData.companyName
            : profileData.fullName
        "
        :profileType="profileData.userTypeId"
      />
      <Notification
        :showNotification="show"
        @showNotification="showNotification(false)"
        @unreadNotifications="unreadNotifications($event)"
      />
      <v-main class="darkgray height-fluid">
        <v-container class="mt-6">
          <router-view />
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import jwt from "jwt-decode";
import { getUserById } from "@/services/user.js";
import Header from "./components/general/Header.vue";
import Notification from "./components/general/Notification.vue";
import SideNav from "./components/general/SideNav.vue";

export default {
  name: "App",
  beforeMount() {
    this.getProfileData();
  },
  components: {
    Header,
    Notification,
    SideNav,
  },
  data: () => ({
    show: false,
    showMenu: false,
    unread: 0,
    jwt: jwt,
    profileData: {},
  }),
  watch: {
    $route: {
      handler: (to) => {
        document.title = to.name || "Eco Golden";
      },
      immediate: true,
    },
  },
  methods: {
    getProfileData() {
      const userId = jwt(localStorage.access_token).sub;
      getUserById(userId)
        .then((res) => {
          this.profileData = res.data;
        })
        .catch(() => {
          localStorage.removeItem("access_token");
        });
    },
    showNotification(param) {
      this.show = param;
    },
    showSideNav(param) {
      this.showMenu = param;
    },
    unreadNotifications(param) {
      this.unread = param;
    },
  },
};
</script>
<style lang="scss" scoped>
.height-fluid {
  min-height: 100vh;
}
</style>
