<template>
  <div>
    <div class="text-center" v-if="success">
      <svg
        width="123"
        height="123"
        viewBox="0 0 123 123"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M38 64.4375L55.625 82.0625L85 46.8125"
          stroke="#0D961B"
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M61.5 120.25C93.9467 120.25 120.25 93.9467 120.25 61.5C120.25 29.0533 93.9467 2.75 61.5 2.75C29.0533 2.75 2.75 29.0533 2.75 61.5C2.75 93.9467 29.0533 120.25 61.5 120.25Z"
          stroke="#0D961B"
          stroke-width="5"
        />
      </svg>
      <p class="body-1 font-weight-bold success--text mt-2">
        {{ message }}
      </p>
      <p class="body-2 gray--text mt-1" v-if="secondaryMessage">
        {{ secondaryMessage }}
      </p>
      <v-btn
        color="primary"
        outlined
        class="mt-1"
        @click="goTo ? goTo() : goToHomePage()"
        v-if="action"
      >
        {{ goToMessage ? goToMessage : "Voltar para Home" }}
      </v-btn>
    </div>
    <div class="text-center" v-else>
      <p class="body-1 font-weight-bold error--text mt-2">
        {{ message }}
      </p>
      <v-btn
        color="primary"
        outlined
        class="mt-1"
        @click="goTo ? goTo() : goToHomePage()"
        v-if="action"
      >
        {{ goToMessage ? goToMessage : "Voltar para Home" }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "Feedback",
  props: {
    success: {
      type: Boolean,
      required: false,
      default: true,
    },
    message: {
      type: String,
      required: true,
    },
    secondaryMessage: {
      type: String,
      required: false,
      default: "",
    },
    action: {
      type: Boolean,
      required: false,
      default: true,
    },
    goTo: {
      type: Function,
      required: false,
    },
    goToMessage: {
      type: String,
      required: false,
      default: "Entendi",
    },
  },
  methods: {
    goToHomePage() {
      window.location.href = "/home";
    },
  },
};
</script>

<style></style>
