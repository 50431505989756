<template>
  <v-form id="login" v-model="valid">
    <v-container>
      <v-row>
        <v-col>
          <h4 class="text-h4 font-weight-bold primary--text text-center">
            Bem-vindo!
          </h4>
          <p class="lightgray--text body-1 mt-3 text-center">
            Insira seu usuário e senha para conectar-se a plataforma<br />
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            label="E-mail"
            v-model="email"
            type="text"
            :rules="[rules.required, rules.email]"
            prepend-inner-icon="mdi-account-circle-outline"
          ></v-text-field>
          <v-text-field
            outlined
            label="Senha"
            v-model="userPassword"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :rules="[rules.required, rules.minSize]"
            prepend-inner-icon="mdi-shield-key-outline"
            autocomplete
          ></v-text-field>
          <a
            href="#"
            @click="$emit('change')"
            class="gray--text body-1 text-decoration-underline"
          >
            Esqueci minha senha
          </a>
          <div
            class="caption mt-2 mb-1"
            :class="{ 'error--text': error, 'white--text': !error }"
          >
            Email ou senha inválidos
          </div>
          <CustomButton
            class="mt-4"
            color="primary"
            @click="sendUserCredentials()"
            :disabled="!valid"
          >
            Acessar
          </CustomButton>
          <router-link to="/cadastro-cliente" class="text-decoration-none">
            <v-btn class="mt-4" color="primary" outlined x-large block>
              Cadastre-se
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import rules from "@/plugins/rules";
import { signin } from "@/services/auth.js";
import CustomButton from "@/components/general/CustomButton.vue";

export default {
  components: {
    CustomButton,
  },
  name: "Login",
  data: () => ({
    rules: rules,
    email: "",
    valid: false,
    userPassword: "",
    showPassword: false,
    error: false,
  }),
  methods: {
    sendUserCredentials() {
      this.error = false;
      signin(this.email, this.userPassword)
        .then((res) => {
          localStorage.access_token = res.data.token;
          window.location.href = "/home";
        })
        .catch(() => {
          this.error = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#login {
  max-width: 476px;
  width: 100%;
}
</style>
