import Vue from "vue";
import VueRouter from "vue-router";

import Auth from "@/views/Auth.vue";
import Home from "@/views/Home.vue";
import Contact from "@/views/Contact.vue";
import Installations from "@/views/Installations.vue";
import AddInstallation from "@/views/AddInstallation.vue";
import EditUser from "@/views/EditUser.vue";
import Profile from "@/views/Profile.vue";
import Register from "@/views/Register.vue";
import CustomerRegistration from "@/views/CustomerRegistration.vue";
import RegisteredUsers from "@/views/RegisteredUsers.vue";
import RegisteredFarms from "@/views/RegisteredFarms.vue";
import RegisteredFee from "@/views/RegisteredFee.vue";
import RegisteredModalities from "@/views/RegisteredModalities.vue";
import Modalities from "@/components/modalidades/Modalities.vue";
import Invoices from "@/views/Invoices.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Página inicial" },
  },
  {
    path: "/auth",
    name: "Autenticação",
    component: Auth,
  },
  {
    path: "/auth/:token",
    name: "Redefinição de senha",
    component: Auth,
  },
  {
    path: "/home",
    name: "Página inicial",
    component: Home,
  },
  {
    path: "/contato",
    name: "Contato",
    component: Contact,
  },
  {
    path: "/instalacoes",
    name: "Instalações",
    component: Installations,
  },
  {
    path: "/adicionar-instalacao",
    name: "Adicionar Instalações",
    component: AddInstallation,
    props: (route) => ({ userId: Number(route.query.userId) }),
  },
  {
    path: "/perfil",
    name: "Meu perfil",
    component: Profile,
  },
  {
    path: "/editar-usuario/:id",
    name: "Editar dados",
    component: EditUser,
  },
  {
    path: "/cadastro",
    name: "Cadastro",
    component: Register,
  },
  {
    path: "/cadastro-cliente",
    name: "Cadastro de cliente",
    component: CustomerRegistration,
  },
  {
    path: "/usuarios-cadastrados",
    name: "Usuários Cadastrados",
    component: RegisteredUsers,
  },
  {
    path: "/fazendas-cadastradas",
    name: "Fazendas Cadastradas",
    component: RegisteredFarms,
  },
  {
    path: "/tarifas-cadastradas",
    name: "Tarifas Cadastradas",
    component: RegisteredFee,
  },
  {
    path: "/modalidades-cadastradas",
    name: "Consorcios e Coperativas",
    component: RegisteredModalities,
  },
  {
    path: "/modalidades/:modalidade",
    name: "Modalities",
    component: Modalities,
  },
  {
    path: "/modalidades/:modalidade/editar/:id",
    name: "Editar Modalidade",
    component: Modalities,
  },
  {
    path: "/faturas",
    name: "Faturas",
    component: Invoices,
  },
];

const router = new VueRouter({
  mode: "history",
  routes: routes,
});

function routeValidation(routeName) {
  let validRoute;
  const routesNoAuth = [
    "Autenticação",
    "Redefinição de senha",
    "Cadastro de cliente",
  ];

  const needAuth = routesNoAuth.indexOf(routeName) == -1;
  const isAuthenticated = localStorage.access_token ? true : false;

  if (!needAuth && !isAuthenticated) {
    validRoute = true;
  } else {
    validRoute = isAuthenticated;
  }

  return validRoute;
}

router.beforeEach((to, from, next) => {
  const auth = localStorage.access_token ? true : false;
  if (!routeValidation(to.name)) next({ name: "Autenticação" });
  else if (to.name == "Autenticação" && auth) next({ name: "Página inicial" });
  else next();
});

export default router;
