<template>
  <v-form v-model="validContactForm">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <IconPhone />
        <span class="ml-2 body-1 font-weight-bold gray--text">
          Insira os dados de contato do novo usuário
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Telefone principal *"
          outlined
          v-mask="phoneMaskPrim"
          v-model="contactData.primaryPhone"
          :rules="[rules.required, rules.phone]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Telefone secundário (opcional)"
          outlined
          v-mask="phoneMaskSecg"
          v-model="contactData.secondaryPhone"
          :rules="[rules.phone]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="E-mail *"
          outlined
          v-model="contactData.email"
          :rules="[rules.required, rules.email]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <v-btn outlined color="primary" @click="$emit('decrementTab')">
          Voltar
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!validContactForm"
          @click="
            $emit('incrementTab');
            $emit('setContactInfo', contactData);
          "
        >
          Continuar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import IconPhone from "@/assets/icons/IconPhone.vue";
export default {
  props: {
    rules: {
      type: Object,
      required: true,
    },
  },
  components: {
    IconPhone,
  },
  data: () => ({
    validContactForm: false,
    contactData: {
      primaryPhone: "",
      secondaryPhone: "",
      email: "",
    },
  }),
  computed: {
    phoneMaskPrim() {
      const numeric = this.contactData.primaryPhone.replace(/[^\d]+/g, "");
      return numeric.length >= 11 ? "(##) # ####-####" : "(##) ####-####";
    },
    phoneMaskSecg() {
      const numeric = this.contactData.secondaryPhone.replace(/[^\d]+/g, "");
      return numeric.length >= 11 ? "(##) # ####-####" : "(##) ####-####";
    },
  },
};
</script>

<style></style>
