<template>
  <div class="elevation-3 white pa-6 rounded-lg">
    <div v-if="installation.statusId === 9">
      <div>
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.875 26.1562V13.5625H5.8125V26.1562C5.8125 26.4132 5.91456 26.6596 6.09624 26.8413C6.27792 27.0229 6.52432 27.125 6.78125 27.125H24.2188C24.4757 27.125 24.7221 27.0229 24.9038 26.8413C25.0854 26.6596 25.1875 26.4132 25.1875 26.1562V13.5625H27.125V26.1562C27.125 26.927 26.8188 27.6663 26.2738 28.2113C25.7288 28.7563 24.9895 29.0625 24.2188 29.0625H6.78125C6.01046 29.0625 5.27125 28.7563 4.72622 28.2113C4.18119 27.6663 3.875 26.927 3.875 26.1562V26.1562ZM25.1875 4.84375V11.625L21.3125 7.75V4.84375C21.3125 4.58682 21.4146 4.34042 21.5962 4.15874C21.7779 3.97706 22.0243 3.875 22.2812 3.875H24.2188C24.4757 3.875 24.7221 3.97706 24.9038 4.15874C25.0854 4.34042 25.1875 4.58682 25.1875 4.84375Z"
            fill="#F44336"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.1299 2.90614C14.4933 2.54292 14.986 2.33887 15.4998 2.33887C16.0135 2.33887 16.5062 2.54292 16.8696 2.90614L29.7481 15.7828C29.93 15.9647 30.0322 16.2114 30.0322 16.4686C30.0322 16.7259 29.93 16.9726 29.7481 17.1545C29.5662 17.3364 29.3195 17.4386 29.0623 17.4386C28.805 17.4386 28.5583 17.3364 28.3764 17.1545L15.4998 4.27595L2.62313 17.1545C2.44123 17.3364 2.19451 17.4386 1.93726 17.4386C1.68001 17.4386 1.43329 17.3364 1.25138 17.1545C1.06948 16.9726 0.967285 16.7259 0.967285 16.4686C0.967285 16.2114 1.06948 15.9647 1.25138 15.7828L14.1299 2.90614Z"
            fill="#F44336"
          />
        </svg>
      </div>
      <h4 class="red--text mt-4">
        Instalação Nº: {{ installation.installationNumber }}
      </h4>
    </div>
    <div v-else>
      <div>
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.875 26.1562V13.5625H5.8125V26.1562C5.8125 26.4132 5.91456 26.6596 6.09624 26.8413C6.27792 27.0229 6.52432 27.125 6.78125 27.125H24.2188C24.4757 27.125 24.7221 27.0229 24.9038 26.8413C25.0854 26.6596 25.1875 26.4132 25.1875 26.1562V13.5625H27.125V26.1562C27.125 26.927 26.8188 27.6663 26.2738 28.2113C25.7288 28.7563 24.9895 29.0625 24.2188 29.0625H6.78125C6.01046 29.0625 5.27125 28.7563 4.72622 28.2113C4.18119 27.6663 3.875 26.927 3.875 26.1562V26.1562ZM25.1875 4.84375V11.625L21.3125 7.75V4.84375C21.3125 4.58682 21.4146 4.34042 21.5962 4.15874C21.7779 3.97706 22.0243 3.875 22.2812 3.875H24.2188C24.4757 3.875 24.7221 3.97706 24.9038 4.15874C25.0854 4.34042 25.1875 4.58682 25.1875 4.84375Z"
            fill="#007D4B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.1299 2.90614C14.4933 2.54292 14.986 2.33887 15.4998 2.33887C16.0135 2.33887 16.5062 2.54292 16.8696 2.90614L29.7481 15.7828C29.93 15.9647 30.0322 16.2114 30.0322 16.4686C30.0322 16.7259 29.93 16.9726 29.7481 17.1545C29.5662 17.3364 29.3195 17.4386 29.0623 17.4386C28.805 17.4386 28.5583 17.3364 28.3764 17.1545L15.4998 4.27595L2.62313 17.1545C2.44123 17.3364 2.19451 17.4386 1.93726 17.4386C1.68001 17.4386 1.43329 17.3364 1.25138 17.1545C1.06948 16.9726 0.967285 16.7259 0.967285 16.4686C0.967285 16.2114 1.06948 15.9647 1.25138 15.7828L14.1299 2.90614Z"
            fill="#007D4B"
          />
        </svg>
      </div>
      <h4 class="primary--text mt-4">
        Instalação Nº: {{ installation.installationNumber }}
      </h4>
    </div>
    <p class="body-1 gray--text mt-2">
      {{ installation.publicPlace }}, {{ installation.number }},
      {{ installation.district }} - {{ installation.city }},
      {{ installation.state }} - CEP: {{ installation.zipCode }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    installation: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style></style>
