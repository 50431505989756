<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <IconUserAvatar />
      <span class="ml-2 body-1 font-weight-bold primary--text">
        Dados pessoais
      </span>
    </v-col>
    <v-col cols="12" md="6" lg="4">
      <v-text-field
        label="Nome completo *"
        outlined
        disabled
        v-model="personalData.fullName"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6" lg="4">
      <v-text-field
        label="CPF *"
        outlined
        disabled
        v-mask="'###.###.###-##'"
        v-model="personalData.physicalPerson"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6" lg="4" v-if="personalData.legalPerson">
      <v-text-field
        label="CNPJ *"
        v-mask="'##.###.###/####-##'"
        outlined
        disabled
        v-model="personalData.legalPerson"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6" lg="4" v-if="personalData.companyName">
      <v-text-field
        label="Razão Social *"
        outlined
        disabled
        v-model="personalData.companyName"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
      v-if="
        (personalData.userTypeId === 1 || personalData.userTypeId === 2) &&
        !isMyProfile
      "
    >
      <v-select
        v-model="selected"
        :items="userTypeList"
        @change="personalData.userTypeId = selected.userTypeId"
        item-vaLue="userTypeId"
        item-text="description"
        label="Tipo de usuário"
        return-object
        outlined
      ></v-select>
    </v-col>
    <v-col
      cols="12"
      class="d-flex justify-end"
      v-if="
        (personalData.userTypeId === 1 || personalData.userTypeId === 2) &&
        !isMyProfile
      "
    >
      <v-btn color="primary" @click="saveChanges">Salvar Dados</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import IconUserAvatar from "@/assets/icons/IconUserAvatar.vue";
export default {
  components: {
    IconUserAvatar,
  },
  props: {
    personalData: {
      type: Object,
      required: true,
    },
    isMyProfile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created() {
    if (this.personalData.userTypeId === 1)
      this.selected = { userTypeId: 1, description: "Administrador Master" };
    if (this.personalData.userTypeId === 2)
      this.selected = { userTypeId: 2, description: "Administrador Geral" };
  },
  data: () => ({
    selected: {},
    userTypeList: [
      {
        userTypeId: 1,
        description: "Administrador Master",
      },
      {
        userTypeId: 2,
        description: "Administrador Geral",
      },
    ],
  }),
  methods: {
    saveChanges() {
      const params = { userTypeId: String(this.personalData.userTypeId) };
      this.$emit("updateUser", params);
    },
  },
};
</script>

<style></style>
