<template>
  <v-form v-model="validConsumption">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <IconEnergy />
        <span class="ml-2 body-1 font-weight-bold gray--text">
          Insira os valores de consumo mensal dos últimos 3 meses
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          outlined
          label="Mês 1 *"
          prefix="R$"
          type="number"
          v-model="consumption.month1"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          outlined
          label="Mês 2 *"
          prefix="R$"
          type="number"
          v-model="consumption.month2"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          outlined
          label="Mês 3 *"
          prefix="R$"
          type="number"
          v-model="consumption.month3"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <v-btn outlined color="primary" @click="$emit('decrementTab')">
          Voltar
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!validConsumption"
          @click="
            $emit('incrementTab');
            $emit('setConsumption', consumption);
          "
        >
          Continuar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import IconEnergy from "@/assets/icons/IconEnergy.vue";
export default {
  props: {
    rules: {
      type: Object,
      required: true,
    },
  },
  components: {
    IconEnergy,
  },
  data: () => ({
    validConsumption: false,
    consumption: {
      month1: "",
      month2: "",
      month3: "",
    },
  }),
};
</script>

<style></style>
