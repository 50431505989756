vue
<template>
  <v-form v-model="valid" id="redefine-password">
    <v-container>
      <v-row>
        <v-col>
          <h4 class="text-h4 font-weight-bold primary--text text-center">
            Redefinição de senha
          </h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            label="Nova senha"
            v-model="newPassword"
            :type="seeNewPassword ? 'text' : 'password'"
            :append-icon="seeNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="seeNewPassword = !seeNewPassword"
            :rules="[rules.required, rules.minSize]"
            prepend-inner-icon="mdi-shield-key-outline"
            autocomplete
          ></v-text-field>
          <v-text-field
            outlined
            label="Confirme sua nova senha"
            v-model="newPasswordConfirmation"
            :type="seeNewPasswordConfirmation ? 'text' : 'password'"
            :append-icon="
              seeNewPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'
            "
            @click:append="
              seeNewPasswordConfirmation = !seeNewPasswordConfirmation
            "
            :rules="[
              rules.required,
              rules.minSize,
              (newPassword == newPasswordConfirmation ? true : false) ||
                'As senhas precisam ser iguais.',
            ]"
            prepend-inner-icon="mdi-shield-key-outline"
            autocomplete
          ></v-text-field>
          <div
            class="caption mt-2 mb-1"
            :class="{ 'error--text': error, 'white--text': !error }"
          >
            Ocorreu um erro ao redefinir sua senha! Insira uma nova senha e
            tente novamente.
          </div>
          <v-btn
            color="primary"
            :disabled="!valid"
            x-large
            block
            @click="setUserPassword()"
          >
            Confirmar e voltar ao login
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import rules from "@/plugins/rules";
import { setPassword } from "@/services/auth.js";

export default {
  name: "Redefinepassword",
  data: () => ({
    rules: rules,
    valid: false,
    error: false,
    newPassword: "",
    newPasswordConfirmation: "",
    seeNewPassword: false,
    seeNewPasswordConfirmation: false,
    showPassword: false,
  }),
  methods: {
    setUserPassword() {
      this.error = false;
      setPassword(this.newPassword, this.$route.params.token)
        .then(() => {
          window.location.href = "/auth";
        })
        .catch(() => {
          this.error = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#login {
  max-width: 476px;
  width: 100%;
}
</style>
