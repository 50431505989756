var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.selection),function(node){return _c('v-row',{key:node.id},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card-style justify-space-between align-center d-flex"},[_c('v-col',{attrs:{"cols":"7"}},[_c('span',{staticClass:"align-center"},[_vm._v(_vm._s(_vm.isConsUnitList ? "Un. Consumidora - " + node.name : node.name))])]),_c('v-col',{attrs:{"cols":"2"}},[(_vm.isConsUnitList)?_c('span',[_vm._v(_vm._s(node.consumptionAverage + " kWh"))]):_c('span',{staticClass:"rounded-percentage white--text pa-1"},[_vm._v(_vm._s(_vm.allocatedPercentage(node) + "%"))])]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"3"}},[(
              _vm.isConsUnitList &&
              _vm.isEdition &&
              node.statusId === 6 &&
              !_vm.selectedToDesvinculate.includes(node.id)
            )?_c('v-btn',{staticClass:"white--text",attrs:{"color":"#C60047"},on:{"click":function($event){return _vm.handleDesvinculationButtonClick(node)}}},[_vm._v("Desvincular")]):(
              _vm.isConsUnitList &&
              _vm.isEdition &&
              (node.statusId === 7 ||
                _vm.selectedToDesvinculate.includes(node.id))
            )?_c('span',{staticClass:"font-weight-bold text-center red--text text--darken-3"},[_vm._v("Aguardando Envio")]):(_vm.isConsUnitList && _vm.isEdition && node.statusId === 8)?_c('a',{staticClass:"font-weight-bold text-center red--text text--darken-3",on:{"click":function($event){return _vm.handleDeactivationButtonClick(node)}}},[_vm._v("Desativação")]):_vm._e(),(_vm.vinculation)?_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.handleVinculationButtonClick(node)}}},[_vm._v("Vincular ")]):_vm._e()],1)],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }