<template>
  <v-alert border="left" colored-border :color="color" elevation="2">
    <div class="px-4">
      <v-row v-if="status">
        <v-col cols="6">
          <span
            class="body-2 text-left grey--text text-center"
            v-if="status === 'PENDENTE' || status === 'ATRASADA'"
          >
            <v-icon x-small color="yellow">mdi-circle</v-icon>
            {{ status }}</span
          >
          <span
            class="body-2 text-left grey--text text-center"
            v-if="status === 'PAGA'"
          >
            <v-icon x-small color="primary">mdi-circle</v-icon>
            {{ status }}</span
          >
          <br />
          <h3 class="lightgray--text">{{ title }}</h3>
          <h1 class="dark--text" v-if="content">R$ {{ content }}</h1>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="6">
          <v-col class="align-end text-center">
            <span class="font-weight-bold grey--text"
              >Vence em {{ this.invoiceData.dueDate }}</span
            >
          </v-col>
          <v-col>
            <div
              class="d-flex flex-column justify-xl-center align-center"
              :disable="true"
            >
              <InvoiceModal :invoiceData="invoiceData" />
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row v-if="!status">
        <v-col>
          <br />
          <h3 class="lightgray--text">{{ title }}</h3>
          <h1 class="dark--text" v-if="content">R$ {{ content }}</h1>
          <h1 class="dark--text" v-if="!content">R$ {{ 0 }}</h1>
        </v-col>
      </v-row>
    </div>
  </v-alert>
</template>

<script>
import InvoiceModal from "@/components/invoices/InvoiceModal.vue";

export default {
  props: {
    color: {
      type: String,
      required: false,
      default: "primary",
    },
    title: {
      type: String,
      required: false,
      default: "--",
    },
    content: {
      required: false,
      default: 0,
    },
    status: {
      required: false,
      default: 0,
    },
    invoiceData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  components: {
    InvoiceModal,
  },
};
</script>
