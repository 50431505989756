var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10"}}),_c('v-col',{attrs:{"cols":"12","md":"2"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.invoices,"sort-by":['status', 'dueDate'],"sort-desc":[true, false],"loading":_vm.loading,"loading-text":"Buscando tarifas..."},scopedSlots:_vm._u([{key:"item.installationNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.installationNumber)+" ")]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dueDate)+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("R$ " + item.value)+" ")]}},{key:"item.kWh",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.kWh + " kWh")+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'PENDENTE' || item.status === 'ATRASADA')?_c('span',{staticClass:"red--text font-weight-bold"},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e(),(item.status === 'PAGA')?_c('span',{staticClass:"primary--text font-weight-bold"},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e(),(item.status === 'CANCELADA' || item.status === 'PROCESSANDO')?_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-lg-start",attrs:{"disable":true}},[_c('InvoiceModal',{attrs:{"invoiceData":item}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }