import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    treeViewListState: {
      items: [],
      selectedByOthers: [],
      alreadySelected: [],
      toVinculate: {},
      filteredItems: [],
    },
    checkboxListState: {
      items: [],
      alreadySelected: [],
      filteredItems: [],
    },
    tempModalityDataState: [],
    modalityDataState: [],
  },
  mutations: {
    TREEVIEW__INIT_ITEMS: (state, items) => {
      state.treeViewListState.items = items;
    },
    TREEVIEW__INIT_SELECTED_BY_OTHERS: (state, items) => {
      state.treeViewListState.selectedByOthers = items;
    },
    TREEVIEW__UPDATE_ALREADY_SELECTED: (state, item) => {
      state.treeViewListState.alreadySelected = item;
    },
    TREEVIEW__UPDATE_TO_VINCULATE: (state, item) => {
      state.treeViewListState.toVinculate = item;
    },
    TREEVIEW__UPDATE_FILTERED_ITEMS: (state, item) => {
      state.treeViewListState.filteredItems = item;
    },
    CHECKBOX__INIT_ITEMS: (state, items) => {
      state.checkboxListState.items = items;
    },
    CHECKBOX__UPDATE_ALREADY_SELECTED: (state, item) => {
      state.checkboxListState.alreadySelected = item;
    },
    CHECKBOX__UPDATE_FILTERED_ITEMS: (state, item) => {
      state.checkboxListState.filteredItems = item;
    },
    TEMP_MODALITY_DATA__ADD_ITEM: (state, item) => {
      state.tempModalityDataState = item;
    },
    TEMP_MODALITY_DATA__RESET_ITEMS: (state, item) => {
      state.tempModalityDataState = item;
    },
    MODALITY_DATA__ADD_ITEM: (state, item) => {
      state.modalityDataState = item;
    },
  },
  actions: {
    treeView_initItems: (context, payload) => {
      context.commit("TREEVIEW__INIT_ITEMS", payload);
    },
    treeView_initSelectedByOthers: (context, payload) => {
      context.commit("TREEVIEW__INIT_SELECTED_BY_OTHERS", payload);
    },
    treeView_updateAlreadySelected: (context, payload) => {
      context.commit("TREEVIEW__UPDATE_ALREADY_SELECTED", payload);
    },
    treeView_updateToVinculate: (context, payload) => {
      context.commit("TREEVIEW__UPDATE_TO_VINCULATE", payload);
    },
    treeView_updateFilteredItems: (context, payload) => {
      if (payload.searchText === null || payload.searchText === "") {
        context.commit("TREEVIEW__UPDATE_FILTERED_ITEMS", []);
      } else {
        const filteredItems = context.state.treeViewListState.items.filter(
          (consUnits) => {
            return consUnits.name
              .toLowerCase()
              .includes(payload.searchText.toLowerCase());
          }
        );

        context.commit("TREEVIEW__UPDATE_FILTERED_ITEMS", filteredItems);
      }
    },
    checkbox_initItems: (context, payload) => {
      context.commit("CHECKBOX__INIT_ITEMS", payload);
    },
    checkbox_updateAlreadySelected: (context, payload) => {
      context.commit("CHECKBOX__UPDATE_ALREADY_SELECTED", payload);
    },
    checkbox_updateFilteredItems: (context, payload) => {
      if (payload.searchText === null || payload.searchText === "") {
        context.commit("CHECKBOX__UPDATE_FILTERED_ITEMS", []);
      } else {
        const filteredItems = context.state.checkboxListState.items.filter(
          (consUnits) => {
            return consUnits.name
              .toLowerCase()
              .includes(payload.searchText.toLowerCase());
          }
        );

        context.commit("CHECKBOX__UPDATE_FILTERED_ITEMS", filteredItems);
      }
    },
    tempModalityData_addItem: (context, payload) => {
      const toVinculate = context.getters.treeView_getToVinculate;
      const farmConsumerUnit = {
        farm: toVinculate,
        consumerUnits: payload,
      };

      const novo = context.getters.tempModalityData_getItem.filter(
        (item) => item.farm.id !== toVinculate.id
      );

      if (farmConsumerUnit.consumerUnits.length > 0) {
        novo.push(farmConsumerUnit);
      }

      context.commit("TEMP_MODALITY_DATA__ADD_ITEM", novo);
    },
    modalityData_addItems: (context, payload) => {
      context.commit("MODALITY_DATA__ADD_ITEM", payload);
    },
    tempModalityData_resetItems: (context, payload) => {
      context.commit("TEMP_MODALITY_DATA__ADD_ITEM", payload);
    },
    resetStore: (context) => {
      context.commit("TREEVIEW__INIT_ITEMS", []);
      context.commit("TREEVIEW__INIT_SELECTED_BY_OTHERS", []);
      context.commit("TREEVIEW__UPDATE_ALREADY_SELECTED", []);
      context.commit("TREEVIEW__UPDATE_TO_VINCULATE", {});
      context.commit("TREEVIEW__UPDATE_FILTERED_ITEMS", []);
      context.commit("CHECKBOX__INIT_ITEMS", []);
      context.commit("CHECKBOX__UPDATE_ALREADY_SELECTED", []);
      context.commit("CHECKBOX__UPDATE_FILTERED_ITEMS", []);
      context.commit("TEMP_MODALITY_DATA__ADD_ITEM", []);
      context.commit("MODALITY_DATA__ADD_ITEM", []);
    },
  },
  getters: {
    treeView_getItems: (state) => {
      let sourceList;

      if (state.treeViewListState.filteredItems.length > 0) {
        sourceList = state.treeViewListState.filteredItems;
      } else {
        sourceList = state.treeViewListState.items;
      }

      const result = sourceList
        .map((item) => {
          return {
            ...item,
            children: item.children.map((child) => {
              return {
                ...child,
                disabled:
                  state.treeViewListState.selectedByOthers.some(
                    (selected) => selected.id === child.id
                  ) || child.disabled === true,
              };
            }),
          };
        })
        .map((item) => {
          return {
            ...item,
            disabled: item.children.every((child) => child.disabled),
          };
        });

      return result;
    },
    treeView_getSelectedByOthers: (state) => {
      return state.treeViewListState.selectedByOthers;
    },
    treeView_getAlreadySelected: (state) => {
      return state.treeViewListState.alreadySelected;
    },
    treeView_getToVinculate: (state) => {
      return state.treeViewListState.toVinculate;
    },
    checkbox_getItems: (state) => {
      if (state.checkboxListState.filteredItems.length > 0) {
        return state.checkboxListState.filteredItems;
      }
      return state.checkboxListState.items;
    },
    checkbox_getAlreadySelected: (state) => {
      return state.checkboxListState.alreadySelected;
    },
    tempModalityData_getItem: (state) => {
      return state.tempModalityDataState;
    },
    modalityData_getItem: (state) => {
      return state.modalityDataState;
    },
  },
});

export default store;
