<template>
  <v-form v-model="validCommission">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <IconPercentage />
        <span class="ml-2 body-1 font-weight-bold gray--text"> Comissão </span>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          :disabled="isMyProfile"
          outlined
          label="Percentual de comissão *"
          prefix="%"
          type="number"
          v-model="commissionData.commissionPercentage"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="d-flex justify-end" v-if="!isMyProfile">
        <v-btn :disabled="!validCommission" color="primary" @click="saveChanges"
          >Salvar Comissão</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import rules from "@/plugins/rules";
import IconPercentage from "@/assets/icons/IconPercentage.vue";
export default {
  components: {
    IconPercentage,
  },
  props: {
    isMyProfile: {
      type: Boolean,
      required: false,
      default: false,
    },
    commissionData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    validCommission: false,
    rules: rules,
  }),
  methods: {
    saveChanges() {
      const params = {
        commissionPercentage: this.commissionData.commissionPercentage,
      };
      this.$emit("updateUser", params);
    },
  },
};
</script>
