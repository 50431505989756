<template>
  <div>
    <Breadcrumbs :items="this.breadcrumbs" />
    <ContentArea>
      <header class="d-flex align-center mb-4">
        <h2 v-if="this.modalityInfo.modalityTypeId == 1" class="gray--text">
          {{ isEdition ? "Editar" : "Criar" }} Consórcio
        </h2>
        <h2 v-if="this.modalityInfo.modalityTypeId == 2" class="gray--text">
          {{ isEdition ? "Editar" : "Criar" }} Cooperativa
        </h2>
      </header>
      <ModalityTabs
        :modalityTypeId="modalityTypeId"
        :modalityInfoProp="modalityInfo"
        :isEdition="isEdition"
        @cancelModalityCreation="handleCancelModalityCreation"
        @confirmModalityCreation="handleConfirmModalityCreation"
        @fetchModalityDataError="handleFetchModalityDataError"
      />
    </ContentArea>
    <DialogFeedback
      :completed="dialog.completed"
      :success="dialog.success"
      :message="dialog.message"
      @closeFeedback="closeFeedback()"
    />
  </div>
</template>

<script>
import Breadcrumbs from "@/components/general/Breadcrumbs.vue";
import ContentArea from "@/components/general/ContentArea.vue";
import DialogFeedback from "@/components/general/DialogFeedback.vue";
import ModalityTabs from "@/components/modalidades/ModalityTabs.vue";
import { createModality, updateModality } from "@/services/modality";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Modalities",
  components: { Breadcrumbs, ContentArea, DialogFeedback, ModalityTabs },
  props: {
    editionId: {
      type: Number,
      required: false,
    },
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Área do administrador",
      },
      {
        text: "Modalidades Cadastradas",
      },
      {
        text: "Cadastrar Modalidade",
      },
    ],
    modalityInfo: {
      id: null,
      name: null,
      document: null,
      responsible: null,
      contact: null,
      modalityType: null,
    },
    modalityTypeId: null,
    isEdition: false,
    dialog: {
      completed: false,
      success: false,
      message: "",
    },
  }),
  beforeRouteLeave(_to, _from, next) {
    this.resetStore();
    next();
  },
  async beforeMount() {
    this.modalityTypeId = this.$route.params.modalidade === "consorcio" ? 1 : 2;
    this.modalityInfo.modalityTypeId = this.modalityTypeId;

    if (this.$route.params.id) {
      this.isEdition = true;
      this.modalityInfo.id = this.$route.params.id;
    }
  },
  computed: {
    ...mapGetters(["modalityData_getItem"]),
  },
  methods: {
    ...mapActions(["resetStore"]),

    async sendModalityData() {
      let response;

      if (this.isEdition) {
        response = await updateModality(this.modalityInfo);
      } else {
        response = await createModality(this.modalityInfo);
      }

      const { message, isError } = response;

      if (isError) {
        this.dialog.success = false;
        this.dialog.message = message;
      } else {
        this.dialog.success = true;
        this.dialog.message = message;
      }
      this.dialog.completed = true;
    },
    handleCancelModalityCreation() {
      this.resetStore();
      this.$router.push({
        path: "/modalidades-cadastradas",
      });
    },
    async handleConfirmModalityCreation(data) {
      const { modalityInfo } = data;
      this.modalityInfo = {
        ...modalityInfo,
        modalityTypeId: this.modalityTypeId,
        farms: this.modalityData_getItem.map((item) => {
          return {
            id: item.farm.id,
            consumerUnits: item.consumerUnits.map((csu) => {
              return {
                id: csu.id,
                toDesvinculation: csu.toDesvinculation || false,
              };
            }),
          };
        }),
      };
      await this.sendModalityData();
    },
    handleFetchModalityDataError() {
      this.dialog.completed = true;
      this.dialog.success = false;
      this.dialog.message =
        "Erro ao buscar dados da modalidade. Tente novamente mais tarde!";
    },
    closeFeedback() {
      this.resetStore();
      this.dialog.completed = false;
      this.$router.push({
        path: "/modalidades-cadastradas",
      });
    },
  },
};
</script>

<style scoped></style>
