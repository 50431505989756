<template>
  <div v-if="!completed">
    <v-tabs v-model="tab" background-color="white">
      <v-tab key="cpfCnpj" :disabled="tab != 0"> CPF / CNPJ </v-tab>
      <v-tab key="userInfo" :disabled="tab != 1"> Informações pessoais </v-tab>
      <v-tab key="contact" :disabled="tab != 2"> Contato </v-tab>
      <v-tab key="consumerUnit" :disabled="tab != 3"> Und. consumidora </v-tab>
      <v-tab key="address" :disabled="tab != 4"> End. Correspondência </v-tab>
      <v-tab key="documents" :disabled="tab != 5"> Documentos </v-tab>
      <v-tab key="billingDay" :disabled="tab != 6"> Dia de pagamento </v-tab>
      <v-tab key="contract" :disabled="tab != 7"> Contrato </v-tab>
      <v-tab key="dataConfirmation" :disabled="tab != 8">
        Confirmação de dados
      </v-tab>
    </v-tabs>
    <v-divider class="mb-6"></v-divider>
    <v-tabs-items v-model="tab">
      <v-tab-item key="cpfCnpj">
        <TabItemKindOfPerson
          @incrementTab="incrementTab"
          @setKindOfPerson="setKindOfPerson($event)"
        />
      </v-tab-item>
      <v-tab-item key="userInfo">
        <TabItemUserInfo
          :rules="rules"
          :kindOfPerson="kindOfPerson"
          @incrementTab="incrementTab"
          @decrementTab="decrementTab"
          @setUserInfo="setUserInfo($event)"
        />
      </v-tab-item>
      <v-tab-item key="contact">
        <TabItemContact
          :rules="rules"
          @incrementTab="incrementTab"
          @decrementTab="decrementTab"
          @setContactInfo="setContactInfo($event)"
        />
      </v-tab-item>
      <v-tab-item key="consumerUnit">
        <TabItemConsumerUnit
          :rules="rules"
          @incrementTab="incrementTab"
          @decrementTab="decrementTab"
          @setConsumerUnit="setConsumerUnit($event)"
        />
      </v-tab-item>
      <v-tab-item key="address">
        <TabItemAddress
          :rules="rules"
          :defaultAddress="false"
          :customFooter="true"
          @incrementTab="incrementTab"
          @decrementTab="decrementTab"
          @setAddress="setAddress($event)"
        />
      </v-tab-item>
      <v-tab-item key="documents">
        <TabItemDocuments
          :rules="rules"
          @incrementTab="incrementTab"
          @decrementTab="decrementTab"
          @setDocuments="setDocuments($event)"
        />
      </v-tab-item>
      <v-tab-item key="billingDay">
        <TabItemBillingDay
          :rules="rules"
          @incrementTab="incrementTab"
          @decrementTab="decrementTab"
          @setBillingDay="setBillingDay($event)"
        />
      </v-tab-item>
      <v-tab-item key="contract">
        <TabItemContract
          :rules="rules"
          @incrementTab="incrementTab"
          @decrementTab="decrementTab"
        />
      </v-tab-item>
      <v-tab-item key="dataConfirmation">
        <v-row>
          <v-col cols="12" class="d-flex align-center">
            <IconAvatarList />
            <span class="ml-2 body-1 font-weight-bold gray--text">
              Por favor, confirme os dados inseridos
            </span>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation field="Nome completo" :contents="fullName" />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation field="CPF" :contents="physicalPerson" />
          </v-col>
          <!--          <v-col cols="12" md="6" lg="3">-->
          <!--            <DataConfirmation field="RG" :contents="idCard" />-->
          <!--          </v-col>-->
          <v-col cols="12" md="6" lg="3" v-if="kindOfPerson === 2">
            <DataConfirmation field="CNPJ" :contents="legalPerson" />
          </v-col>
          <v-col cols="12" md="6" lg="3" v-if="kindOfPerson == 2">
            <DataConfirmation field="Razão social" :contents="companyName" />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation
              field="Telefone principal"
              :contents="primaryPhone"
            />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation field="E-mail" :contents="email" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-space-between">
            <v-btn outlined color="primary" @click="tab = tab - 1">
              Voltar
            </v-btn>
            <v-btn color="primary" @click="createUser()">
              Confirmar cadastro
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
  <div v-else class="mt-6">
    <Feedback
      :success="success"
      :action="false"
      :message="message"
      secondaryMessage="Informamos que seu cadastro está atualmente em processo de análise por nossa equipe. Após a conclusão da análise, nossa equipe de vendas entrará em contato com você para discutir os próximos passos do processo. Pedimos que esteja atento(a) ao seu e-mail, pois é através dele que nos comunicaremos com você. Fique tranquilo(a), manteremos você informado(a) sobre quaisquer atualizações relevantes."
    />
    <v-row justify="center" class="mt-4">
      <v-btn
        color="primary"
        outlined
        x-large
        class="mt-1 justify-center align-center"
        @click="complete()"
      >
        Entendi
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import rules from "@/plugins/rules";
import { createClientNoAuth } from "@/services/user.js";
import DataConfirmation from "@/components/register/DataConfirmation.vue";
import TabItemKindOfPerson from "@/components/customer-registration/tabs/TabItemKindOfPerson.vue";
import TabItemUserInfo from "@/components/customer-registration/tabs/TabItemUserInfo.vue";
import TabItemContact from "@/components/customer-registration/tabs/TabItemContact.vue";
import TabItemConsumerUnit from "@/components/customer-registration/tabs/TabItemConsumerUnit.vue";
import TabItemAddress from "@/components/register/tabs/TabItemAddress.vue";
import TabItemDocuments from "@/components/customer-registration/tabs/TabItemDocuments.vue";
import TabItemBillingDay from "@/components/customer-registration/tabs/TabItemBillingDay.vue";
import TabItemContract from "@/components/customer-registration/tabs/TabItemContract.vue";

import IconAvatarList from "@/assets/icons/IconAvatarList.vue";
import Feedback from "@/components/general/Feedback.vue";

export default {
  name: "RegisterSeller",
  components: {
    DataConfirmation,
    TabItemKindOfPerson,
    TabItemUserInfo,
    TabItemContact,
    TabItemConsumerUnit,
    TabItemAddress,
    TabItemDocuments,
    TabItemBillingDay,
    TabItemContract,
    IconAvatarList,
    Feedback,
  },
  data: () => ({
    completed: null,
    success: null,
    tab: 0,
    rules: rules,
    kindOfPerson: null,
    fullName: "",
    idCard: "",
    physicalPerson: "",
    legalPerson: "",
    companyName: "",
    primaryPhone: "",
    secondaryPhone: "",
    email: "",
    validAddressForm: false,
    zipCode: "",
    state: "",
    city: "",
    publicPlace: "",
    district: "",
    dontHaveNumber: false,
    number: "",
    complement: "",
    consumerUnit: [],
    files: [],
    billingDay: "",
  }),
  methods: {
    setKindOfPerson(KindOfPerson) {
      this.kindOfPerson = KindOfPerson;
    },
    setUserInfo(userInfoData) {
      this.fullName = userInfoData.fullName;
      this.physicalPerson = userInfoData.physicalPerson;
      this.legalPerson = userInfoData.legalPerson;
      this.companyName = userInfoData.companyName;
      this.idCard = userInfoData.idCard;
    },
    setContactInfo(contactData) {
      this.primaryPhone = contactData.primaryPhone;
      this.secondaryPhone = contactData.secondaryPhone;
      this.email = contactData.email;
    },
    setAddress(addressData) {
      this.zipCode = addressData.zipCode;
      this.state = addressData.state;
      this.city = addressData.city;
      this.publicPlace = addressData.publicPlace;
      this.district = addressData.district;
      this.dontHaveNumber = addressData.dontHaveNumber;
      this.number = addressData.number;
      this.complement = addressData.complement;
    },
    setConsumerUnit(consumerUnitData) {
      consumerUnitData.map((unit) => {
        const obj = {
          zipCode: unit.zipCode,
          state: unit.state,
          city: unit.city,
          publicPlace: unit.publicPlace,
          district: unit.district,
          number: unit.number,
          complement: unit.complement,
          installationNumber: unit.installationNumber,
          status: unit.status,
        };

        if (unit.fileName) {
          this.files.push(unit.fileName);
          obj.fileName = unit.fileName.name;
        }

        this.consumerUnit.push(obj);
      });
    },
    setDocuments(documetsData) {
      this.files.push(documetsData.rg1);
      this.files.push(documetsData.rg2);
      this.files.push(documetsData.cpf);
    },
    setBillingDay(billingDay) {
      const date = billingDay.split("-");
      this.billingDay = date[2];
    },
    createUser() {
      const params = {
        userTypeId: 3,
        fullName: this.fullName,
        companyName: this.companyName,
        physicalPerson: this.physicalPerson,
        legalPerson: this.legalPerson,
        idCard: this.idCard,
        primaryPhone: this.primaryPhone,
        secondaryPhone: this.secondaryPhone,
        email: this.email,
        consumerUnit: this.consumerUnit,
        zipCode: this.zipCode,
        publicPlace: this.publicPlace,
        number: this.number,
        complement: this.complement,
        district: this.district,
        city: this.city,
        state: this.state,
        billingDay: this.billingDay,
        status: "INATIVO",
      };

      createClientNoAuth(params, this.files)
        .then(() => {
          this.success = true;
          this.message = "Parabéns! Seu cadastro foi realizado com sucesso!";
        })
        .catch((error) => {
          this.success = false;
          switch (error.response.status) {
            case 409:
              this.message =
                "Este e-mail já está cadastrado. Tente novamente com outro email!";
              break;
            default:
              this.message =
                "Houve um erro ao realizar seu cadastro, tente novamente mais tarde.";
              break;
          }
        })
        .finally(() => {
          this.completed = true;
        });
    },
    incrementTab() {
      this.tab += 1;
    },
    decrementTab() {
      this.tab -= 1;
    },
    complete() {
      window.location.href = "/auth";
    },
  },
};
</script>
