<template>
  <v-form v-model="validPasswordForm">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <IconKey />
        <span class="ml-2 body-1 font-weight-bold gray--text">
          Senha de acesso
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          outlined
          label="Senha *"
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          :rules="[rules.required, rules.minSize]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          outlined
          label="Confirme a senha *"
          v-model="passwordConfirmation"
          :type="showPasswordConfirmation ? 'text' : 'password'"
          :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPasswordConfirmation = !showPasswordConfirmation"
          :rules="[
            rules.required,
            rules.minSize,
            (password == passwordConfirmation ? true : false) ||
              'As senhas precisam ser iguais.',
          ]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          :disabled="!validPasswordForm"
          color="primary"
          @click="saveChanges"
          >Salvar Senha</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import rules from "@/plugins/rules";
import IconKey from "@/assets/icons/IconKey.vue";
export default {
  components: {
    IconKey,
  },
  data: () => ({
    validPasswordForm: false,
    rules: rules,
    showPasswordConfirmation: false,
    showPassword: false,
    password: "",
    passwordConfirmation: "",
  }),
  methods: {
    saveChanges() {
      const params = { password: this.password };
      this.$emit("updateUser", params);
    },
  },
};
</script>
