<template>
  <div v-if="!completed">
    <v-tabs v-model="tab" background-color="white">
      <v-tab key="cpfCnpj" :disabled="tab != 0"> CPF / CNPJ </v-tab>
      <v-tab key="farmData" :disabled="tab != 1"> Dados da fazenda </v-tab>
      <v-tab key="address" :disabled="tab != 2"> Endereço </v-tab>
      <v-tab key="contact" :disabled="tab != 3"> Contato </v-tab>
      <v-tab key="dataConfirmation" :disabled="tab != 4">
        Confirmação de dados
      </v-tab>
    </v-tabs>
    <v-divider class="mb-6"></v-divider>
    <v-tabs-items v-model="tab" class="pa-2">
      <v-tab-item key="cpfCnpj">
        <TabItemKindOfPerson
          @incrementTab="incrementTab"
          @setKindOfPerson="setKindOfPerson($event)"
        />
      </v-tab-item>
      <v-tab-item key="farmData">
        <TabItemFarmData
          :rules="rules"
          :kindOfPerson="kindOfPerson"
          @incrementTab="incrementTab"
          @decrementTab="decrementTab"
          @setFarmData="setFarmData($event)"
        />
      </v-tab-item>
      <v-tab-item key="address">
        <TabItemAddress
          :rules="rules"
          @incrementTab="incrementTab"
          @decrementTab="decrementTab"
          @setAddress="setAddress($event)"
        />
      </v-tab-item>
      <v-tab-item key="contact">
        <TabItemContact
          :rules="rules"
          @incrementTab="incrementTab"
          @decrementTab="decrementTab"
          @setContactInfo="setContactInfo($event)"
        />
      </v-tab-item>
      <v-tab-item key="dataConfirmation">
        <v-row>
          <v-col cols="12" class="d-flex align-center">
            <IconAvatarList />
            <span class="ml-2 body-1 font-weight-bold gray--text">
              Por favor, confirme os dados inseridos
            </span>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation field="Nome da fazenda" :contents="fullName" />
          </v-col>
          <v-col cols="12" md="6" lg="3" v-if="kindOfPerson === 1">
            <DataConfirmation field="CPF" :contents="physicalPerson" />
          </v-col>
          <v-col cols="12" md="6" lg="3" v-if="kindOfPerson === 2">
            <DataConfirmation field="CNPJ" :contents="legalPerson" />
          </v-col>
          <v-col cols="12" md="6" lg="3" v-if="kindOfPerson == 2">
            <DataConfirmation field="Razão social" :contents="companyName" />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation field="Potência nomial" :contents="KVA" />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation field="Potência de placa" :contents="KWP" />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation
              field="Capaciade de geração mensal"
              :contents="kWh"
            />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation field="CEP" :contents="zipCode" />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation field="Estado" :contents="state" />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation field="Cidade" :contents="city" />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation field="Bairro" :contents="district" />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation field="Logradouro" :contents="publicPlace" />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation
              field="Número"
              :contents="number ? number : '--'"
            />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation
              field="Complemento"
              :contents="complement ? complement : '--'"
            />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <DataConfirmation
              field="Telefone principal"
              :contents="primaryPhone"
            />
          </v-col>
          <v-col cols="12" md="12" lg="3">
            <DataConfirmation field="E-mail" :contents="email" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-space-between">
            <v-btn outlined color="primary" @click="tab = tab - 1">
              Voltar
            </v-btn>
            <v-btn color="primary" @click="createFarm()">
              Confirmar cadastro
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
  <div v-else class="mt-6">
    <Feedback
      :success="success"
      :message="
        success
          ? 'Usuário cadastrado com sucesso!'
          : 'Houve um erro ao cadastrar este usuário, tente novamente mais tarde.'
      "
    />
  </div>
</template>

<script>
import rules from "@/plugins/rules";
import { createFarm } from "@/services/user.js";
import DataConfirmation from "@/components/register/DataConfirmation.vue";
import TabItemKindOfPerson from "@/components/register/tabs/TabItemKindOfPerson.vue";
import TabItemFarmData from "@/components/register/tabs/TabItemFarmData.vue";
import TabItemContact from "@/components/register/tabs/TabItemContact.vue";
import TabItemAddress from "@/components/register/tabs/TabItemAddress.vue";

import IconAvatarList from "@/assets/icons/IconAvatarList.vue";
import Feedback from "@/components/general/Feedback.vue";

export default {
  name: "RegisterSeller",
  components: {
    DataConfirmation,
    TabItemKindOfPerson,
    TabItemContact,
    TabItemAddress,
    TabItemFarmData,
    IconAvatarList,
    Feedback,
  },
  data: () => ({
    completed: false,
    success: null,
    tab: 0,
    rules: rules,
    kindOfPerson: "",
    fullName: "",
    physicalPerson: "",
    legalPerson: "",
    companyName: "",
    KVA: "",
    KWP: "",
    kWh: "",
    primaryPhone: "",
    secondaryPhone: "",
    email: "",
    validAddressForm: false,
    zipCode: "",
    state: "",
    city: "",
    publicPlace: "",
    district: "",
    dontHaveNumber: false,
    number: "",
    complement: "",
  }),
  methods: {
    setKindOfPerson(KindOfPerson) {
      this.kindOfPerson = KindOfPerson;
    },
    setFarmData(farmData) {
      this.fullName = farmData.fullName;
      this.physicalPerson = farmData.physicalPerson;
      this.legalPerson = farmData.legalPerson;
      this.companyName = farmData.companyName;
      this.KVA = farmData.KVA;
      this.KWP = farmData.KWP;
      this.kWh = farmData.kWh;
    },
    setContactInfo(contactData) {
      this.primaryPhone = contactData.primaryPhone;
      this.secondaryPhone = contactData.secondaryPhone;
      this.email = contactData.email;
    },
    setAddress(addressData) {
      this.zipCode = addressData.zipCode;
      this.state = addressData.state;
      this.city = addressData.city;
      this.publicPlace = addressData.publicPlace;
      this.district = addressData.district;
      this.dontHaveNumber = addressData.dontHaveNumber;
      this.number = addressData.number;
      this.complement = addressData.complement;
    },
    createFarm() {
      const params = {
        fullName: this.fullName,
        companyName: 1,
        KVA: this.KVA,
        KWP: this.KWP,
        kWh: this.kWh,
        primaryPhone: this.primaryPhone,
        secondaryPhone: this.secondaryPhone,
        email: this.email,
        zipCode: this.zipCode,
        publicPlace: this.publicPlace,
        number: this.number,
        complement: this.complement,
        district: this.district,
        city: this.city,
        state: this.state,
        status: "INATIVO",
      };

      createFarm(params)
        .then(() => {
          this.success = true;
        })
        .catch(() => {
          this.success = false;
        })
        .finally(() => {
          this.completed = true;
        });
    },
    incrementTab() {
      this.tab += 1;
    },
    decrementTab() {
      this.tab -= 1;
    },
  },
};
</script>
