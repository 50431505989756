<template>
  <v-col cols="12">
    <v-row v-for="node in selection" :key="node.id">
      <v-col cols="12">
        <v-card class="card-style justify-space-between align-center d-flex">
          <v-col cols="7">
            <span class="align-center">{{
              isConsUnitList ? "Un. Consumidora - " + node.name : node.name
            }}</span>
          </v-col>
          <v-col cols="2">
            <span v-if="isConsUnitList">{{
              node.consumptionAverage + " kWh"
            }}</span>
            <span v-else class="rounded-percentage white--text pa-1">{{
              allocatedPercentage(node) + "%"
            }}</span>
          </v-col>
          <v-col cols="3" class="d-flex justify-center">
            <v-btn
              color="#C60047"
              class="white--text"
              v-if="
                isConsUnitList &&
                isEdition &&
                node.statusId === 6 &&
                !selectedToDesvinculate.includes(node.id)
              "
              @click="handleDesvinculationButtonClick(node)"
              >Desvincular</v-btn
            >
            <span
              class="font-weight-bold text-center red--text text--darken-3"
              v-else-if="
                isConsUnitList &&
                isEdition &&
                (node.statusId === 7 ||
                  selectedToDesvinculate.includes(node.id))
              "
              >Aguardando Envio</span
            >
            <a
              v-else-if="isConsUnitList && isEdition && node.statusId === 8"
              class="font-weight-bold text-center red--text text--darken-3"
              @click="handleDeactivationButtonClick(node)"
              >Desativação</a
            >
            <v-btn
              class="primary"
              v-if="vinculation"
              @click="handleVinculationButtonClick(node)"
              >Vincular
            </v-btn>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SelectedList",
  props: {
    selection: {
      type: Array,
      default: () => [],
    },
    vinculation: {
      type: Boolean,
      required: false,
      default: false,
    },
    isConsUnitList: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEdition: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedToDesvinculate: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  methods: {
    handleVinculationButtonClick(node) {
      this.$emit("clicked:vinculation", node);
    },
    handleDesvinculationButtonClick(node) {
      this.$emit("clicked:desvinculation", node);
    },
    handleDeactivationButtonClick(node) {
      this.$emit("clicked:deactivation", node);
    },
    allocatedPercentage(node) {
      const farmData = this.tempModalityData_getItem.find(
        (item) => item.farm.id === node.id
      ) || { consumerUnits: [{ consumptionAverage: 0 }], farm: { kWh: 1 } };

      const reduced = farmData.consumerUnits.reduce((acc, v) => {
        return v.consumptionAverage + acc;
      }, 0);

      const result = ((reduced / farmData.farm.kWh) * 100).toFixed(3);

      return result;
    },
  },
  computed: {
    ...mapGetters(["tempModalityData_getItem"]),
  },
};
</script>

<style scoped>
.card-style {
  border-radius: 8px;
  width: 50rem;
}
.rounded-percentage {
  background-color: #e91e63;
  border-radius: 10%;
}
</style>
