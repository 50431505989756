<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="6" class="d-flex justify-end">
        <v-col cols="12 d-flex justify-end">
          <span class="text-lg-h4 primary--text col-6 text-end align-center">{{
            modalityTotalAllocated + " kWh"
          }}</span
          ><span class="text-lg-h6 primary--text col-4 align-center ma-1">{{
            " de " + modalityTotalProduction + " kWh"
          }}</span>
        </v-col>
      </v-col>
      <v-divider vertical />
      <v-col cols="6" class="d-flex justify-start align-center">
        <span class="text-lg-h4 pink--text ml-4 font-weight-bold"
          >{{ this.modalityTotalAllocationPercentage.toFixed(3) }}%</span
        >
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Allocated",
  computed: {
    ...mapGetters(["tempModalityData_getItem"]),
    modalityTotalProduction() {
      return this.tempModalityData_getItem.reduce((acc, item) => {
        return acc + item.farm.kWh;
      }, 0);
    },
    modalityTotalAllocated() {
      return this.tempModalityData_getItem.reduce((acc, farm) => {
        return (
          acc +
          farm.consumerUnits.reduce((ac, consUnit) => {
            return ac + consUnit.consumptionAverage;
          }, 0)
        );
      }, 0);
    },
    modalityTotalAllocationPercentage() {
      return (
        (this.modalityTotalAllocated / this.modalityTotalProduction) * 100 || 0
      );
    },
  },
};
</script>

<style scoped></style>
