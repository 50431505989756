import api from "../plugins/axios";

function _handleCreatePayload(modalityData) {
  const farms = modalityData.farms.map((farm) => {
    return {
      id: farm.id,
      consumerUnits: farm.consumerUnits.map((consumerUnit) => {
        return {
          id: consumerUnit.id,
          toDesvinculation: consumerUnit.toDesvinculation || false,
        };
      }),
    };
  });

  const payload = {
    name: modalityData.name,
    document: modalityData.document,
    modalityType: modalityData.modalityTypeId,
    responsible: modalityData.responsible,
    contact: modalityData.contact,
    farms,
  };

  return payload;
}

function _handleUpdatePayload(modalityData) {
  const payload = _handleCreatePayload(modalityData);
  payload.id = modalityData.id;

  return payload;
}

function _handleCreateResponse(response) {
  if (response.status === 201) {
    return {
      message: response.data.message,
      isError: false,
    };
  }

  return {
    message: response.data.message,
    isError: true,
  };
}

function _handleUpdateResponse(response) {
  if (response.status === 200) {
    return {
      message: response.data.message || "Modalidade atualizada com sucesso!",
      isError: false,
    };
  }

  return {
    message: response.data.message,
    isError: true,
  };
}

function _handleGetResponse(response) {
  if (response.status === 200) {
    return {
      data: response.data,
      isError: false,
    };
  }

  return {
    message: response.data.message,
    isError: true,
  };
}

export async function createModality(modalityData) {
  const payload = _handleCreatePayload(modalityData);
  try {
    const response = await api.post("/modalities", payload);
    return _handleCreateResponse(response);
  } catch (error) {
    console.error(error);
    return _handleCreateResponse(error.response);
  }
}

export async function updateModality(modalityData) {
  const payload = _handleUpdatePayload(modalityData);
  try {
    const response = await api.put(`/modalities/${payload.id}`, payload);
    return _handleUpdateResponse(response);
  } catch (error) {
    console.error(error);
    return _handleUpdateResponse(error.response);
  }
}

export async function deleteModality(modalityId) {
  try {
    const response = await api.delete(`/modalities/${modalityId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
}

export async function getModalityById(modalityId) {
  try {
    const response = await api.get(`/modalities/${modalityId}`);
    return _handleGetResponse(response);
  } catch (error) {
    console.error(error);
    return _handleGetResponse(error.response);
  }
}
