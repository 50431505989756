<template>
  <v-col cols="12" id="modalityTabs">
    <v-row>
      <ModalityInfoForm
        :class="this.tabs == 0 ? '' : 'd-none'"
        :modalityInfoProp="this.modalityInfo"
        :rules="rules"
        @update:modalityInfo="handleModalityInfoChange"
      />
    </v-row>
    <v-row>
      <Allocated />
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="tabs" class="noneDisplay" background-color="white">
          <v-tab key="farms" :disabled="tabs != 0"> Fazendas </v-tab>
          <v-tab key="consumerUnits" :disabled="tabs != 1">
            Unidades Consumidoras
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs" class="pa-2">
          <v-tab-item key="farms">
            <v-row>
              <ParentTab @navigation:toItem="handleNavigateToVinculationTab" />
            </v-row>
            <v-row>
              <v-col cols="8" class="d-flex flex-wrap-reverse">
                <v-col cols="4">
                  <v-btn
                    color="primary"
                    block
                    outlined
                    large
                    @click="handleCancelModalityCreation"
                    >Cancelar</v-btn
                  >
                </v-col>
                <v-col cols="4">
                  <v-btn
                    color="primary"
                    block
                    large
                    :disabled="!this.validModalityData"
                    @click="handleConfirmModalityCreation"
                    >Confirmar</v-btn
                  >
                </v-col>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item key="consumerUnits">
            <VinculationTab
              :isEdition="isEdition"
              @clicked:confirmVinculation="handleConfirmVinculation"
              @clicked:cancelVinculation="handleCancelVinculation"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Allocated from "@/components/modalidades/Allocated.vue";
import ModalityInfoForm from "@/components/modalidades/ModalityInfoForm.vue";
import ParentTab from "@/components/modalidades/ParentTab.vue";
import VinculationTab from "@/components/modalidades/VinculationTab.vue";
import rules from "@/plugins/rules";
import { getModalityById } from "@/services/modality";
import { getConsumerUnitByModalityTypeId, getFarms } from "@/services/user";

export default {
  name: "ModalityTabs",
  components: { Allocated, ModalityInfoForm, ParentTab, VinculationTab },
  props: {
    modalityTypeId: {
      type: Number,
      required: true,
    },
    modalityInfoProp: {
      type: Object,
      required: false,
    },
    isEdition: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    modalityInfo: {
      id: null,
      name: null,
      document: null,
      responsible: null,
      contact: null,
      modalityTypeId: null,
    },
    tabs: 0,
    rules,
  }),
  async beforeMount() {
    let [farmList, consumerUnitList] = await Promise.all([
      this.fetchFarmList(this.modalityTypeId),
      this.fetchConsumerUnitList(this.modalityTypeId),
    ]);

    let farms = [];
    let consumerUnits = [];
    if (this.isEdition) {
      this.modalityInfo = this.modalityInfoProp;
      let { modalityFarms, modalityConsumerUnits } =
        await this.fetchModalityData(this.$route.params.id);

      farms = modalityFarms || [];
      consumerUnits = modalityConsumerUnits || [];
    }

    const treeViewModality = consumerUnits.map((consumerUnit) => {
      return {
        id: consumerUnit.userId,
        index: `U-${consumerUnit.userId}`,
        name: consumerUnit.userName,
        children: [
          {
            id: consumerUnit.id,
            index: `CU-${consumerUnit.id}`,
            name: consumerUnit.name,
            consumptionAverage: consumerUnit.consumptionAverage,
            statusId: consumerUnit.statusId,
            disabled: true,
          },
        ],
      };
    });

    this.checkbox_initItems(
      farmList.concat(
        farms.map((farm) => {
          return {
            ...farm.farm,
            disabled: true,
          };
        })
      )
    );

    this.treeView_initItems(
      consumerUnitList
        .concat(
          treeViewModality.map((consumerUnit) => {
            return {
              ...consumerUnit,
            };
          })
        )
        .reduce((acc, cur) => {
          const index = acc.findIndex((item) => item.id === cur.id);
          if (index === -1) {
            acc.push(cur);
          } else {
            acc[index].children.push(cur.children[0]);
          }
          return acc;
        }, [])
    );
    this.modalityInfo.modalityTypeId = this.modalityTypeId;

    if (this.isEdition) {
      const modalityData = farms.map((farm) => {
        return {
          farm: farm.farm,
          consumerUnits: consumerUnits
            .filter((consumerUnit) => consumerUnit.farmId === farm.farm.id)
            .map((consumerUnit) => {
              return {
                id: consumerUnit.id,
                index: `CU-${consumerUnit.id}`,
                name: consumerUnit.name,
                consumptionAverage: consumerUnit.consumptionAverage,
                statusId: consumerUnit.statusId,
                disabled: true,
              };
            }),
        };
      });
      this.modalityData_addItems(modalityData);
      this.tempModalityData_resetItems(modalityData);
      this.checkbox_updateAlreadySelected(farms.map((farm) => farm.farm));
    }
  },
  computed: {
    validModalityData() {
      return (
        this.modalityInfo.name &&
        this.modalityInfo.document &&
        this.modalityData_getItem.length > 0 &&
        this.modalityData_getItem.every((item) => {
          return item.consumerUnits.length > 0;
        }) &&
        this._modalityTotalAllocationPercentage(this.modalityData_getItem) <=
          100
      );
    },
    ...mapGetters([
      "treeView_getAlreadySelected",
      "treeView_getToVinculate",
      "tempModalityData_getItem",
      "checkbox_getAlreadySelected",
      "modalityData_getItem",
    ]),
  },
  methods: {
    ...mapActions([
      "treeView_initItems",
      "treeView_initSelectedByOthers",
      "treeView_updateAlreadySelected",
      "treeView_updateToVinculate",
      "checkbox_initItems",
      "checkbox_updateAlreadySelected",
      "modalityData_addItems",
      "tempModalityData_resetItems",
    ]),
    // API Call Functions
    async fetchFarmList(modalityTypeId) {
      const result = await getFarms(modalityTypeId);
      return result.data.map((item) => {
        return {
          id: item.id,
          name: item.fullName,
          kWh: item.kWh,
        };
      });
    },
    async fetchConsumerUnitList(modalityTypeId) {
      const result = await getConsumerUnitByModalityTypeId(modalityTypeId);
      this.consumerUnits = result.data;
      return result.data.map((client) => {
        return {
          id: client.userId,
          index: `U-${client.userId}`,
          name: client.fullName || client.name,
          children: client.consumerUnits.map((consUnit) => {
            return {
              id: consUnit.id,
              index: `CU-${consUnit.id}`,
              name: consUnit.installationNumber,
              consumptionAverage: consUnit.consumptionAverage,
              statusId: consUnit.statusId,
            };
          }),
        };
      });
    },
    async fetchModalityData(id) {
      const { data, isError } = await getModalityById(id);
      if (!isError) {
        this.modalityInfo = this._extractModalityInfo(data);
        const modalityFarms = this._extractModalityFarms(data);
        const modalityConsumerUnits = this._extractModalityConsumerUnits(data);
        return { modalityFarms, modalityConsumerUnits };
      } else {
        this.$emit("fetchModalityDataError");
        return null;
      }
    },

    // Event Handler Functions
    handleNavigateToVinculationTab(node) {
      this.treeView_updateToVinculate(node);

      const filteredFarm = this.modalityData_getItem.filter(
        (item) => item.farm.id === node.id
      );

      this.treeView_updateAlreadySelected(
        filteredFarm[0].consumerUnits.map((consUnit) => {
          return {
            ...consUnit,
          };
        }) || []
      );

      this.treeView_initSelectedByOthers(
        this.modalityData_getItem
          .filter((item) => item.farm.id !== node.id)
          .map((item) => item.consumerUnits)
          .flat()
      );
      this.incrementTab();
    },
    handleConfirmVinculation(toDesvinculation) {
      const farmConsumerUnit = {
        farm: this.treeView_getToVinculate,
        consumerUnits: this.treeView_getAlreadySelected.map((sec) => {
          if (toDesvinculation.includes(sec.id)) {
            return {
              ...sec,
              toDesvinculation: true,
            };
          }
          return sec;
        }),
      };
      const novo = this.modalityData_getItem.filter(
        (item) => item.farm.id !== this.treeView_getToVinculate.id
      );
      novo.push(farmConsumerUnit);

      this.modalityData_addItems(novo);
      this.tempModalityData_resetItems(novo);
      this.treeView_updateToVinculate({});
      this.treeView_updateAlreadySelected([]);
      this.decrementTab();
    },
    handleCancelVinculation() {
      this.treeView_updateToVinculate({});
      this.tempModalityData_resetItems(this.modalityData_getItem);
      this.treeView_updateAlreadySelected([]);
      this.decrementTab();
    },
    handleModalityInfoChange(modalityInfo) {
      this.modalityInfo = {
        ...modalityInfo,
      };
    },
    handleCancelModalityCreation() {
      this.$emit("cancelModalityCreation");
    },
    handleConfirmModalityCreation() {
      this.$emit("confirmModalityCreation", {
        modalityInfo: this.modalityInfo,
      });
    },

    // Private Functions
    _extractModalityInfo(data) {
      return {
        id: data.id,
        name: data.name,
        document: data.document,
        responsible: data.responsible,
        contact: data.contact,
        modalityTypeId: data.modalityTypeId,
      };
    },

    _extractModalityFarms(data) {
      return data.FarmConsumerUnitModalities.map((fcum) => {
        const farmConsUnit = fcum.FarmConsumerUnit;
        return {
          farm: {
            id: farmConsUnit.User.id,
            name: farmConsUnit.User.fullName,
            kWh: farmConsUnit.User.kWh,
          },
        };
      }).reduce((acc, item) => {
        // Remove as fazendas repetidas
        const index = acc.findIndex((accItem) => {
          return accItem.farm.id === item.farm.id;
        });
        if (index === -1) {
          acc.push(item);
        }
        return acc;
      }, []);
    },

    _extractModalityConsumerUnits(data) {
      return data.FarmConsumerUnitModalities.map((fcum) => {
        const consumerUnit = fcum.FarmConsumerUnit.ConsumerUnit;

        return {
          id: consumerUnit.id,
          index: `CU-${consumerUnit.id}`,
          name: consumerUnit.installationNumber,
          consumptionAverage: consumerUnit.consumptionAverage,
          statusId: consumerUnit.statusId,
          userId: consumerUnit.User.id,
          userName: consumerUnit.User.fullName,
          farmId: fcum.FarmConsumerUnit.UserId,
        };
      });
    },

    _modalityTotalProduction(modalityData) {
      return modalityData.reduce((acc, item) => {
        return acc + item.farm.kWh;
      }, 0);
    },
    _modalityTotalAllocated(modalityData) {
      return modalityData.reduce((acc, farm) => {
        return (
          acc +
          farm.consumerUnits.reduce((ac, consUnit) => {
            return ac + consUnit.consumptionAverage;
          }, 0)
        );
      }, 0);
    },
    _modalityTotalAllocationPercentage(modalityData) {
      return (
        (
          (this._modalityTotalAllocated(modalityData) /
            this._modalityTotalProduction(modalityData)) *
          100
        ).toFixed(3) || 0
      );
    },

    // Navigation v-tab Functions
    incrementTab() {
      this.tabs++;
    },
    decrementTab() {
      this.tabs--;
    },
  },
};
</script>

<style scoped></style>
