var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"d-flex align-center mb-4"},[_c('h2',{staticClass:"gray--text"},[_vm._v("Fazendas cadastradas")]),_c('v-spacer'),_c('v-text-field',{staticClass:"elevation-0 body-2 lightgray--text",attrs:{"dense":"","hide-details":"","solo":"","label":"Pesquisar","filled":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"gray--text body-2",attrs:{"headers":_vm.headers,"items":_vm.farms,"search":_vm.search,"loading":_vm.loading,"loading-text":"Buscando fazendas..."},scopedSlots:_vm._u([{key:"item.farmType",fn:function(ref){
var item = ref.item;
return [(item.farmType === 1)?_c('span',[_vm._v("Consórcio")]):_vm._e(),(item.farmType === 2)?_c('span',[_vm._v("Cooperativa")]):_vm._e()]}},{key:"item.personType",fn:function(ref){
var item = ref.item;
return [(item.legalPerson)?_c('span',[_vm._v(_vm._s(item.legalPerson))]):_c('span',[_vm._v(_vm._s(item.physicalPerson))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'ATIVO')?_c('span',{staticClass:"success--text"},[_vm._v(_vm._s(item.status))]):_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-around"},[_c('router-link',{attrs:{"to":("/editar-usuario/" + (item.id) + "?userTypeId=4")}},[_c('IconEdit')],1),_c('DeleteUser',{attrs:{"profileData":item}})],1)]}}])},[_c('template',{slot:"no-data"},[_c('span',{staticClass:"body-2 lightgray--text"},[_vm._v("Nenhuma fazenda encontrada :(")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }