<template>
  <div>
    <Admin v-if="profileData.userTypeId === (1 || 2)" />
    <Client v-if="profileData.userTypeId === 3" :userId="profileData.id" />
    <Seller v-if="profileData.userTypeId === 5" />
  </div>
</template>

<script>
import jwt from "jwt-decode";
import { getUserById } from "@/services/user.js";
import Admin from "@/components/dashboard/Admin.vue";
import Client from "@/components/dashboard/Client.vue";
import Seller from "@/components/dashboard/Seller.vue";
export default {
  name: "Dashboard",
  beforeMount() {
    this.getProfileData();
  },
  components: {
    Admin,
    Client,
    Seller,
  },
  data: () => ({
    profileData: {},
    jwt: jwt,
  }),
  methods: {
    getProfileData() {
      const userId = jwt(localStorage.access_token).sub;
      getUserById(userId).then((res) => {
        this.profileData = res.data;
      });
    },
  },
};
</script>
