<template>
  <v-form v-model="validContactForm">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <IconPhone />
        <span class="ml-2 body-1 font-weight-bold primary--text">
          Contato
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Telefone principal *"
          outlined
          v-mask="phoneMaskPrim"
          v-model="contactDetails.primaryPhone"
          :rules="[rules.required, rules.phone]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Telefone secundário (opcional)"
          outlined
          v-mask="phoneMaskSecg"
          v-model="contactDetails.secondaryPhone"
          :rules="[rules.required, rules.phone]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="E-mail corporativo *"
          outlined
          disabled
          v-model="contactDetails.email"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          :disabled="!validContactForm"
          color="primary"
          @click="saveChanges"
          >Salvar Contato</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import rules from "@/plugins/rules";
import IconPhone from "@/assets/icons/IconPhone.vue";
export default {
  components: {
    IconPhone,
  },
  props: {
    contactDetails: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    rules: rules,
    validContactForm: false,
  }),
  computed: {
    phoneMaskPrim() {
      const numeric = this.contactDetails.primaryPhone.replace(/[^\d]+/g, "");
      return numeric.length >= 11 ? "(##) # ####-####" : "(##) ####-####";
    },
    phoneMaskSecg() {
      const numeric = this.contactDetails.secondaryPhone.replace(/[^\d]+/g, "");
      return numeric.length >= 11 ? "(##) # ####-####" : "(##) ####-####";
    },
  },
  methods: {
    saveChanges() {
      const params = {
        primaryPhone: this.contactDetails.primaryPhone,
        secondaryPhone: this.contactDetails.secondaryPhone,
      };
      this.$emit("updateUser", params);
    },
  },
};
</script>
