<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="8">
        <v-row class="ma-4 flex-column">
          <span class="text--primary font-weight-bold">
            {{ this.treeView_getToVinculate.name }}
            <span class="rounded-percentage ml-4 pa-1 white--text"
              >{{ allocationPercentage }}%</span
            >
          </span>
          <span class="text-caption mt-2"
            >(alocação de kWh em {{ allocationPercentage }}%)</span
          >
        </v-row>
        <v-row>
          <SelectedList
            :selection="treeView_getAlreadySelected"
            :isConsUnitList="true"
            :isEdition="isEdition"
            :selectedToDesvinculate="toDesvinculation"
            @clicked:desvinculation="handleDesvinculation"
            @clicked:deactivation="handleDeactivation"
          />
        </v-row>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="4">
        <Search
          :search="search"
          @change:searchField="handleSearch"
          headerText="Selecione Unidades Consumidoras"
        />
        <TreeViewList />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8" class="d-flex flex-wrap-reverse">
        <v-col cols="4">
          <v-btn
            color="primary"
            block
            outlined
            large
            @click="handleCancelVinculation"
            >Cancelar</v-btn
          >
        </v-col>
        <v-col cols="4">
          <v-btn
            color="primary"
            block
            large
            :disabled="
              !this.treeView_getToVinculate.hasOwnProperty('id') ||
              allocationPercentage > 100
            "
            @click="handleConfirmVinculation"
            >Vincular</v-btn
          >
        </v-col>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import TreeViewList from "@/components/modalidades/TreeViewList.vue";
import SelectedList from "@/components/modalidades/SelectedList.vue";
import Search from "@/components/general/Search.vue";

export default {
  name: "VinculationTab",
  components: { Search, TreeViewList, SelectedList },
  props: {
    isEdition: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    selection: [],
    toDesvinculation: [],
    search: "",
  }),
  computed: {
    ...mapGetters([
      "treeView_getAlreadySelected",
      "treeView_getToVinculate",
      "treeView_getItems",
      "treeView_getSelectedByOthers",
    ]),
    allocationPercentage() {
      const totalConsumption = this.treeView_getAlreadySelected.reduce(
        (acc, curr) => {
          return acc + curr.consumptionAverage;
        },
        0
      );

      return (
        (totalConsumption / this.treeView_getToVinculate.kWh) *
        100
      ).toFixed(3);
    },
  },
  methods: {
    resetSearch() {
      this.search = "";
    },
    handleSearch(searchText) {
      if (searchText === null || searchText === "") {
        this.items = this.checkBoxItems;
        return;
      }

      const filteredItems = this.checkBoxItems.filter((consUnits) => {
        return (
          consUnits.name.toLowerCase().includes(searchText.toLowerCase()) ||
          consUnits.children.some((child) => {
            return child.name.toLowerCase().includes(searchText.toLowerCase());
          })
        );
      });

      this.items = filteredItems;
    },
    handleConfirmVinculation() {
      this.$emit("clicked:confirmVinculation", this.toDesvinculation);
    },
    handleCancelVinculation() {
      this.$emit("clicked:cancelVinculation");
      this.toDesvinculation = [];
    },
    async handleDesvinculation(consUnit) {
      this.toDesvinculation.push(consUnit.id);
    },
    handleDeactivation(consUnit) {
      const client = this.items.reduce((acc, curr) => {
        const found = curr.children.find((child) => child.id === consUnit.id);
        if (found) {
          return { ...curr };
        }
        return acc;
      }, {});

      if (client) {
        window.location.href = `/editar-usuario/${client.id}`;
      }
    },
  },
};
</script>

<style scoped>
.rounded-percentage {
  background-color: #e91e63;
  border-radius: 10%;
}
</style>
