import api from "@/plugins/axios";

export async function signin(login, password) {
  let params = {
    email: login,
    password: password,
  };

  const signin = await api.post("/users/authenticate", params);
  return signin;
}

export async function passwordReset(login) {
  const params = { email: login };

  const passwordReset = await api.post("/users/reset-password", params);
  return passwordReset;
}

export async function setPassword(newPassword, passwordResetToken) {
  const params = {
    password: newPassword,
    token: passwordResetToken,
  };
  const setPassword = await api.patch("/users/reset-password/", params);
  return setPassword;
}
