<template>
  <v-form v-model="validPasswordForm">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <IconKey />
        <span class="ml-2 body-1 font-weight-bold gray--text">
          Defina uma senha de acesso provisória para o novo usuário
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          outlined
          label="Senha *"
          v-model="passwordData.password"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          :rules="[rules.required, rules.minSize]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          outlined
          label="Confirme a senha *"
          v-model="passwordData.passwordConfirmation"
          :type="showPasswordConfirmation ? 'text' : 'password'"
          :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPasswordConfirmation = !showPasswordConfirmation"
          :rules="[
            rules.required,
            rules.minSize,
            (passwordData.password == passwordData.passwordConfirmation
              ? true
              : false) || 'As senhas precisam ser iguais.',
          ]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <v-btn outlined color="primary" @click="$emit('decrementTab')">
          Voltar
        </v-btn>
        <v-btn
          color="primary"
          @click="
            $emit('incrementTab');
            $emit('setPassword', passwordData);
          "
          :disabled="!validPasswordForm"
        >
          Continuar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import IconKey from "@/assets/icons/IconKey.vue";
export default {
  props: {
    rules: {
      type: Object,
      required: true,
    },
  },
  components: {
    IconKey,
  },
  data: () => ({
    validPasswordForm: false,
    showPasswordConfirmation: false,
    showPassword: false,
    passwordData: {
      password: "",
      passwordConfirmation: "",
    },
  }),
};
</script>
