<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" />
    <ContentArea>
      <EditProfile :profileData="profileData" />
    </ContentArea>
  </div>
</template>

<script>
import { getUserById } from "@/services/user.js";
import Breadcrumbs from "@/components/general/Breadcrumbs.vue";
import EditProfile from "@/components/profile/EditProfile.vue";
import ContentArea from "@/components/general/ContentArea.vue";

export default {
  name: "Profile",
  components: {
    Breadcrumbs,
    EditProfile,
    ContentArea,
  },
  created() {
    this.getProfileData();
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Área do administrador",
      },
      {
        text: "Usuários cadastrados",
      },
      {
        text: "Editar dados",
      },
    ],
    profileData: {},
  }),
  methods: {
    getProfileData() {
      getUserById(
        this.$route.params.id,
        this.$route.query.userTypeId ? this.$route.query.userTypeId : null
      ).then((res) => {
        this.profileData = res.data;
      });
    },
  },
};
</script>
