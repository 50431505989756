<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="672" persistent>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <IconDelete />
        </div>
      </template>
      <v-card class="text-center py-12 px-6" v-if="!completed">
        <header>
          <h2 class="gray--text">
            Tem certeza que deseja deletar o usuário abaixo?
          </h2>
        </header>
        <body class="mt-6">
          <div class="body-1 lightgray--text mb-4">
            {{ profileData.fullName }}
          </div>
          <div class="body-1 lightgray--text mb-4">
            <span v-if="profileData.userTypeId === 1"
              >Administrador Master</span
            >
            <span v-if="profileData.userTypeId === 2">Administrador Geral</span>
            <span v-if="profileData.userTypeId === 3">Cliente</span>
            <span v-if="profileData.userTypeId === 4">Fazenda</span>
            <span v-if="profileData.userTypeId === 5">Vendedor</span>
          </div>
          <div class="body-1 lightgray--text mb-4">
            {{ profileData.physicalPerson }}
          </div>
        </body>

        <v-card-actions class="mt-5">
          <v-btn color="primary" outlined @click="dialog = false">
            Voltar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="deleteUser()"> Deletar </v-btn>
        </v-card-actions>
      </v-card>
      <div class="text-center" v-else>
        <v-card class="py-12">
          <svg
            v-if="success"
            width="123"
            height="123"
            viewBox="0 0 123 123"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M38 64.4375L55.625 82.0625L85 46.8125"
              stroke="#0D961B"
              stroke-width="5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M61.5 120.25C93.9467 120.25 120.25 93.9467 120.25 61.5C120.25 29.0533 93.9467 2.75 61.5 2.75C29.0533 2.75 2.75 29.0533 2.75 61.5C2.75 93.9467 29.0533 120.25 61.5 120.25Z"
              stroke="#0D961B"
              stroke-width="5"
            />
          </svg>
          <p
            class="body-1 font-weight-bold mt-2"
            :class="{ 'success--text': success, 'error--text': !success }"
          >
            {{ message }}
          </p>
          <v-btn color="primary" outlined class="mt-1" @click="complete()">
            Entendi
          </v-btn>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { deleteUser } from "@/services/user.js";
import IconDelete from "@/assets/icons/IconDelete.vue";

export default {
  components: {
    IconDelete,
  },
  props: {
    profileData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    success: null,
    completed: false,
    message: "Usuário excluído com sucesso!",
    dialog: false,
  }),
  methods: {
    deleteUser() {
      deleteUser(this.profileData.id)
        .then(() => {
          this.success = true;
          this.message = "Usuário excluído com sucesso!";
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.success = false;
            this.message = error.response.data.message;
          } else {
            this.success = false;
            this.message = "Erro ao excluir, tente novamente";
          }
        })
        .finally(() => {
          this.completed = true;
        });
    },
    complete() {
      window.location.reload();
    },
  },
};
</script>
