<template>
  <v-dialog v-model="completed" max-width="720" persistent>
    <div class="white pa-3">
      <div class="d-flex justify-end">
        <v-icon right @click="$emit('closeFeedback')"> mdi-close </v-icon>
      </div>
      <Feedback
        :message="message"
        :success="success"
        :action="false"
        class="mt-4"
      />
      <div class="d-flex align-center justify-center pt-2 mb-12">
        <v-btn outlined color="primary" @click="$emit('closeFeedback')"
          >Entendi</v-btn
        >
      </div>
    </div>
  </v-dialog>
</template>

<script>
import Feedback from "@/components/general/Feedback.vue";
export default {
  components: {
    Feedback,
  },
  props: {
    completed: {
      type: Boolean,
      required: false,
      default: false,
    },
    success: {
      type: Boolean,
      required: false,
      default: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
};
</script>
