<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <IconUserAvatar />
        <span class="ml-2 body-1 font-weight-bold gray--text">
          O usuário será cadastrado como Pessoa Física ou Pessoa Jurídica?
        </span>
      </v-col>
      <v-col cols="12">
        <v-radio-group v-model="kindOfPerson">
          <v-radio label="Pessoa física (CPF)" :value="1"></v-radio>
          <v-radio label="Pessoa jurídica (CNPJ)" :value="2"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="end">
        <v-btn
          color="primary"
          @click="
            $emit('incrementTab');
            $emit('setKindOfPerson', kindOfPerson);
          "
        >
          Continuar
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IconUserAvatar from "@/assets/icons/IconUserAvatar.vue";
export default {
  components: {
    IconUserAvatar,
  },
  data: () => ({
    kindOfPerson: 1,
  }),
};
</script>
