<template>
  <div id="content" class="white d-flex align-center justify-center">
    <transition
      v-if="!$route.params.token"
      name="auth-transitions"
      :duration="{ enter: 1000, leave: 200 }"
      mode="out-in"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <LoginForm v-if="isLogin" @change="change()" />
      <RecoverPasswordForm v-else @change="change()" />
    </transition>
    <RedefinePassowordForm v-else />
  </div>
</template>

<script>
import LoginForm from "./LoginForm.vue";
import RecoverPasswordForm from "./RecoverPasswordForm.vue";
import RedefinePassowordForm from "./RedefinePasswordForm.vue";

export default {
  name: "Content",
  components: {
    LoginForm,
    RecoverPasswordForm,
    RedefinePassowordForm,
  },
  data: () => ({
    isLogin: true,
  }),
  methods: {
    change() {
      this.isLogin = !this.isLogin;
    },
  },
};
</script>

<style lang="scss" scoped>
#content {
  width: 65%;
  height: 100%;
}

@media only screen and (max-width: 860px) {
  #content {
    width: 100%;
  }
}

@media only screen and (min-width: 1920px) {
  #content {
    width: 75%;
  }
}
</style>
