<template>
  <v-form v-model="validEditForm">
    <div class="text-center">
      <v-dialog v-model="dialog" max-width="480" persistent>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn color="warning">Editar</v-btn>
          </div>
        </template>
        <v-card class="text-center py-12 px-6" v-if="!completed">
          <header>
            <h2 class="primary--text mb-10">Editar Unidade Consumidora</h2>
          </header>
          <body>
            <v-row class="justify-space-around">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newCreditDiscount"
                  v-mask="'##,#%'"
                  label="Desconto de Crédito *"
                  :rules="[rules.required, rules.creditDiscount]"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newConsumptionAverage"
                  label="Média de Consumo *"
                  :rules="[rules.required, rules.numeric]"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </body>
          <v-card-actions class="d-flex justify-center mt-5">
            <v-btn
              class="mt-4 d-flex align-center justify-center"
              x-large
              color="primary"
              outlined
              @click="dialog = false"
            >
              Cancelar
            </v-btn>
            <CustomButton
              id="custom-button"
              class="mt-4 d-flex align-center justify-center"
              color="primary"
              @click="saveChanges()"
              :disabled="!validEditForm"
            >
              Confirmar
            </CustomButton>
          </v-card-actions>
        </v-card>
        <div class="text-center" v-else>
          <v-card class="py-12">
            <svg
              v-if="success"
              width="123"
              height="123"
              viewBox="0 0 123 123"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M38 64.4375L55.625 82.0625L85 46.8125"
                stroke="#0D961B"
                stroke-width="5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M61.5 120.25C93.9467 120.25 120.25 93.9467 120.25 61.5C120.25 29.0533 93.9467 2.75 61.5 2.75C29.0533 2.75 2.75 29.0533 2.75 61.5C2.75 93.9467 29.0533 120.25 61.5 120.25Z"
                stroke="#0D961B"
                stroke-width="5"
              />
            </svg>
            <p
              class="body-1 font-weight-bold ma-6"
              :class="{ 'success--text': success, 'error--text': !success }"
            >
              {{ message }}
            </p>
            <v-btn color="primary" outlined class="mt-1" @click="complete()">
              Entendi
            </v-btn>
          </v-card>
        </div>
      </v-dialog>
    </div>
  </v-form>
</template>

<script>
import rules from "@/plugins/rules.js";
import { updateConsumerUnit } from "@/services/user.js";
import CustomButton from "@/components/general/CustomButton.vue";

export default {
  components: {
    CustomButton,
  },
  props: {
    unitData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    rules: rules,
    validEditForm: false,
    success: null,
    completed: false,
    message: "Unidade consumidora editada com sucesso!",
    newCreditDiscount: "",
    newConsumptionAverage: null,
    dialog: false,
    validFeeForm: false,
  }),
  methods: {
    updateConsumerUnit(params) {
      updateConsumerUnit(this.unitData.id, params)
        .then(() => {
          this.success = true;
          this.message = "Unidade consumidora editada com sucesso!";
        })
        .catch(() => {
          this.success = false;
          this.message = "Erro ao editar unidade consumidora, tente novamente";
        })
        .finally(() => {
          this.completed = true;
        });
    },
    saveChanges() {
      this.newCreditDiscount = this.newCreditDiscount
        .replace(",", ".")
        .replace("%", "");
      const params = {
        userId: this.unitData.userId,
        zipCode: this.unitData.zipCode,
        publicPlace: this.unitData.publicPlace,
        number: this.unitData.number,
        complement: this.unitData.complement,
        district: this.unitData.district,
        installationNumber: this.unitData.installationNumber,
        consumptionAverage: Number(this.newConsumptionAverage),
        creditDiscount: Number(this.newCreditDiscount),
        city: this.unitData.city,
        state: this.unitData.state,
        billUrl: this.unitData.billUrl,
        statusId: this.unitData.statusId,
      };
      this.updateConsumerUnit(params);
    },
    complete() {
      window.location.reload();
    },
  },
};
</script>
<style>
#custom-button {
  min-width: fit-content !important;
  max-width: 30% !important;
}
</style>
