<template>
  <div>
    <div v-if="value !== undefined">
      <v-radio-group v-model="status" row @change="handleChangeStatus">
        <v-radio label="Ativo" :value="'ATIVO'" />
        <v-radio label="Pendente" :value="'PENDENTE'" />
        <v-radio label="Inativo" :value="'INATIVO'" />
      </v-radio-group>
      <v-dialog v-model="confirmDialog" max-width="500">
        <v-card class="text-center py-12 px-6">
          <header>
            <h2 class="gray--text">
              Tem certeza que deseja alterar o status do usuário para o status
              abaixo?
            </h2>
          </header>
          <v-card-text>
            <body class="mt-6">
              <div class="body-1 lightgray--text mb-4">
                {{ selectedStatus }}
              </div>
            </body>
          </v-card-text>
          <v-card-actions class="d-flex justify-center mt-5">
            <v-btn color="primary" outlined @click="cancelChangeStatus()">
              Voltar
            </v-btn>
            <v-btn color="primary" @click="confirmStatus()"> Confirmar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-if="farmValue !== undefined">
      <v-radio-group v-model="farmStatus" row @change="handleChangeStatus">
        <v-radio label="Ativo" :value="'ATIVO'" />
        <v-radio label="Inativo" :value="'INATIVO'" />
      </v-radio-group>
      <v-dialog v-model="confirmDialog" max-width="500">
        <v-card class="text-center py-12 px-6">
          <header>
            <h2 class="gray--text">
              Tem certeza que deseja alterar o status da fazenda para o status
              abaixo?
            </h2>
          </header>
          <v-card-text>
            <body class="mt-6">
              <div class="body-1 lightgray--text mb-4">
                {{ selectedFarmStatus }}
              </div>
            </body>
          </v-card-text>
          <v-card-actions class="d-flex justify-center mt-5">
            <v-btn color="primary" outlined @click="cancelChangeStatus()">
              Voltar
            </v-btn>
            <v-btn color="primary" @click="confirmStatus()"> Confirmar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { updateUserStatus } from "@/services/user";

export default {
  name: "StatusRadio",
  props: {
    value: {
      type: String,
      required: false,
    },
    farmValue: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      userId: this.$route.params.id,
      status: this.value,
      farmStatus: this.farmValue,
      selectedStatus: this.value,
      selectedFarmStatus: this.farmValue,
      confirmDialog: false,
    };
  },
  methods: {
    handleChangeStatus() {
      this.selectedFarmStatus = this.farmStatus;
      this.selectedStatus = this.status;
      this.confirmDialog = true;
    },
    async confirmStatus() {
      if (this.farmValue) {
        await updateUserStatus(this.userId, { status: this.farmStatus });
        this.selectedFarmStatus = null;
        this.confirmDialog = false;
      }
      if (this.value) {
        await updateUserStatus(this.userId, { status: this.status });
        this.selectedStatus = null;
        this.confirmDialog = false;
      }
    },
    async cancelChangeStatus() {
      if (this.farmValue) {
        this.confirmDialog = false;
        this.farmStatus = this.farmValue;
      }
      if (this.value) {
        this.confirmDialog = false;
        this.status = this.value;
      }
    },
  },
};
</script>
