<template>
  <v-container class="white content-area rounded-lg pa-6">
    <slot />
  </v-container>
</template>

<script>
export default {
  name: "Content",
};
</script>

<style lang="scss" scoped>
.content-area {
  min-height: 70vh;
}
</style>
